import config from "@config";

export async function geocode(data) {
  const encoded = encodeURI(data);

  let object = { data: null, error: false };

  try {
    const res = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${config.ymaps.api_key}&geocode=${encoded}&format=json`);
    const data = await res.json();
    object = { data, error: false };
  } catch (e) {
    object = { data: null, error: true };
  }

  return object;
}
