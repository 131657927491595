import { Button } from "antd";

export const getContactItems = (contacts) =>
  contacts.map((c, i) => {
    const { type, contact } = c;

    switch (type) {
      case "phone":
        return (
          <Button
            type="text"
            key={i}
            icon={
              <img
                className="align-middle mb-1 mr-2"
                alt="Контакты"
                width={15}
                height={15}
                src="/img/contact-icons/icon-phone.svg"
              />
            }
            href={`tel:${contact}`}
            target="_blank"
          >
            {contact}
          </Button>
        );

      case "telegram":
        return (
          <Button
            type="text"
            key={i}
            icon={
              <img
                className="align-middle mb-1 mr-2"
                alt="Контакты"
                width={15}
                height={15}
                src="/img/contact-icons/icon-telegram.svg"
              />
            }
            href={contact}
            target="_blank"
          >
            Telegram
          </Button>
        );

      case "instagram":
        return (
          <Button
            type="text"
            key={i}
            icon={
              <img
                className="align-middle mb-1 mr-2"
                alt="Контакты"
                width={15}
                height={15}
                src="/img/contact-icons/icon-instagram.svg"
              />
            }
            href={contact}
            target="_blank"
          >
            Instagram
          </Button>
        );

      case "whatsapp":
        return (
          <Button
            type="text"
            key={i}
            icon={
              <img
                className="align-middle mb-1 mr-2"
                alt="Контакты"
                width={15}
                height={15}
                src="/img/contact-icons/icon-whatsapp.svg"
              />
            }
            href={`https://wa.me/${contact.replace("+", "")}`}
            target="_blank"
          >
            WhatsApp
          </Button>
        );

      case "facebook":
        return (
          <Button
            type="text"
            key={i}
            icon={
              <img
                className="align-middle mb-1 mr-2"
                alt="Контакты"
                width={15}
                height={15}
                src="/img/contact-icons/icon-facebook.svg"
              />
            }
            href={contact}
            target="_blank"
          >
            Facebook
          </Button>
        );

      default:
        return (
          <Button type="text" key={i} href={contact} target="_blank">
            {contact}
          </Button>
        );
    }
  });
