import { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd";

import { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapDefaultMarker, YMapControls, reactify, YMapZoomControl } from "@lib/ymaps";
import { useDebounce } from "@hooks/useDebounce";
import { Geocode } from "@utils";

const DEFAULT_VALUES = {
  location: [71.430429, 51.1282], // Астана
  bounds: undefined,
  zoom: 10,
  duration: 500
};

export default function Address(props) {
  const { form, cities } = props;
  const { t } = useTranslation();

  const shouldGeocodeRef = useRef(false);

  const [isMapVisible, setIsMapVisible] = useState(false);
  const [boundedBy, setBoundedBy] = useState(DEFAULT_VALUES.bounds);
  const [location, setLocation] = useState(
    form.getFieldValue(["meta", "location", "pos"])
      ? form
          .getFieldValue(["meta", "location", "pos"])
          .split(" ")
          .map(e => Number(e))
      : DEFAULT_VALUES.location
  );

  const addressValue = Form.useWatch(["meta", "location", "address"], form);
  const debouncedAddressValue = useDebounce(addressValue, 1000);

  const cityId = Form.useWatch("city_id", form);
  const cityName = useMemo(() => {
    return cities.find(e => e.value === cityId)?.label;
  }, [cities, cityId]);

  useEffect(() => {
    if (!cityName) return;

    // при изменении города позволяем сделать геокод запрос из второго useEffect
    shouldGeocodeRef.current = true;
  }, [cityName, form]);

  useEffect(() => {
    if (!cityName) return; // не выбран город
    if (!isMapVisible) return; // не открыта карта
    if (!shouldGeocodeRef.current) return; // флаг для запроса

    // console.log("doing geocode");
    Geocode.geocode(`${cityName}, ${debouncedAddressValue}`).then(({ data, error }) => {
      if (error) return;

      const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;

      form.setFields([
        {
          name: ["meta", "location", "lon"],
          value: geoObject.Point.pos.split(" ")[0]
        },
        {
          name: ["meta", "location", "lat"],
          value: geoObject.Point.pos.split(" ")[1]
        },
        {
          name: ["meta", "location", "pos"],
          value: geoObject.Point.pos
        },
        {
          name: ["meta", "location", "country"],
          value: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "country")?.name
        },
        {
          name: ["meta", "location", "city"],
          value: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "locality")?.name
        }
      ]);

      setLocation(geoObject.Point.pos.split(" ").map(e => Number(e)));
      setBoundedBy([
        geoObject.boundedBy.Envelope.lowerCorner.split(" ").map(e => Number(e)),
        geoObject.boundedBy.Envelope.upperCorner.split(" ").map(e => Number(e))
      ]);
    });
  }, [cityName, debouncedAddressValue, form, isMapVisible]);

  return (
    <section className="flex flex-col gap-4">
      <Form.Item
        label={t("listings.locationTitle")}
        // tooltip={t("listings.locationTooltip")}
        help={!cityName ? t("listings.locationHelpWithoutCity") : t("listings.locationHelpWithCity")}
        name={["meta", "location", "address"]}
        rules={[
          {
            required: false,
            message: t("listings.locationPrompt")
          }
        ]}
      >
        <Input
          placeholder={t("listings.locationPlaceholder")}
          onFocus={() => (shouldGeocodeRef.current = true)}
          onClick={() => (shouldGeocodeRef.current = true)}
          disabled={!cityName}
        />
      </Form.Item>

      <Button
        className="w-fit"
        type="primary"
        disabled={!cityName}
        onClick={() => {
          setIsMapVisible(prev => !prev);
        }}
      >
        {isMapVisible ? t("listings.locationHideMap") : t("listings.locationShowMap")}
      </Button>

      {isMapVisible && (
        <div style={{ width: "100%", height: "400px" }}>
          <YMap
            location={reactify.useDefault({ center: location, zoom: DEFAULT_VALUES.zoom, duration: DEFAULT_VALUES.duration, bounds: boundedBy }, [location])}
          >
            <YMapDefaultSchemeLayer />
            <YMapDefaultFeaturesLayer />

            <YMapControls position="right">
              <YMapZoomControl />
            </YMapControls>

            <YMapDefaultMarker
              coordinates={reactify.useDefault(location, [location])}
              draggable
              color="#00b25d"
              size="normal"
              iconName="fallback"
              mapFollowsOnDrag
              onDragEnd={coords => {
                shouldGeocodeRef.current = false;
                Geocode.geocode(coords).then(({ data, error }) => {
                  if (error) return;

                  const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;

                  form.setFields([
                    {
                      name: ["meta", "location", "address"],
                      value: geoObject.name
                    },
                    {
                      name: ["meta", "location", "lon"],
                      value: geoObject.Point.pos.split(" ")[0]
                    },
                    {
                      name: ["meta", "location", "lat"],
                      value: geoObject.Point.pos.split(" ")[1]
                    },
                    {
                      name: ["meta", "location", "pos"],
                      value: geoObject.Point.pos
                    },
                    {
                      name: ["meta", "location", "country"],
                      value: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "country")?.name
                    },
                    {
                      name: ["meta", "location", "city"],
                      value: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "locality")?.name
                    }
                  ]);

                  setLocation(geoObject.Point.pos.split(" ").map(e => Number(e)));
                  setBoundedBy([
                    geoObject.boundedBy.Envelope.lowerCorner.split(" ").map(e => Number(e)),
                    geoObject.boundedBy.Envelope.upperCorner.split(" ").map(e => Number(e))
                  ]);
                });
              }}
            />
          </YMap>
        </div>
      )}
      <div>
        <Form.Item name={["meta", "location", "lat"]} hidden>
          <Input hidden disabled />
        </Form.Item>
        <Form.Item name={["meta", "location", "lon"]} hidden>
          <Input hidden disabled />
        </Form.Item>
        <Form.Item name={["meta", "location", "pos"]} hidden>
          <Input hidden disabled />
        </Form.Item>
        <Form.Item name={["meta", "location", "city"]} hidden>
          <Input hidden disabled />
        </Form.Item>
        <Form.Item name={["meta", "location", "country"]} hidden>
          <Input hidden disabled />
        </Form.Item>
      </div>
    </section>
  );
}
