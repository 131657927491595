import { useEffect, useState } from "react";
import { Table, message, Tag, Button, Collapse, Badge } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import { Imgproxy, Blog as Dict } from "@utils";
import dayjs from "dayjs";
import * as API from "@api/moderator";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  compact = false,
  showFilters = ["search", "category", "product", "status", "dates", "published", "type", "locale", "extra", "project"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true
}) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(searchParams?.search || null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current ? parseInt(searchParams.pagination.current) : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: total => `Найдено: ${total}`
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      search,
      pagination: {
        current: params.current
      }
    });

    const _res = await API.Blog.getArticles({
      q: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC"
      }
    });

    if (!_res.data) {
      return message.error("Ошибка во время загрузки материалов, попробуйте позже", 2);
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: total => `Найдено: ${total}`
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter({ ...filter, [f]: v || undefined }, setPagination({ ...pagination, current: 1 }));
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "image",
      key: "image",
      align: "center",
      width: 80,
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: data => (
        <img
          loading="lazy"
          src={data ? Imgproxy.getURL(data, { width: 224, height: 150 }) : "/img/no-image.svg"}
          alt="Article"
          className="block mx-auto w-28 object-contain rounded-sm bg-white"
        />
      )
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Материал",
      width: 400,
      render: (_, data) => (
        <Link to={`/blog/articles/edit/${data.id}`}>
          <div className="flex justify-start items-center flex-wrap">
            <span className="block text-sm text-primary">
              <i className="not-italic text-neutral-400">ID {data?.id}</i>
              <i className="not-italic text-neutral-300 mx-1">|</i> {data?.type?.name} <i className="not-italic text-neutral-300 mx-1">|</i>{" "}
              {data?.category?.name}
            </span>
          </div>
          <span className="block text-base font-medium text-black leading-tight my-1">{data?.title}</span>
          {data?.authors?.length > 0 && (
            <span className="block text-sm font-normal text-neutral-500 leading-snug">Авторы: {data?.authors?.map(a => a?.name).join(", ")}</span>
          )}
          {data?.people?.length > 0 && (
            <span className="block text-sm font-normal text-neutral-500 leading-snug">Эксперты: {data?.people?.map(a => a?.full_name).join(", ")}</span>
          )}
          <div className="flex flex-wrap justify-start items-center gap-2 mt-2">
            {data?.locale && <Tag className="m-0">{data?.locale?.name}</Tag>}
            {data?.is_breaking && (
              <Tag className="m-0" color="red">
                Срочная новость
              </Tag>
            )}
            {data?.is_important && (
              <Tag className="m-0" color="blue">
                Важная новость
              </Tag>
            )}
            {data?.is_pr && (
              <Tag className="m-0" color="red">
                PR
              </Tag>
            )}
            {data?.is_live && (
              <Tag className="m-0" color="red">
                LIVE
              </Tag>
            )}
            {data?.is_editor_choice && <Tag className="m-0">Выбор редакции</Tag>}
            {data?.in_slider && <Tag className="m-0">В слайдере</Tag>}
            {data?.project_id && (
              <Tag color="green" className="m-0">
                Есть спецпроект
              </Tag>
            )}
          </div>
        </Link>
      )
    },
    {
      dataIndex: "status",
      key: "status",
      align: "center",
      title: "Статус",
      render: (data, all) => (
        <div>
          <Badge status={Dict.ARTICLE_STATUSES[data].badgeStatus} text={Dict.ARTICLE_STATUSES[data].label} />
        </div>
      ),
      sorter: true
    },
    {
      dataIndex: "published_at",
      key: "published_at",
      align: "center",
      title: "Дата публикации",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: data =>
        data ? (
          <span className="block text-sm font-medium text-black">{dayjs(data).format("DD.MM.YYYY HH:mm")}</span>
        ) : (
          <span className="block text-sm text-neutral-500">Не опубликован</span>
        ),
      sorter: true
    },
    {
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      title: "Посл. изменение",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: data => (
        <div>
          <span className="block text-sm font-normal text-neutral-400">{dayjs(data).fromNow()}</span>
          <span className="block text-sm font-medium text-black">{dayjs(data).format("DD.MM.YYYY HH:mm")}</span>
        </div>
      ),
      sorter: true
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/blog/articles/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      )
    }
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Search defaultValue={search} onSearch={onSearch} />
        </div>
      )}
      {includes("category", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Category defaultValue={filter?.category_id} onChange={onFilterChange} />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Status defaultValue={filter?.status} onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Dates defaultValue={filter?.created_at} onChange={onFilterChange} />
        </div>
      )}
      {includes("published", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Published defaultValue={filter?.published_at} onChange={onFilterChange} />
        </div>
      )}
      {includes("type", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Type defaultValue={filter?.type_id} onChange={onFilterChange} />
        </div>
      )}
      {includes("locale", showFilters) && (
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <Filters.Locale defaultValue={filter?.locale_id} onChange={onFilterChange} />
        </div>
      )}

      {includes("extra", showFilters) && (
        <div className="col-span-12">
          <Collapse
            size="small"
            defaultActiveKey={
              (!!filter?.is_pr || !!filter?.is_editor_choice || !!filter?.is_important || !!filter?.in_slider || !!filter?.is_live) && ["extra"]
            }
            items={[
              {
                key: "extra",
                label: "Особые пометки",
                children: (
                  <div className="w-full grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6 xl:col-span-2">
                      <Filters.PR defaultChecked={!!filter?.is_pr} onChange={onFilterChange} />
                    </div>
                    <div className="col-span-12 md:col-span-6 xl:col-span-2">
                      <Filters.Editor defaultChecked={!!filter?.is_editor_choice} onChange={onFilterChange} />
                    </div>
                    <div className="col-span-12 md:col-span-6 xl:col-span-2">
                      <Filters.Important defaultChecked={!!filter?.is_important} onChange={onFilterChange} />
                    </div>
                    <div className="col-span-12 md:col-span-6 xl:col-span-2">
                      <Filters.Slider defaultChecked={!!filter?.in_slider} onChange={onFilterChange} />
                    </div>
                    <div className="col-span-12 md:col-span-6 xl:col-span-2">
                      <Filters.Live defaultChecked={!!filter?.is_live} onChange={onFilterChange} />
                    </div>
                  </div>
                )
              }
            ]}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
