import { Form, Input, Select } from "antd";
import { Fragment } from "react";

export default function Details(props) {
  return (
    <Fragment {...props}>
      <Form.Item
        label="Сфера деятельности"
        name="category_id"
        rules={[
          {
            required: true,
            message: "Пожалуйста, выберите хотя сферу деятельности вашей компании."
          }
        ]}
      >
        <Select
          placeholder="Выберите сферу деятельности вашей компании"
          options={props?.dict?.categories}
          showSearch
          optionFilterProp="label"
          filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
        />
      </Form.Item>
      <Form.Item
        tooltip="Укажите чем занимается ваша компания. Например: «Предоставляем аутсорсинг финансовых услуг, кадровое делопроизводство, а также бесплатный сервис по расчету налоговых отчислений.»"
        label="Описание деятельности"
        name="description"
        rules={[
          {
            required: true,
            min: 8,
            message: "Описание должно содержать мин. 8 символов.",
            whitespace: true
          }
        ]}
        normalize={value => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }}
      >
        <Input.TextArea rows={3} placeholder="Укажите описание деятельности" />
      </Form.Item>
    </Fragment>
  );
}
