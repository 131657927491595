import { Card, Divider, Tag, List } from "antd";

export default function Info({ data }) {
  return (
    <Card bordered={false} title="Информация о бизнесе">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Название
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.company?.title}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Слоган
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.company?.subtitle || "Не указано"}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Категория
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.category?.name}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Страны присутствия
          </span>
          <span className="flex flex-wrap justify-start items-center gap-1">
            {data?.countries?.map((c, i) => (
              <Tag key={i}>{c?.name}</Tag>
            ))}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Города присутствия
          </span>
          <span className="flex flex-wrap justify-start items-center gap-1">
            {data?.cities?.map((c, i) => (
              <Tag key={i}>{c?.name}</Tag>
            ))}
          </span>
        </div>
        <div className="col-span-12">
          <Divider className="m-0" />
        </div>
        <div className="col-span-12">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Описание
          </span>
          <p className="text-base leading-relaxed text-slate-800 m-0 whitespace-pre-wrap">
            {data?.company?.description || "Не указано"}
          </p>
        </div>
        <div className="col-span-12">
          <Divider className="m-0" />
        </div>
        <div className="col-span-12">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Услуги
          </span>
          <List
            bordered={false}
            dataSource={data?.company?.services}
            renderItem={(item) => (
              <List.Item
                className="w-full px-0 text-base font-medium"
                extra={
                  <span className="text-base font-medium text-green-500">
                    {item?.priceFrom && "От "}
                    {Intl.NumberFormat("ru-KZ", {
                      currency: "kzt",
                      style: "currency",
                      maximumFractionDigits: 0,
                    }).format(item?.price)}
                  </span>
                }
              >
                {item?.title}
              </List.Item>
            )}
          />
        </div>
      </div>
    </Card>
  );
}
