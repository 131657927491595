import { useState } from "react";
import { Button, message, Form, Divider, Select } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { services } from "../list/filters/services";
import * as API from "@api/moderator";
import Products from "./options/products";

export default function NewPromotion({ className }) {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);

    const product = await API.Products.getAll({
      filter: { id: values?.product_id },
    }).then((res) => res?.data?.rows?.[0] || null);

    if (!product || product?.status !== "published") {
      setLoading(true);
      return message.error(
        "Объявление не найдено, либо еще не опубликовано",
        3
      );
    }

    API.ExternalPromotions.create({
      type: "product",
      source: values.source,
      product_id: product.id,
      product_type: product.type,
      user_id: product.user_id,
    }).then((res) => {
      if (!res || res?.error) {
        setLoading(false);
        return message.error("Невозможно создать продвижение", 3);
      }

      return message.success("Продвижение успешно создано", 3, () => {
        window.location.reload(false);
      });
    });
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12">
        <Form
          layout="vertical"
          scrollToFirstError
          onFinish={onSubmit}
          onErrorCapture={console.log}
          onFinishFailed={console.log}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Выберите объявление",
              },
            ]}
            name="product_id"
            required
            label="Объявление"
          >
            <Products loading={loading} />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Выберите тариф",
              },
            ]}
            label="Тариф"
            className="m-0"
            name="source"
            required
          >
            <Select placeholder="Выберите тариф">
              {services?.map((option) => (
                <Select.Option value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="mb-0">
            <Divider />
            <div className="flex justify-between items-center">
              <Button
                htmlType="submit"
                icon={<CreditCardOutlined />}
                loading={loading}
                source="primary"
              >
                Создать продвижение
              </Button>
              <span className="text-xs text-orange-500 ml-5 leading-tight">
                Услуга активируется сразу, убедитесь, что клиент произвел
                оплату!
              </span>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
