import { Card, Form, Input, Divider, Button, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Contacts } from "@modules/customer";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export default function ListingContacts({
  visible,
  form,
  loading,
  onBackClick,
  isApp = false,
}) {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const onContactsChange = (data) => {
    form.setFieldValue("contacts", data);
  };

  return (
    <Card
      hidden={!visible}
      className={classNames("shadow-lg shadow-gray-100 transition-all my-5", {
        "app-card": isApp,
      })}
    >
      <h2 className="w-full text-center my-1">{t("listings.contactsTitle")}</h2>
      <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-5">
        {t("listings.contactsHelp")}
      </span>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <span className="text-sm block w-full text-black mb-2">
            <i className="not-italic text-red-500">*</i>{" "}
            {t("listings.contactsName")}
          </span>
          <Form.Item
            className="m-0"
            help={t("listings.contactsNameHelp")}
            name="display_name"
            rules={[
              {
                required: true,
                message: t("listings.contactsNamePrompt"),
              },
            ]}
          >
            <Input placeholder={t("listings.contactsNamePlaceholder")} />
          </Form.Item>
        </div>
        <div className="col-span-12">
          <span className="text-sm block w-full text-black mb-2">
            <i className="not-italic text-red-500">*</i>{" "}
            {t("listings.contactsPhonesTitle")}
          </span>
          <Contacts
            disabled={currentUser?.role === "moderator"}
            onChange={onContactsChange}
            data={form.getFieldValue("contacts") || []}
            className="py-3"
          />
        </div>
      </div>
      <Divider />

      <Form.Item
        className="m-0"
        label={t("listings.ownerBiz")}
        name={["meta", "published_by_broker"]}
        initialValue={false}
      >
        <Select defaultValue={false}>
          <Select.Option value={false}>
            {t("listings.ownerBizYes")}
          </Select.Option>
          <Select.Option value={true}>{t("listings.ownerBizNo")}</Select.Option>
        </Select>
      </Form.Item>

      <Divider />

      <div className="flex justify-between items-center">
        <Button type="link" className="p-0" onClick={onBackClick}>
          {t("generic.back")}
        </Button>
        <Form.Item>
          <Button loading={loading} htmlType="submit" type="primary">
            {t("generic.publish")} <RightOutlined />
          </Button>
        </Form.Item>
      </div>
    </Card>
  );
}
