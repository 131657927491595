import { Select } from "antd";

export default function Cities({ onChange, data, ...params }) {
  return (
    <Select
      className="w-full"
      placeholder="Город"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => onChange("city_id", v)}
      {...params}
    >
      {data.map((data) => (
        <Select.Option key={data.id} value={data.id}>
          {data.name}
        </Select.Option>
      ))}
    </Select>
  );
}
