import { message } from "antd";
import * as API_CUSTOMER from "@api/customer";
import * as API from "@api";

export async function getListingById({ type = "sale", id = null }) {
  if (!id || !type) {
    return message.error("Невозможно загрузить данные");
  }

  const PRODUCT_API =
    type === "franchise"
      ? API_CUSTOMER.Franchises
      : type === "investment"
      ? API_CUSTOMER.Investments
      : API_CUSTOMER.Sales;

  return await Promise.all([
    PRODUCT_API.getOne({ id }),
    API.Tariffs.getAll({ limit: 100 }),
    API.Tariffs.getPackages({ limit: 100 }),
  ])
    .then(([products, tariffs, packages]) => {
      if (!products || !products?.data?.product?.id || !tariffs || !packages) {
        throw new Error("Ошибка загрузки объявления");
      }

      return {
        type,
        listing: products?.data,
        tariffsData: {
          tariffs: tariffs?.data?.rows,
          packages: packages?.data?.rows,
        },
      };
    })
    .catch((error) => {
      return message.error(error?.message || "Невозможно загрузить данные", 4);
    });
}
