import * as Sales from "./sales";
import * as Franchises from "./franchises";
import * as Investments from "./investments";
import * as Users from "./users";
import * as Countries from "./countries";
import * as Cities from "./cities";
import * as Categories from "./categories";
import * as ProductOptions from "./product_options";
import * as OrgTypes from "./org_types";
import * as Payments from "./payments";
import * as Products from "./products";
import * as Analytics from "./analytics";
import * as Promotions from "./promotions";
import * as Tariffs from "./tariffs";
import * as Companies from "./companies";
import * as CompanyCategories from "./company_categories";
import * as Reports from "./reports";
import * as ExternalPromotions from "./external_promotions";
import * as Blog from "./blog";
import axios from "axios";
import qs from "qs";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0"
  };

  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

export {
  Sales,
  Franchises,
  Investments,
  Users,
  Countries,
  Cities,
  Categories,
  ProductOptions,
  OrgTypes,
  Payments,
  Products,
  Analytics,
  Promotions,
  Tariffs,
  Companies,
  CompanyCategories,
  Reports,
  ExternalPromotions,
  Blog
};
