import { useEffect, useState } from "react";
import { Loading } from "@components";
import { Card } from "antd";
import classNames from "classnames";
import Head from "./head";
import Gallery from "./gallery";
import Files from "./files";
import Options from "./options";
import Info from "./info";
import Contacts from "./contacts";
import Stats from "./stats";
import History from "./history";
import { Promotions, Reports } from "@modules/moderator";
import getDetailsByType from "./details";
import * as API from "@api/moderator";
import IncomingCalls from "./contacts/IncomingСalls";

export default function ListingInfo({
  className,
  type = "sale",
  id = null,
  initialData = null,
}) {
  const [loading, setLoading] = useState(!initialData);
  const [data, setData] = useState(initialData);
  const Details = getDetailsByType(type);

  const api =
    type === "franchise"
      ? API.Franchises
      : type === "investment"
      ? API.Investments
      : API.Sales;

  useEffect(() => {
    if (!data) {
      fetchListing();
    }
    /* eslint-disable-next-line */
  }, [data]);

  const fetchListing = () =>
    api
      .getOne({ id })
      .then((res) => setData(res.data))
      .then(() => setLoading(false));

  if (loading) return <Loading compact />;

  return (
    <div className={classNames("grid grid-cols-12 gap-4", className)}>
      <div className="col-span-12">
        <Head data={data} />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col gap-4">
        <Gallery data={data} />
        <Files data={data} />
        <Options data={data} />
        <Contacts data={data} />
        <Stats data={data} />
        <History data={data} />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-8 flex flex-col gap-4">
        <Info data={data} />
        <Details data={data} />
        <Card bordered={false} className="p-0" title="Продвижения">
          <Promotions.List
            compact
            showHeader={false}
            showFilters={[]}
            defaultFilterValues={{ product_id: data?.product?.id }}
          />
        </Card>
        <IncomingCalls productId={data?.product?.id} />
        <Card bordered={false} className="p-0" title="Жалобы">
          <Reports.List
            compact
            showHeader={false}
            showFilters={[]}
            defaultFilterValues={{ product_id: data?.product?.id }}
          />
        </Card>
      </div>
    </div>
  );
}
