import { List, Button, Divider, Popconfirm } from "antd";
import { PlusOutlined, SwapOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState, Fragment } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Add from "./add";

export default function ServicesList({ form, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [items, setItems] = useState(form?.getFieldValue("services") || []);

  useEffect(() => {
    form.setFieldsValue({ services: items });
    /* eslint-disable-next-line */
  }, [items]);

  const triggerModal = () => setModalOpen(!modalOpen);

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onItemRemove = (key) => {
    const newItems = items.filter((item, index) => index !== key);
    setItems(newItems);
  };

  return (
    <Fragment>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        <List
          // pagination={{
          //   pageSize: 100,
          //   className: "hidden",
          // }}
          dataSource={items}
          renderItem={(item, key) => (
            <SortableItem key={key}>
              <List.Item className="p-0 list-none">
                <div className="w-full grid grid-cols-12 gap-4 py-3">
                  <div className="col-span-12 lg:col-span-8">
                    <span className="block text-base w-full text-black font-medium">
                      {item?.title}
                    </span>
                  </div>
                  <div className="col-span-12 lg:col-span-4 flex justify-end">
                    <span className="block text-base text-right w-full text-primary font-medium">
                      {item?.priceFrom && "От "}
                      {Intl.NumberFormat("ru-KZ", {
                        currency: "kzt",
                        style: "currency",
                        maximumFractionDigits: 0,
                      }).format(item?.price)}
                    </span>
                    <div className="flex items-center">
                      <Divider type="vertical" />
                      <SwapOutlined
                        className="text-neutral-400 cursor-grab hover:text-neutral-700"
                        style={{ rotate: "90deg" }}
                      />
                      <Divider type="vertical" />
                      <Popconfirm
                        onConfirm={() => onItemRemove(key)}
                        okText="Удалить"
                        title="Удалить услугу?"
                      >
                        <Button
                          danger
                          type="link"
                          className="m-0 p-0 h-fit w-fit"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </div>
                  </div>
                </div>
              </List.Item>
            </SortableItem>
          )}
          footer={
            <Button
              disabled={items.length >= 50}
              onClick={triggerModal}
              block
              icon={<PlusOutlined />}
              type="dashed"
            >
              Добавить услугу
            </Button>
          }
        />
      </SortableList>
      <Add
        {...props}
        open={modalOpen}
        onCancel={triggerModal}
        onAfterAdd={(item) => {
          setItems([...items, item]);
          triggerModal();
        }}
      />
    </Fragment>
  );
}
