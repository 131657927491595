import { useState, useEffect } from "react";
import { Card, Form, message, Button, Divider, Input, Select } from "antd";
import { Loading } from "@components";
import { useNavigate, useParams } from "react-router-dom";
import * as API from "@api/moderator";

export default function Edit() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    /* eslint-disable-next-line */
  }, []);

  const getData = async () => {
    setLoading(true);
    const _res = await API.ProductOptions.getOne({ id });

    if (!_res || _res.error) {
      return message.error(
        "Ошибка запроса данных. Пожалуйста обновите страницу или обратитесь к администратору.",
        2
      );
    }

    setData(_res.data);
    setLoading(false);
  };

  const onFormSubmit = async (values) => {
    setSaving(true);
    const _res = await API.ProductOptions.update(values);
    setSaving(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка при отправке. Пожалуйста, попробуйте снова или обратитесь к администратору.",
        2
      );
    }

    return message.success("Запись успешно обновлена.", 1, () =>
      navigate("/dict/options")
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card bordered={false}>
      <Form
        className="global-form"
        layout="vertical"
        scrollToFirstError
        onFinish={onFormSubmit}
        initialValues={data}
      >
        <Form.Item name="id" hidden>
          <Input hidden />
        </Form.Item>
        <Form.Item
          label="Название"
          help="Введите название опции"
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название.",
            },
          ]}
        >
          <Input placeholder="Введите название" />
        </Form.Item>
        <Form.Item
          label="Для каких объявлений"
          name="product_type"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите категорию объявлений.",
            },
          ]}
        >
          <Select showSearch placeholder="Выберите для каких объявлений">
            <Select.Option value="sale">Бизнес</Select.Option>
            <Select.Option value="franchise">Франшизы</Select.Option>
            <Select.Option value="investment">Ивестиции</Select.Option>
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item name="submit">
          <Button loading={saving} type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
