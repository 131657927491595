import classNames from "classnames";

export default function ProductCard({ listing = null, className }) {
  const getProductStatus = () => {
    switch (listing.product.status) {
      case "in_review":
        return "На модерации";

      case "published":
        return "Опубликован";

      case "rejected":
      case "declined":
        return "Отклонен";

      case "archived":
      case "unpublished":
        return "Снято с сайта";

      case "draft":
        return "Черновик";

      default:
        break;
    }
  };
  return (
    <div className={classNames("w-full", className)}>
      <span className="block m-0 text-sm leading-none text-neutral-400">
        {getProductStatus()}
      </span>
      <h1 className="block text-xl font-semibold text-neutral-700">
        {listing.product.title}
      </h1>
    </div>
  );
}
