import { Card, Form, Radio, Button } from "antd";
import {
  BankOutlined,
  ApartmentOutlined,
  RocketOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import classNames from "classnames";

export default function TypeSelector({
  setStage,
  visible,
  form,
  isApp = false,
}) {
  const { t } = useTranslation();

  return (
    <Fade
      count={1}
      spy={visible}
      exit={false}
      duration={300}
      distance="5px"
      bottom
    >
      <Card
        hidden={!visible}
        className={classNames("shadow-lg shadow-gray-100 transition-all", {
          "app-card": isApp,
        })}
      >
        <h2 className="w-full text-center my-1">
          {t("listings.typeSelectTitle")}
        </h2>
        <span className="block w-full max-w-md m-auto text-center text-slate-500 text-sm mb-10">
          {t("listings.typeSelectText")}
        </span>
        <Form.Item noStyle name="type">
          <Radio.Group
            onChange={() => setStage(1)}
            className="grid grid-cols-3 gap-4"
          >
            <div className="col-span-3 lg:col-span-1">
              <Radio.Button value="sale" className="card type-selector">
                <div className="selector-content">
                  <BankOutlined />
                  <span className="text-sm font-medium text-black my-1">
                    {t("listings.typeSelectBusinessTitle")}
                  </span>
                  <span className="text-xs text-slate-400">
                    {t("listings.typeSelectBusinessText")}
                  </span>
                </div>
              </Radio.Button>
            </div>
            <div className="col-span-3 lg:col-span-1">
              <Radio.Button value="franchise" className="card type-selector">
                <div className="selector-content">
                  <ApartmentOutlined />
                  <span className="text-sm font-medium text-black my-1">
                    {t("listings.typeSelectFranchiseTitle")}
                  </span>
                  <span className="text-xs text-slate-400">
                    {t("listings.typeSelectFranchiseText")}
                  </span>
                </div>
              </Radio.Button>
            </div>
            <div className="col-span-3 lg:col-span-1">
              <Radio.Button value="investment" className="card type-selector">
                <div className="selector-content">
                  <RocketOutlined />
                  <span className="text-sm font-medium text-black my-1">
                    {t("listings.typeSelectStartupTitle")}
                  </span>
                  <span className="text-xs text-slate-400">
                    {t("listings.typeSelectStartupText")}
                  </span>
                </div>
              </Radio.Button>
            </div>
          </Radio.Group>
        </Form.Item>

        <div hidden={!form.getFieldValue("type")} className="flex justify-end">
          <Button onClick={() => setStage(1)} className="mt-5" type="primary">
            {t("generic.next")} <RightOutlined />
          </Button>
        </div>
      </Card>
    </Fade>
  );
}
