import { createRef, useEffect } from "react";
import { Image, Card, Empty } from "antd";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Imgproxy } from "@utils";
import "@splidejs/react-splide/css";

export default function Gallery({ data, className }) {
  const mainRef = createRef();
  const thumbsRef = createRef();

  useEffect(() => {
    syncSlides();
  });

  const syncSlides = async () => {
    if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
      return mainRef?.current?.sync(thumbsRef.current.splide) || null;
    }
  };

  const renderSlides = () => {
    return data?.company?.image_list?.map((slide, i) => (
      <SplideSlide className="bg-neutral-50 flex" key={i}>
        <Image
          className="mx-auto object-contain object-center"
          rootClassName="mx-auto"
          // rootClassName="block object-contain object-center"
          src={Imgproxy.getURL(slide)}
          alt="Изображение объявления"
        />
      </SplideSlide>
    ));
  };

  const renderThumbs = () => {
    return data?.company?.image_list?.map((slide, i) => (
      <SplideSlide className=" bg-neutral-50" key={i}>
        <img
          style={{ minHeight: "unset", minWidth: "unset", maxHeight: "unset" }}
          className="block w-full h-full object-contain object-center"
          src={Imgproxy.getURL(slide, {
            width: 110,
            height: 70,
            resize: "fit",
            background: "fff"
          })}
          alt="Изображение объявления"
        />
      </SplideSlide>
    ));
  };

  const mainOptions = {
    type: "loop",
    perPage: 1,
    perMove: 1,
    gap: "1rem",
    pagination: false,
    cover: true,
    autoHeight: true
    // height: 200,
  };

  const thumbsOptions = {
    type: "slide",
    rewind: true,
    gap: "0.2rem",
    pagination: false,
    fixedWidth: 110,
    fixedHeight: 70,
    cover: true,
    focus: "center",
    isNavigation: true
  };

  if (!data?.company?.image_list?.length) {
    return (
      <Card bordered={false} title="Фотографии" className={className}>
        <Empty description="Фото не предоставлено" />
      </Card>
    );
  }

  return (
    <Card bordered={false} title="Фотографии" className={className}>
      <Splide options={mainOptions} ref={mainRef}>
        {renderSlides()}
      </Splide>

      <Splide className="mt-5" options={thumbsOptions} ref={thumbsRef}>
        {renderThumbs()}
      </Splide>
    </Card>
  );
}
