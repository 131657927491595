import { Modal, Input, Checkbox, InputNumber, message } from "antd";
import { useEffect, useState } from "react";

export default function AddModal({ open, onCancel, ...props }) {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(null);
  const [priceFrom, setPriceFrom] = useState(false);

  useEffect(() => {
    setTitle("");
    setPrice(null);
    setPriceFrom(false);
  }, [open]);

  const onAdd = () => {
    if (!title || title === "") {
      return message.error("Введите название услуги", 2);
    }

    if (!price) {
      return message.error("Введите стоимость услуги", 2);
    }

    props.onAfterAdd({ title, price, priceFrom });
  };

  return (
    <Modal
      destroyOnClose
      title="Добавление услуги"
      centered
      open={open}
      onCancel={onCancel}
      okText="Добавить"
      onOk={onAdd}
    >
      <div className="grid grid-cols-12 gap-4 my-5">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Название услуги
          </span>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Введите название услуги"
          />
        </div>
        <div className="col-span-12 lg:col-span-6 h-full flex flex-col justify-center">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Стоимость
          </span>
          <InputNumber
            min={0}
            max={999999999}
            className="w-full"
            prefix="₸"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            placeholder="Стоимость в тенге"
            controls={false}
            onChange={setPrice}
            value={price}
          />
        </div>
        <div className="col-span-12 lg:col-span-6 h-full flex flex-col justify-center">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Дополнительно
          </span>
          <Checkbox
            checked={priceFrom}
            onChange={(e) => setPriceFrom(e.target.checked)}
          >
            Стоимость «от»
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
}
