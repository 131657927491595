import { useEffect, useState } from "react";
import { message, Skeleton, Card, Button, Tag, Tooltip } from "antd";
import { EyeOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons";
import { findIndex, without } from "ramda";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Imgproxy } from "@utils";
import { useLocale } from "@contexts/locale";
import * as API from "@api/customer";
import config from "@config";

import "dayjs/locale/ru";
import "dayjs/locale/kk";

dayjs.locale("ru");

export default function Favorites({ className, initialData }) {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [loading, setLoading] = useState(!Array.isArray(initialData?.data));
  const [data, setData] = useState(initialData?.data || []);
  const [total, setTotal] = useState(initialData?.count || 0);

  if (locale === "kk") {
    dayjs.locale("kk");
  }

  useEffect(() => {
    return () => getTableData();
    /* eslint-disable-next-line */
  }, []);

  // Get all favorites
  async function getTableData() {
    setLoading(true);
    const _res = await API.Favorites.getAll({
      limit: 9,
      offset: data?.length || 0
    });

    if (!_res.data) {
      return message.error(t("generic.serverError"), 2);
    }

    setData([...data, ..._res.data.rows]);
    setTotal(_res.data.count);
    setLoading(false);
  }

  async function unlike(id) {
    await API.Favorites.remove({ id });
    const index = findIndex(item => item.product.id === id, data);
    setData(without([data[index]], data));
  }

  if (!data?.length)
    return (
      <div className="flex flex-col justify-center items-center py-10">
        <HeartOutlined className="text-3xl text-neutral-400 mb-5" />
        <span className="text-xl text-black mb-2">{t("favorites.emptyTitle")}</span>
        <span className="block text-base text-neutral-500 text-center">{t("favorites.emptyText")}</span>
      </div>
    );

  return (
    <div className="grid grid-cols-12 gap-4">
      {data?.map((item, i) => (
        <div key={item?.product?.id + "-" + i} className="col-span-12 md:col-span-6 lg:col-span-4">
          <Card className="relative h-full" bodyStyle={{ padding: 0, overflow: "hidden" }}>
            <Tag color="green" className="absolute left-5 top-5 m-0">
              {item.product.type === "sale" && t("generic.business")}
              {item.product.type === "franchise" && t("generic.franchise")}
              {item.product.type === "investment" && t("generic.startup")}
            </Tag>
            <img
              className="block w-full h-52 rounded-sm object-cover object-center"
              src={Imgproxy.getURL(item.product.image_main, {
                width: 320,
                height: 210
              })}
              alt={item?.product?.title || "Объявление"}
              height={208}
            />

            <div className="p-5">
              <span className="text-sm text-slate-500 block">
                {locale === "kk" ? item?.category?.name_kk : locale === "en" ? item.category.name_en : item.category.name}
              </span>
              <span className="text-lg font-semibold block text-black">{item.product.title}</span>
              <span className="text-sm font-semibold block text-green-600">
                {new Intl.NumberFormat("ru-KZ", {
                  style: "currency",
                  currency: "KZT",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
                }).format(item.product.price)}
              </span>
              <div className="flex justify-between items-center mt-4">
                <Button
                  href={`${config.frontend}/${item.product.type}/${item.product.id}/${item.product.slug}`}
                  target="_blank"
                  className="m-0 p-0"
                  type="link"
                  icon={<EyeOutlined />}
                >
                  {t("generic.open")}
                </Button>
                <Tooltip title={t("favorites.remove")}>
                  <Button
                    onClick={() => unlike(item.product.id)}
                    shape="circle"
                    className="m-0 p-0 text-red-500 hover:text-red-200"
                    type="link"
                    icon={<HeartFilled style={{ fontSize: 20 }} />}
                  ></Button>
                </Tooltip>
              </div>
            </div>
          </Card>
        </div>
      ))}
      {total > data?.length && (
        <div className="col-span-12 flex justify-center items-center py-5">
          <Button onClick={getTableData} className="mx-auto" type="primary" loading={loading}>
            {t("generic.loadMore")}
          </Button>
        </div>
      )}
      {loading && [
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Skeleton />
        </div>,
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Skeleton />
        </div>,
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Skeleton />
        </div>
      ]}
    </div>
  );
}
