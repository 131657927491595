import { Button } from "antd";
import { Companies } from "@modules/partner";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

export default function DashboardPage() {
  return (
    <div className="my-5">
      <div className="flex justify-between items-center gap-4">
        <h2 className="m-0">Мои компании</h2>
        <Link to="/companies/new">
          <Button icon={<PlusOutlined />} type="primary">
            Добавить компанию
          </Button>
        </Link>
      </div>

      <Companies.List />
    </div>
  );
}
