import {
  PhoneFilled,
  InstagramFilled,
  FacebookFilled,
  WhatsAppOutlined,
  SendOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

export const getContactType = (contact) => {
  switch (contact.type) {
    case "phone":
      return {
        ...contact,
        icon: <PhoneFilled />,
        label: "Телефон",
        prefix: "+"
      };

    case "instagram":
      return {
        ...contact,
        icon: <InstagramFilled className="text-red-500" />,
        label: "Instagram",
        prefix: ""
      };

    case "facebook":
      return {
        ...contact,
        icon: <FacebookFilled className="text-blue-600" />,
        label: "Facebook",
        prefix: ""
      };

    case "whatsapp":
      return {
        ...contact,
        icon: <WhatsAppOutlined className="text-green-500" />,
        label: "WhatsApp",
        prefix: "+"
      };

    case "telegram":
      return {
        ...contact,
        icon: <SendOutlined className="text-blue-600" />,
        label: "Telegram",
        prefix: "@"
      };

    default:
      return {
        ...contact,
        icon: <QuestionCircleFilled />,
        label: "Неизвестный",
        prefix: ""
      };
  }
};
