import axios from "axios";
import qs from "qs";
import config from "@config";
import { getAuthToken } from "@utils";
import * as Sentry from "@sentry/react";
import * as Categories from "./categories";
import * as Cities from "./cities";
import * as Countries from "./countries";
import * as Static from "./static";
import * as Users from "./users";
import * as Auth from "./auth";
import * as Tariffs from "./tariffs";
import * as ProductOptions from "./product_options";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0"
  };

  if (getAuthToken()) {
    config.headers.Authorization = `Bearer ${getAuthToken()}`;
  }

  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Skip auth errors
    if (error?.response?.status > 403) {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

// Sends request to frontend client to revalidate next.js data
const revalidateClientData = data =>
  axios
    .post(`${config.frontend}/api/revalidate?secret=${config.revalidateSecret}`, data, {
      withCredentials: false
    })
    .then(res => res.data)
    .catch(err => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error revalidating product");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера"
      };
    });

export { Auth, Categories, Cities, Countries, Static, Users, Tariffs, ProductOptions, revalidateClientData };
