import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Avatar, Button } from "antd";
import { services } from "../list/filters/services";
import { find, propEq } from "ramda";
import { Link } from "react-router-dom";
import { Imgproxy } from "@utils";
import * as API from "@api/moderator";
import dayjs from "dayjs";

export default function Promotion({ initialData = null, id = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchData = () => {
    setLoading(true);

    API.Promotions.getOne({ id }).then(res => {
      if (!res?.data || !res?.data?.calculation) {
        setLoading(false);
        return message.error("Ошибка, попробуйте еще раз", 2);
      }

      setData(res.data.calculation);
      setLoading(false);
    });
  };

  if (loading) {
    return <Loading compact />;
  }

  const service = find(propEq("value", data?.service_code), services);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <span className="block text-base font-semibold text-slate-700 mb-2">Объявление</span>
        <span className="block text-base font-normal text-slate-800">
          <Link to={`/listings/view/${data?.product?.type}/${data?.product?.id}`}>{data?.product?.title}</Link>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Тариф/сервис</span>
        <span className="block text-base font-normal text-slate-800">
          <div className="flex justify-start items-center">
            <img src={service?.icon} alt="Иконка продвижения" className="block h-5 mr-2" />
            <i className="not-italic block">{service?.label}</i>
          </div>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Дата активации</span>
        <span className="block text-base font-normal text-slate-800">{dayjs(data?.created_at).format("HH:mm DD.MM.YYYY")}</span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Стоимость</span>
        <span className="block text-base font-normal text-slate-800">
          {new Intl.NumberFormat("ru-KZ", {
            style: "currency",
            currency: "KZT",
            maximumFractionDigits: 0
          }).format(data?.payment_amount)}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Начало продвижения</span>
        <span className="block text-base font-normal text-slate-800">{dayjs(data?.service_start_date).format("HH:mm DD.MM.YYYY")}</span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Конец продвижения</span>
        <span className="block text-base font-normal text-slate-800">{dayjs(data?.service_end_date).format("HH:mm DD.MM.YYYY")}</span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Покупатель</span>
        <span className="flex flex-wrap justify-start items-center">
          <Avatar src={Imgproxy.getURL(data?.user?.avatar, { width: 100, height: 100 })} className="mr-2" />
          <div>
            <span className="block text-base font-medium text-slate-800 leading-tight">{data?.user?.display_name}</span>
            <Link target="_blank" to={`/users/view/${data?.user?.id}`} className="block text-sm leading-none">
              {data?.user?.email}
            </Link>
          </div>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">Оплата</span>
        <span className="block text-base font-normal text-slate-800">
          <Link target="_blank" to={`/payments`}>
            <Button>Смотреть платежи</Button>
          </Link>
        </span>
      </div>
    </div>
  );
}
