import { Breadcrumb } from "@components";
import { Listings } from "@modules/moderator";
import { useLoaderData } from "react-router-dom";

export default function ListingsPage() {
  const { id, type, ...data } = useLoaderData();
  return (
    <div>
      <Breadcrumb
        showBack
        data={[
          { title: "Объявления", href: "/listings" },
          { title: "Данные объявления", href: `/listings/view/${type}/${id}` },
        ]}
      />
      <Listings.Info intialData={data} id={id} type={type} />
    </div>
  );
}
