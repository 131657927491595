import { Card, Divider, Button, Form } from "antd";
import classNames from "classnames";
import ServiceList from "./services/list";
import PhotosList from "./photos/list";

export default function Info({ className, loading = false, ...props }) {
  return (
    <div
      className={classNames("grid grid-cols-12 gap-4", {
        hidden: !props?.visible,
      })}
    >
      <div className="col-span-12">
        <Card title="Услуги">
          <ServiceList {...props} />
        </Card>
      </div>
      <div className="col-span-12">
        <Card title="Фото">
          <PhotosList {...props} />
        </Card>
      </div>
      <div className="col-span-12">
        <Divider className="mt-3" />
        <div className="flex justify-between items-center">
          <Button onClick={props.onPrev}>Назад</Button>
          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              htmlType="submit"
              type="primary"
            >
              Далее
            </Button>
          </Form.Item>
        </div>
      </div>
    </div>
  );
}
