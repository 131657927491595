import { Button, Popover } from "antd";
import { RiseOutlined } from "@ant-design/icons";
import { tariffDescription } from "@modules/customer/tariffs/packages/reference";
import { useTranslation } from "react-i18next";
import config from "@config";

export default function PromotionsButtons({ data, isApp = false }) {
  const { t } = useTranslation();

  if (!config.paidServices) {
    return (
      <Button type="link" className="block p-0 m-0" disabled>
        {t("listings.promoUnavailable")}
      </Button>
    );
  }

  const activePromotions = () => {
    const tariffPreview = info => {
      return (
        <div className="w-fit" style={{ maxWidth: 230 }}>
          <span className="text-sm text-black">{info?.description}</span>
          <img src={info?.img} alt="Изображение" className="block w-full mt-5" />
        </div>
      );
    };

    if (data?.product?.services?.length > 0) {
      return (
        <div className="mt-3">
          <span className="block text-xs leading-none text-neutral-400">{t("listings.promoActive")}</span>
          <div className="flex flex-wrap items-center mt-3">
            {data.product.services.map((service_code, i) => {
              const info = tariffDescription({ service_code });

              return (
                info && (
                  <Popover key={i} placement="bottom" title={info?.name} content={tariffPreview(info)}>
                    <img src={info?.icon} className="block cursor-help mr-2" alt="Информация" width={20} height={20} />
                  </Popover>
                )
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  if (isApp) {
    return (
      <Button
        block
        type="default"
        icon={<RiseOutlined />}
        className="block mt-3"
        size="large"
        disabled={data?.product?.status === "unpublished" || data?.product?.status === "declined"}
        href={`/app/promote/${data.product.type}/${data.product.id}`}
      >
        {t("listings.toPromote")}
      </Button>
    );
  }

  return (
    <div>
      <span className="text-xs leading-none text-neutral-400">{t("listings.pushListings")}</span>
      <Button
        block
        type="primary"
        icon={<RiseOutlined />}
        className="block mt-2 w-fit"
        disabled={data?.product?.status === "unpublished" || data?.product?.status === "declined"}
        href={`/promote/${data.product.type}/${data.product.id}`}
      >
        {t("listings.toPromote")}
      </Button>
      {activePromotions()}
    </div>
  );
}
