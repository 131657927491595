import { Button } from "antd";
import { Tariffs } from "@modules/customer";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function Promote({
  listing,
  tariffsData,
  type,
  className,
  isApp = false,
}) {
  const appPrefix = isApp ? "/app" : "";

  return (
    <div
      className={classNames("container content-max-promote py-5", className)}
    >
      <div className="layout-card p-5 lg:p-9">
        <Tariffs.ProductCard listing={listing} />
        <Tariffs.Packages isApp={isApp} listing={listing} data={tariffsData} />
        <Tariffs.Premium />
        <Link
          to={`${appPrefix}/promote/additional/${type}/${listing.product.id}`}
        >
          <span className="block w-full bg-green-100 rounded-md py-5 px-3 font-semibold text-center text-xl">
            Выбрать способ продвижения вручную
          </span>
        </Link>
        <Tariffs.External listing={listing} />
        <Link to={isApp ? "/app/listings/all" : "/"}>
          <Button className="mt-6" size="large" type="primary">
            Продолжить без продвижения
          </Button>
        </Link>
      </div>
    </div>
  );
}
