import { Form, Input } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="name_kk"
      className="w-full"
      label="Название (Қазақша)"
      rules={[
        {
          required: true,
          message: "Это поле обязательно к заполнению"
        }
      ]}
    >
      <Input controls={false} minLength={3} className="w-full" placeholder="Введите название" />
    </Form.Item>
  );
}
