import { message } from "antd";
import * as API from "@api/customer";

export default async function PageLoader() {
  const params = {
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  };

  const _res = await API.Payments.getAll({
    limit: params.pageSize,
    offset: params.current * params.pageSize - params.pageSize,
  });

  if (!_res.data) {
    return message.error(
      "Невозможно загрузить данные. Проверьте соединение с интернетом или свяжитесь с нами.",
      2
    );
  }

  return {
    data: _res.data.rows,
    pagination: { ...params, total: _res.data.count },
  };
}
