import { Button, Card, Avatar, Tag } from "antd";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Imgproxy } from "@utils";

export default function Contacts({ data, className }) {
  return (
    <Card bordered={false} title="Контакты объявления" className={className}>
      <div className="flex justify-start items-center">
        <Avatar
          size="large"
          icon={<UserOutlined />}
          src={Imgproxy.getURL(data?.product?.user?.avatar, {
            width: 100,
            height: 100,
          })}
        />
        <div className="ml-2">
          <span className="block text-base font-medium text-black leading-tight">
            {data?.product?.display_name}
            {data?.product?.meta?.published_by_broker ? (
              <Tag color="orange" className="m-0 ml-2">
                Брокер
              </Tag>
            ) : (
              <Tag color="green" className="m-0 ml-2">
                Владелец
              </Tag>
            )}
          </span>
          <span className="block text-sm font-normal text-neutral-500 leading-tight">
            <Link to={`/users/view/${data?.product?.user?.id}`}>
              Смотреть профиль
            </Link>
          </span>
        </div>
      </div>
      <span className="block text-sm text-neutral-600 mt-5">
        Номера телефонов:
      </span>
      {data?.product?.contacts?.map((contact, key) => (
        <Button
          key={key}
          icon={<PhoneOutlined />}
          type="link"
          className="block w-fit h-fit p-0 my-2 last:mb-0"
          href={`tel:${contact?.phone}`}
        >
          {contact?.phone}
        </Button>
      ))}
    </Card>
  );
}
