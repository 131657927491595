import {
  CheckCircleFilled,
  StopFilled,
  InfoCircleFilled,
  DeleteFilled,
} from "@ant-design/icons";

export const types = [
  {
    value: "franchise",
    label: "Франшиза",
  },
  {
    value: "sale",
    label: "Бизнес",
  },
  {
    value: "investment",
    label: "Инвестиции",
  },
];

export const statuses = [
  {
    value: "published",
    label: "На сайте",
    tagProps: {
      status: "success",
      color: "green",
      icon: <CheckCircleFilled />,
    },
  },
  {
    value: "declined",
    label: "Отклонено",
    tagProps: {
      status: "error",
      color: "red",
      icon: <StopFilled />,
    },
  },
  {
    value: "in_review",
    label: "Требует модерации",
    tagProps: {
      status: "warning",
      color: "orange",
      icon: <InfoCircleFilled />,
    },
  },
  {
    value: "unpublished",
    label: "Убрано с сайта",
    tagProps: {
      status: "error",
      color: "default",
      icon: <DeleteFilled />,
    },
  },
];
