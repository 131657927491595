import { Card, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Blog } from "@modules/moderator";
import { useSearchParams, Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import qs from "qs";

export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilter = filter => {
    setSearchParams(
      qs.stringify(filter, {
        arrayFormat: "brackets",
        encode: false,
        skipNulls: true,
        addQueryPrefix: true
      })
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center flex-wrap gap-4 mb-7">
        <h2 className="block text-xl text-black">Материалы</h2>
        <Link to="/blog/articles/new">
          <Button type="primary" icon={<PlusOutlined />}>
            Добавить материал
          </Button>
        </Link>
      </div>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card bordered={false} className="h-full">
              <Blog.Articles.List
                searchParams={qs.parse(searchParams.toString(), {
                  ignoreQueryPrefix: true
                })}
                setSearchParams={setFilter}
              />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
