import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import dayjs from "dayjs";
import * as API from "@api/moderator";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const editorInstance = useRef(null);

  useEffect(() => {
    API.Blog.getArticle({ id }).then(res => {
      if (res.error || !res) {
        return () => message.error("Unable to load data, please try again later.", 2);
      }

      if (res.data.published_at) {
        res.data.published_at = dayjs(res.data.published_at);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async values => {
    setLoading(true);
    let content = null;

    if (editorInstance?.current) {
      content = (await editorInstance.current.save()) || null;
    }

    if (data?.type_id === 7) {
      const blocks = form.getFieldValue("blocks") || [];
      content = {
        time: Date.now(),
        blocks,
        version: "custom"
      };
    }

    const meta = form.getFieldValue("meta") || null;
    const image = form.getFieldValue("image") || null;
    const tts = form.getFieldValue("tts") || null;
    const tags = form.getFieldValue("tags")?.map(tag => tag?.data) || [];
    const translated_article_id = form.getFieldValue("translated_article_id") || null;
    const nextStatus = form.getFieldValue("next_status") || null;

    if (!content || !content.blocks || !content.blocks.length) {
      setLoading(false);
      return message.error("Материал не может быть пустым", 2);
    }

    if (!image) {
      setLoading(false);
      return message.error("Необходимо добавить изображение", 2);
    }

    const normalizedValues = Object.entries(values).reduce((acc, [key, value]) => {
      acc[key] = value !== undefined ? value : null;
      return acc;
    }, {});

    API.Blog.updateArticle({
      id,
      ...normalizedValues,
      meta,
      content,
      image,
      tts,
      tags,
      translated_article_id
    })
      .then(async res => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Ошибка при сохранении материала, попробуйте позже");
        }

        if (nextStatus === "published") {
          const published = await API.Blog.publishArticle({ id });

          if (published?.error || published instanceof Error) {
            return message.error(published?.message || "Ошибка во время публикации материала, попробуйте позже", 2);
          }

          return message.success("Материал успешно отправлен на публикацию", 2, () => window.location.reload());
        }

        if (nextStatus === "unpublished") {
          const unpublished = await API.Blog.unpublishArticle({ id });

          if (unpublished?.error || unpublished instanceof Error) {
            return message.error(unpublished?.message || "Ошибка во время снятия с публикации, попробуйте позже", 2);
          }

          return message.success("Материал снят с публикации", 2, () => window.location.reload());
        }

        setLoading(false);

        return message.success("Материал сохранен", 1, () => window.location.reload());
      })
      .catch(err => {
        setLoading(false);

        return message.error(err.message || "Ошибка при сохранении материала, попробуйте позже", 2);
      });
  };

  const onPublish = () => {
    onSave({
      ...form.getFieldsValue(),
      status: "published"
    });
  };

  const onUnpublish = async () => {
    setLoading(true);
    API.Blog.updateArticle({
      id,
      status: "draft"
    })
      .then(async res => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error unpublishing article");
        }

        return message.success("Article unpublished", 1, () => window.location.reload());
      })
      .catch(err => message.error(err.message || "Error unpublishing article", 2))
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={err => err?.errorFields?.map(msg => message.error(msg.errors[0], 2))}
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm form={form} data={data} loading={loading} onSave={onSave} onPublish={onPublish} onUnpublish={onUnpublish} editorInstance={editorInstance} />
    </Form>
  );
}
