import { Companies } from "@modules/partner";

export default function NewCompany({ isApp = false }) {
  return (
    <div className="my-5">
      <h2 className="mb-5 lg:mb-10">Добавление компании</h2>
      <Companies.Form.New isApp={isApp} />
    </div>
  );
}
