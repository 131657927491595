import { useEffect, useState } from "react";
import { Table, Button, message, Badge } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { pathOr, splitAt, includes } from "ramda";
import { statusMap } from "../";
import * as Filters from "./filters";
import * as API from "@api/moderator";
import { Imgproxy } from "@utils";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

export default function List({
  showHeader = true,
  showFilters = ["search", "status", "categories", "users"],
  defaultFilterValues = null,
  compact = false,
  pageSize = 10,
  showPagination = true
}) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    category_id: pathOr(undefined, ["category_id"], defaultFilterValues),
    is_active: pathOr(undefined, ["is_active"], defaultFilterValues),
    user_id: pathOr(undefined, ["user_id"], defaultFilterValues)
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: total => `Найдено: ${total}`
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter, search]);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Companies.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: total => `Найдено: ${total}`
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter({ ...filter, [f]: v || undefined }, setPagination({ ...pagination, current: 1 }));
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "logo",
      key: "logo",
      align: "center",
      hidden: true,
      width: 130,
      className: "align-top",
      responsive: ["sm"],
      render: (_, data) => (
        <img
          src={
            data?.company?.logo
              ? `${Imgproxy.getURL(data.company.logo, {
                  width: 100,
                  height: 100,
                  resize: "fit",
                  background: "fff"
                })}`
              : "/img/no-image.svg"
          }
          className="block w-full"
          style={{ maxWidth: 150 }}
          alt="Логотип"
        />
      )
    },
    {
      title: "Компания",
      dataIndex: "info",
      key: "info",
      align: "left",
      width: 450,
      render: (_, data) => (
        <div>
          {<Badge status={statusMap(data?.company?.status)?.badgeStatus} text={statusMap(data?.company?.status)?.badgeLabel} />}
          <Link to={`/companies/view/${data?.company?.id}`} className="block my-1 text-base text-black">
            {data?.company?.title}
          </Link>
          <span className="block font-normal text-neutral-500 text-xs">
            {data?.category?.name || "Без категории"} &middot; Услуг: {data?.company?.services?.length || 0}
          </span>
        </div>
      )
    },
    {
      title: "Страны",
      dataIndex: "countries",
      key: "countries",
      align: "center",
      responsive: ["sm"],
      render: countries => (
        <div className="flex flex-wrap gap-2 justify-center">
          {countries?.length > 2
            ? `${splitAt(2, countries)[0]
                .map(d => d.name)
                .join(",")}, +${countries.length - 2}`
            : countries.map(d => d.name).join(", ")}
        </div>
      )
    },
    {
      title: "Города",
      dataIndex: "cities",
      key: "cities",
      align: "center",
      responsive: ["sm"],
      render: cities => (
        <div className="flex flex-wrap gap-2 justify-center">
          {cities?.length > 2
            ? `${splitAt(2, cities)[0]
                .map(d => d.name)
                .join(",")}, +${cities.length - 2}`
            : cities.map(d => d.name).join(", ")}
        </div>
      )
    },
    {
      title: "Посл. обновление",
      dataIndex: "date",
      key: "date",
      align: "center",
      responsive: ["sm"],
      render: (_, data) => (
        <div className="flex flex-col">
          <span className="m-0 p-0 text-sm font-medium text-black">{dayjs(data?.company?.updated_at).fromNow()}</span>
          <span className="m-0 p-0 text-sm text-neutral-500">{dayjs(data?.company?.updated_at).format("HH:mm DD.MM.YYYY")}</span>
        </div>
      )
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: ["sm"],
      render: (_, all) => (
        <Link target="_blank" to={`/companies/view/${all?.company?.id}`}>
          <Button icon={<EyeOutlined />}>Открыть</Button>
        </Link>
      )
    }
  ];

  return (
    <div className="grid grid-cols-12 gap-6">
      {includes("search", showFilters) && [
        <div className="col-span-12 lg:col-span-3">
          <Filters.Search onSearch={onSearch} />
        </div>
      ]}
      {includes("status", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Status onChange={onFilterChange} />
        </div>
      )}
      {includes("categories", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Categories onChange={onFilterChange} />
        </div>
      )}
      {includes("users", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <Filters.Users onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={compact ? columns.filter(col => col.key !== "logo" && col.key !== "actions", columns) : columns}
        />
      </div>
    </div>
  );
}
