import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api/moderator";

export default function FormInput(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Blog.getCategories({ limit: 100 }).then(res => {
      if (res.error || !res) {
        return;
      }

      // set grouped data for Antd Select with name, children, etc.
      // for those who don't have parent_id set it to the group named 'Others'
      const grouped = res?.data?.rows?.reduce((acc, row) => {
        const parent = row.parent_id || "Родительские категории";
        if (!acc[parent]) {
          acc[parent] = {
            name: row.parent?.name || "Родительские категории",
            children: []
          };
        }
        acc[parent].children.push(row);
        return acc;
      }, {});
      const groupedArray = Object.values(grouped);
      setData(groupedArray);
    });
  }, []);

  return (
    <Form.Item
      {...props}
      name="category_id"
      className="w-full"
      label="Рубрика"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите рубрику"
        }
      ]}
    >
      <Select
        placeholder="Выберите"
        className="w-full"
        showSearch
        filterOption={(input, option) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
        options={data.map(val => {
          return {
            label: val.name,
            title: val.name,
            value: val.id,
            options: val?.children?.map(child => {
              return {
                label: child.name,
                value: child.id
              };
            })
          };
        })}
      />
    </Form.Item>
  );
}
