import { useState } from "react";
import { Card, Form, Input, Button, Divider } from "antd";
import classNames from "classnames";
import { LoginButton } from "@components";
import {
  ArrowLeftOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";
import config from "@config";

export default function LoginForm({ className }) {
  const { t } = useTranslation();
  const [resetting, setResetting] = useState(false);
  const {
    loading,
    login,
    signInWithGoogle,
    signInWithApple,
    forgotPassword,
    currentUser,
    isVerified,
  } = useAuth();

  return (
    <Card
      hidden={currentUser && !isVerified}
      className={classNames("rounded-md", className)}
    >
      <h2 className="text-xl text-black mb-10">{t("login.cabinetEnter")}</h2>
      <LoginButton.Google
        hidden={resetting}
        loading={loading}
        onClick={signInWithGoogle}
        title={t("login.google")}
      />
      <LoginButton.Apple
        hidden={resetting}
        loading={loading}
        onClick={signInWithApple}
        title={t("login.apple")}
      />
      <Divider hidden={resetting} style={{ fontSize: 10 }}>
        {t("login.or")}
      </Divider>
      <Form
        tabIndex={1}
        className="login-form !mb-0"
        name="signin"
        size="large"
        initialValues={{ remember: true }}
        onFinish={resetting ? forgotPassword : login}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("login.emailRequired"),
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: t("login.emailInvalid"),
            },
          ]}
        >
          <Input
            autoCapitalize="false"
            disabled={loading}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("login.email")}
          />
        </Form.Item>
        <Form.Item
          hidden={resetting}
          name="password"
          rules={[
            {
              min: 6,
              required: !resetting,
              message: t("login.passRequired"),
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder={t("login.pass")}
            disabled={loading}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading}
            block
          >
            {resetting ? t("login.resetPass") : t("login.enter")}
          </Button>
        </Form.Item>
        <Form.Item className="!mb-0" style={{ fontSize: 15 }}>
          <div className="flex justify-between items-center w-full">
            <Button
              type="link"
              className="m-0 p-0"
              disabled={loading}
              hidden={resetting}
            >
              <Link to={`${config.basepath}/register`}>
                {t("login.registration")}
              </Link>
            </Button>
            <Button
              className="m-0 p-0"
              type="link"
              disabled={loading}
              hidden={resetting}
              onClick={() => setResetting(true)}
            >
              {t("login.forgotPass")}
            </Button>
            <Button
              className="m-0 p-0"
              type="link"
              hidden={!resetting}
              disabled={loading}
              onClick={() => setResetting(false)}
              icon={<ArrowLeftOutlined />}
            >
              {t("login.back")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
