import { Card } from "antd";

export default function Details({ data }) {
  const details = data[data?.product?.type];

  return (
    <Card bordered={false} title="Подробная информация">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Сумма взноса
          </span>
          <span className="block text-base font-normal text-slate-800">
            {Intl.NumberFormat("ru-KZ", {
              currency: "kzt",
              style: "currency",
              maximumFractionDigits: 0,
            }).format(details.lump_sum)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Макс. сумма взноса
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details?.is_lump_range
              ? Intl.NumberFormat("ru-KZ", {
                  currency: "kzt",
                  style: "currency",
                  maximumFractionDigits: 0,
                }).format(details.lump_max)
              : "Нет (фикс-сумма)"}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Паушальный взнос входит в стоимость продажи франшизы?
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.is_lump_included ? "Да" : "Нет"}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Роялти
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.royalty_type === "fixed" &&
              new Intl.NumberFormat("ru-KZ", {
                style: "currency",
                currency: "kzt",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(details?.royalty_value)}
            {details.royalty_type === "rate" && `${details?.royalty_value}%`}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Месячная прибыль
          </span>
          <span className="block text-base font-normal text-slate-800">
            {new Intl.NumberFormat("ru-KZ", {
              style: "currency",
              currency: "kzt",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(details?.month_profit)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Окупаемость (мес.)
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.payback_period}
          </span>
        </div>
      </div>
    </Card>
  );
}
