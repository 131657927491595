import { useEffect, useState } from "react";
import { Table, Tag, Button, message, Avatar } from "antd";
import { Link } from "react-router-dom";
import * as API from "@api/moderator/users";
import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import SearchFilter from "./filters/search";
import BlockedFilter from "./filters/blocked";
import EmailFilter from "./filters/email";
import { Imgproxy } from "@utils";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchName, setSearchName] = useState(null);
  const [searchID, setSearchID] = useState(null);
  const [searchPhone] = useState(null);
  const [searchEmail, setSearchEmail] = useState(null);
  const [filters] = useState({
    roles: [
      { id: "customer", name: "Пользователь" },
      { id: "moderator", name: "Модератор" }
    ],
    blocked: [
      { id: 0, name: "Активный" },
      { id: 1, name: "Заблокирован" }
    ],
    email_confirmed: [
      { id: 0, name: "Нет" },
      { id: 1, name: "Да" }
    ]
  });

  const [filter, setFilter] = useState({
    id: undefined,
    role: undefined,
    blocked: undefined,
    email_confirmed: undefined,
    displayName: undefined
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter, searchName, searchEmail, searchPhone, searchID]);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.getAll({
      filter: {
        ...filter,
        display_name: searchName || undefined,
        email: searchEmail || undefined,
        phone: searchPhone || undefined,
        id: searchID || undefined
      },
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter({ ...filter, [f]: v || undefined }, setPagination({ ...pagination, current: 1 }));
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4">
        <SearchFilter
          title="Поиск по ID"
          onChange={v => {
            setSearchID(v);
            setPagination({ ...pagination, current: 1 });
          }}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <SearchFilter
          title="Поиск по имени"
          onChange={v => {
            setSearchName(v);
            setPagination({ ...pagination, current: 1 });
          }}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <SearchFilter
          title="Поиск по эл.почте"
          onChange={v => {
            setSearchEmail(v);
            setPagination({ ...pagination, current: 1 });
          }}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <SearchFilter
          title="Поиск по номеру"
          onChange={v => {
            // replace all non-digit characters to empty string
            setFilter({
              ...filter,
              contacts: v ? v.replace(/\D/g, "") : undefined
            });
            setPagination({ ...pagination, current: 1 });
          }}
        />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <BlockedFilter data={filters.blocked} onChange={onFilterChange} />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <EmailFilter data={filters.email_confirmed} onChange={onFilterChange} />
      </div>
      <div className="col-span-12">
        <Table
          onChange={getTableData}
          pagination={pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          scroll={{ x: "max-content" }}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              align: "id",
              render: data => <span className="text-base">{data}</span>
            },
            {
              title: "Пользователь",
              dataIndex: "display_name",
              key: "display_name",
              align: "left",
              render: (data, all) => (
                <div className="flex justify-start items-center">
                  <Avatar
                    className="m-0 mr-4"
                    size="large"
                    icon={<UserOutlined />}
                    src={Imgproxy.getURL(all?.avatar, {
                      width: 100,
                      height: 100
                    })}
                  />
                  <div>
                    <h4 className="block m-0 text-base">{data}</h4>
                    <span className="block mb-2 font-normal text-slate-500 text-sm">
                      <a rel="noreferrer" target="_blank" href={`mailto:${all.email}`}>
                        {all.email}
                      </a>{" "}
                      &middot; Посл. вход {dayjs(all.last_login_at).format("HH:mm · DD.MM.YYYY")}
                    </span>
                  </div>
                </div>
              )
            },
            {
              title: "Статус",
              dataIndex: "blocked",
              key: "blocked",
              align: "center",
              render: data =>
                data ? (
                  <Tag className="m-0" color="red">
                    Заблокирован
                  </Tag>
                ) : (
                  <Tag className="m-0">Активен</Tag>
                )
            },
            {
              title: "Роль",
              dataIndex: "role",
              key: "role",
              align: "center",
              render: data =>
                data === "moderator" ? (
                  <Tag className="m-0" color="blue">
                    Модератор
                  </Tag>
                ) : (
                  <Tag className="m-0">Пользователь</Tag>
                )
            },
            {
              title: "Регистрация",
              dataIndex: "created_at",
              key: "created_at",
              align: "center",
              render: data => (
                <span className="text-sm text-center font-medium">
                  {dayjs(data).fromNow()}
                  <i className="block not-italic text-neutral-500 font-normal text-sm">{dayjs(data).format("HH:mm DD.MM.YYYY")}</i>
                </span>
              )
            },
            {
              dataIndex: "actions",
              key: "actions",
              align: "right",
              render: (_, all) => (
                <Link target="_blank" to={`/users/view/${all.id}`}>
                  <Button icon={<EyeOutlined />}>Открыть</Button>
                </Link>
              )
            }
          ]}
        />
      </div>
    </div>
  );
}
