import { message } from "antd";
import * as API from "@api/customer";

export default async function PageLoader() {
  const [sales, franchises, investments] = await Promise.all([
    API.Sales.getAll({ limit: 50 }),
    API.Franchises.getAll({ limit: 50 }),
    API.Investments.getAll({ limit: 50 }),
  ]);

  if (
    !sales ||
    sales.error ||
    !franchises ||
    franchises.error ||
    !investments ||
    investments.error
  ) {
    return message.error(
      "Невозможно загрузить данные, попробуйте снова позже",
      3
    );
  }

  return [
    ...sales?.data?.rows,
    ...franchises?.data?.rows,
    ...investments?.data?.rows,
  ];
}
