import { Tooltip, Divider } from "antd";
import {
  CheckOutlined,
  InfoCircleFilled,
  PhoneOutlined,
  DashOutlined,
  EyeOutlined,
  PicCenterOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import Promotions from "./promotions";
import { useTranslation } from "react-i18next";
import { or } from "ramda";

export default function Activity({ data, info, isApp = false }) {
  const { t } = useTranslation();

  const suggestions = () => {
    const sugList = [];

    if (data?.product?.image_list?.length < 3) {
      sugList.push({
        title: t("listings.addPhotosTitle"),
        description: t("listings.addPhotosText"),
      });
    }

    if (data.product.type === "sale") {
      if (
        or(
          info?.month_revenue === 0,
          info?.month_costs === 0,
          info?.month_profit === 0,
          info?.payback_period === 0,
          info?.bin === "000000000000"
        )
      ) {
        sugList.push({
          title: t("listings.addFinanceTitle"),
          description: t("listings.addFinanceText"),
        });
      }
    }

    if (!data?.product?.website && data?.product?.files_list?.length === 0) {
      sugList.push({
        title: t("listings.addPresentationTitle"),
        description: t("listings.addPresentationText"),
      });
    }

    if (sugList.length === 0) {
      return (
        <span className="block mt-2 text-base">
          <CheckOutlined className="text-green-500 mr-2" />
          {t("listings.allOk")}
        </span>
      );
    }

    return (
      <ul className="list-disc list-inside m-0 p-0">
        {sugList.map((s, i) => (
          <li key={i} className="my-1 ml-2">
            <span className="text-sm text-black">
              {s.title}{" "}
              <Tooltip title={s.description}>
                <InfoCircleFilled className="ml-1 text-xs text-orange-400" />
              </Tooltip>
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="border border-solid border-neutral-200 h-full rounded-md p-4">
      <div className="mb-3 w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.activity")}
        </span>
        <div className="grid grid-cols-4 gap-2 my-3">
          <div className="col-span-2 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsViews")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data.product.clicks_metric <= 0 ? (
                    <DashOutlined />
                  ) : (
                    data.product.clicks_metric
                  )}
                </span>
                <EyeOutlined
                  className="mx-auto block text-neutral-400"
                  style={{ fontSize: 17 }}
                />
              </div>
            </Tooltip>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsShows")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data.product.shows_metric <= 0 ? (
                    <DashOutlined />
                  ) : (
                    data.product.shows_metric
                  )}
                </span>
                <PicCenterOutlined
                  className="mx-auto block text-neutral-400"
                  style={{ fontSize: 17 }}
                />
              </div>
            </Tooltip>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsRequests")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data.product.contacts_metric <= 0 ? (
                    <DashOutlined />
                  ) : (
                    data.product.contacts_metric
                  )}
                </span>
                <PhoneOutlined
                  className="mx-auto block text-neutral-400"
                  style={{ fontSize: 17 }}
                />
              </div>
            </Tooltip>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsSaves")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data.product.favorites_metric <= 0 ? (
                    <DashOutlined />
                  ) : (
                    data.product.favorites_metric
                  )}
                </span>
                <HeartOutlined
                  className="mx-auto block text-neutral-400"
                  style={{ fontSize: 17 }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.recommendationTitle")}
        </span>
        {suggestions()}
        <Divider hidden={isApp} />
        {!isApp && <Promotions data={data} />}
      </div>
    </div>
  );
}
