import { Fragment } from "react";
import Phone from "./phone";
import Social from "./social";

export default function Contacts(props) {
  return (
    <Fragment {...props}>
      <Phone {...props} />
      <Social {...props} />
    </Fragment>
  );
}
