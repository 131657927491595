import { Companies } from "@modules/partner";
import { useLoaderData } from "react-router-dom";

export default function EditCompany({ isApp = false }) {
  const data = useLoaderData();

  return (
    <div className="my-5">
      <h2 className="mb-5 lg:mb-10">Редактирование компании</h2>
      <Companies.Form.Edit data={data} isApp={isApp} />
    </div>
  );
}
