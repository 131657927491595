import { Select } from "antd";

export const services = [
  {
    value: "102",
    label: "Вывод на главную",
    icon: "/img/promotions/icon-hot.svg",
  },
  {
    value: "103",
    label: "Интересное",
    icon: "/img/promotions/icon-interesting.svg",
  },
  {
    value: "104",
    label: "Выделить цветом",
    icon: "/img/promotions/icon-highlight.svg",
  },
  {
    value: "105",
    label: "Поднятие в поиске",
    icon: "/img/promotions/icon-raise.svg",
  },
  {
    value: "106",
    label: "Автопродление",
    icon: "/img/promotions/icon-success.svg",
  },
  {
    value: "107",
    label: "Премиум",
    icon: "/img/promotions/icon-success.svg",
  },
  {
    value: "108",
    label: "Вывод в Kapital.kz",
    icon: "/img/promotions/kapital.svg",
  },
];

export default function Services(params) {
  return (
    <Select
      className="w-full"
      placeholder="Тариф"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("service_code", v)}
      options={services}
    />
  );
}
