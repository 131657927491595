import { Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { useAuth } from "@contexts";

export default function Banner() {
  const { loading, logout } = useAuth();

  const onPartnerRegister = () => {
    logout().then(() => {
      window.location.href = "/register?role=partner";
    });
  };

  return (
    <div className="grid grid-cols-12 gap-4 my-10">
      <div className="col-span-12 md:col-span-6 flex flex-col justify-center">
        <h1 className="block w-full font-semibold text-2xl text-neutral-900 my-0">
          Станьте партнером на Sdelka.kz
        </h1>
        <span className="block w-full font-normal text-base text-neutral-500 my-2 mb-7">
          Зарегистрируйтесь на Sdelka.kz как «Партнер» и получите возможность
          размещать профиль Вашей компании.
        </span>
        <span className="block text-base font-semibold text-neutral-900">
          Партнеры Sdelka.kz могут:
        </span>
        <ul className="list-none list-inside p-0 my-3">
          <li className="p-0 m-0 my-1 flex justify-start items-center">
            <img
              src="/img/icon-check.svg"
              className="block"
              width={20}
              height={20}
              alt="Сделка"
            />
            <span className="block text-base font-normal text-neutral-900 ml-2">
              Размещать профиль компании
            </span>
          </li>
          <li className="p-0 m-0 my-1 flex justify-start items-center">
            <img
              src="/img/icon-check.svg"
              className="block"
              width={20}
              height={20}
              alt="Сделка"
            />
            <span className="block text-base font-normal text-neutral-900 ml-2">
              Размещать описание и стоимость услуг
            </span>
          </li>
          <li className="p-0 m-0 my-1 flex justify-start items-center">
            <img
              src="/img/icon-check.svg"
              className="block"
              width={20}
              height={20}
              alt="Сделка"
            />
            <span className="block text-base font-normal text-neutral-900 ml-2">
              Получать заявки от других партнеров и компаний на сайте
            </span>
          </li>
        </ul>
        <Button
          loading={loading}
          size="large"
          icon={<LoginOutlined />}
          className="mt-5"
          type="primary"
          onClick={onPartnerRegister}
        >
          Стать партнером
        </Button>

        <span className="text-xs leading-tight font-normal text-neutral-500 block w-full text-center mt-4">
          Ваша учетная запись не предназначена для работы с «Кабинетом
          Партнера». В следующем шаге вам будет предложено создать новую учетную
          запись.
        </span>
      </div>
      <div className="col-span-12 md:col-span-6 hidden md:flex justify-center items-center">
        <img src="/img/partner-banner.png" alt="partner-banner.png" className="block w-full max-w-xs" />
      </div>
    </div>
  );
}
