import { Button } from "antd";
import { Link, useLoaderData } from "react-router-dom";
import { Listings } from "@modules/customer";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Dashboard({ isApp = false }) {
  const listings = useLoaderData();
  const { t } = useTranslation();

  return (
    <div className="my-5">
      {!isApp && (
        <div className="flex flex-wrap justify-between items-center gap-4">
          <h2 className="m-0">{t("listings.my")}</h2>
          <Button icon={<PlusOutlined />} type="primary">
            <Link className="hover:text-white ml-2" to="/listings/new">
              {t("listings.new")}
            </Link>
          </Button>
        </div>
      )}
      <Listings.List listings={listings} isApp={isApp} />
    </div>
  );
}
