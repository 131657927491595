import { Notifications } from "@modules/customer";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotificationsPage() {
  const { t } = useTranslation();
  const initialData = useLoaderData();
  return (
    <div className="my-5">
      <h2 className="mb-5">{t("nav.notifications")}</h2>
      <Notifications initialData={initialData} />
    </div>
  );
}
