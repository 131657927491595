import { Card } from "antd";
import { Users } from "@modules/moderator";
import Fade from "react-reveal/Fade";

export default function UsersPage() {
  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Пользователи</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card bordered={false} className="h-full">
              <Users.Table />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
