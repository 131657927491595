import { useEffect, useState } from "react";
import { message, Table, Button, Modal } from "antd";
import { tariffLength } from "@modules/customer/tariffs/packages/reference";
import * as API from "@api/partner";
import { EyeOutlined } from "@ant-design/icons";
import classNames from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/ru";
dayjs.locale("ru");

export default function Payments({ className, initialData }) {
  const [loading, setLoading] = useState(!initialData);
  const [data, setData] = useState(initialData?.data || []);
  const [pagination, setPagination] = useState(
    initialData?.pagination || {
      current: 1,
      pageSize: 10,
      total: 1,
      position: ["bottomright"],
      showSizeChanger: true
    }
  );

  useEffect(() => {
    if (!initialData) {
      getTableData();
    }
    /* eslint-disable-next-line */
  }, []);

  // Get all payments
  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Payments.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Невозможно загрузить данные. Проверьте соединение с интернетом или свяжитесь с нами.", 2);
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setLoading(false);
  }

  async function onPaymentOpen(payment) {
    setLoading(true);
    const _res = await API.Payments.getOne({ id: payment.id });

    if (!_res.data) {
      return message.error("Невозможно загрузить данные. Проверьте соединение с интернетом или свяжитесь с нами.", 2);
    }

    setLoading(false);
    Modal.info({
      width: 600,
      okText: "Готово",
      icon: null,
      title: `Платеж №${_res.data.transaction_id}`,
      maskClosable: true,
      content: (
        <div className="grid grid-cols-2 gap-6 my-5">
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Номер транзакции</span>
            <span className="text-base text-black">№{_res.data.transaction_id}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Дата транзакции</span>
            <span className="text-base text-black">{dayjs(_res.data.created_at).format("HH:mm DD.MM.YYYY")}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Сумма транзакции</span>
            <span className="text-base text-black">
              {new Intl.NumberFormat("ru-KZ", {
                style: "currency",
                currency: "kzt",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
              }).format(_res.data.amount)}
            </span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Описание платежа</span>
            <span className="text-base text-black">Услуга: {_res.data.pg_description}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Пакеты услуг</span>
            <span className="text-base text-black">{_res?.data?.package?.name || "Нет"}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Объявление</span>
            <span className="text-base text-black">{_res?.data?.product?.title || "Нет"}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Чек</span>
            <span className="text-base text-black">Отправлен на адрес: {_res?.data?.pg_user_contact_email || "Уточните у менеджера"}</span>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">Статус</span>
            {_res.data.is_charged ? <span className="text-base text-green-600">Оплачено</span> : <span className="text-base text-rose-600">Не оплачено</span>}
          </div>
        </div>
      )
    });
  }

  return (
    <div className={classNames("block w-full", className)}>
      <Table
        showHeader={false}
        onChange={getTableData}
        pagination={pagination}
        dataSource={data}
        loading={loading}
        className="rounded-sm overflow-hidden"
        rowKey="id"
        columns={[
          {
            dataIndex: "pg_description",
            key: "pg_description",
            align: "left",
            width: 250,
            render: (data, all) => (
              <div className="cursor-pointer" onClick={() => onPaymentOpen(all)}>
                <span className="block text-base font-semibold text-black leading-tight">{data}</span>
                <span className="block text-sm font-normal text-green-600 leading-snug my-1">
                  {all?.tariff?.name} ({all?.tariff?.quantity && tariffLength(all?.tariff)})
                </span>
                <span className="block text-xs text-slate-500 mb-2">Транзакция №{all.transaction_id}</span>
              </div>
            )
          },
          {
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            responsive: ["lg"],
            render: data => <span className="block m-0 font-normal text-slate-500 text-sm">{dayjs(data).format("HH:mm DD.MM.YYYY")}</span>
          },
          {
            dataIndex: "amount",
            key: "amount",
            align: "right",
            render: (data, all) => (
              <div>
                <span className="block text-base leading-tight font-semibold text-black">
                  {new Intl.NumberFormat("ru-KZ", {
                    style: "currency",
                    currency: "kzt",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                  }).format(data)}
                </span>
                {all?.package_id && <span className="block text-sm leading-tight font-normal text-slate-500 my-1">Входит в пакет</span>}
                {all.is_charged ? (
                  <span className="m-0 block text-sm text-green-600">Оплачено</span>
                ) : (
                  <span className="m-0 block text-sm text-rose-600">Не оплачено</span>
                )}
              </div>
            )
          },
          {
            dataIndex: "actions",
            key: "actions",
            align: "right",
            responsive: ["lg"],
            render: (_, all) => (
              <Button onClick={() => onPaymentOpen(all)} icon={<EyeOutlined />}>
                Открыть
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
