import { Card, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Imgproxy, Contacts } from "@utils";

export default function ContactsWidget({ data, className }) {
  return (
    <Card bordered={false} title="Контакты объявления" className={className}>
      <div className="flex justify-start items-center">
        <Avatar
          size="large"
          icon={<UserOutlined />}
          src={Imgproxy.getURL(data?.user?.avatar, { width: 100, height: 100 })}
        />
        <div className="ml-2">
          <span className="block text-base font-medium text-black leading-tight">
            {data?.user?.display_name}
          </span>
          <span className="block text-sm font-normal text-neutral-500 leading-tight">
            <Link to={`/users/view/${data?.user?.id}`}>Смотреть профиль</Link>
          </span>
        </div>
      </div>
      <span className="block text-sm text-neutral-600 mt-5">
        Контакты объявления:
      </span>
      <div className="flex flex-col items-start gap-2 my-2">
        {Contacts.getContactItems(data?.contacts)}
      </div>
    </Card>
  );
}
