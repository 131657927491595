import { useState } from "react";
import { message, Select, Spin } from "antd";
import debounce from "debounce";
import * as API from "@api/moderator";

export default function Products(params) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  let fetchList = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Products.getAll({
      limit: 10,
      filter: { title: value },
    });

    if (!data || data?.error) {
      return message.error(
        "Невозможно произвести поиск, проверьте интернет-соединение",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((product) => ({
        value: product.id,
        label: `${product.title} (ID ${product.id})`,
      }))
    );

    setFetching(false);
  };

  fetchList = debounce(fetchList, 200);

  return (
    <Select
      onChange={(v) => params.onChange("product_id", v)}
      showSearch
      allowClear
      className="w-full"
      placeholder="Поиск по объявлению"
      filterOption={false}
      onSearch={fetchList}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  );
}
