import { Breadcrumb } from "@components";
import { Dictionary } from "@modules/moderator";
import { useLoaderData } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const DictMap = {
  categories: Dictionary.Categories.Edit,
  countries: Dictionary.Countries.Edit,
  cities: Dictionary.Cities.Edit,
  orgtypes: Dictionary.Orgtypes.Edit,
  options: Dictionary.Options.Edit,
}

export default function NewDict() {
  const initialData = useLoaderData();
  const Component = DictMap[initialData?.type];

  return (
    <div>
      <Breadcrumb
        showBack
        data={[
          { title: initialData?.title, href: `/dict/${initialData?.type}` },
          { title: "Редактировать", href: `/dict/${initialData?.type}/edit/${initialData?.id}` },
        ]}
      />
      <h2 className="block text-xl text-black mb-5">Редактирование объекта: {initialData?.title}</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-6">
            <Component initialData={initialData} />
          </div>
        </div>
      </Fade>
    </div>
  );
}
