import { useLoaderData } from "react-router-dom";
import { Payments } from "@modules/customer";
import { useTranslation } from "react-i18next";

export default function PaymentsPage() {
  const initialData = useLoaderData();
  const { t } = useTranslation();

  return (
    <div className="my-5">
      <h2 className="mb-5">{t("nav.payments")}</h2>
      <Payments initialData={initialData} />
    </div>
  );
}
