import { useState, useEffect } from "react";
import { Card, Form, message, Button, Divider, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import * as API from "@api/moderator";

export default function NewCity() {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    setLoading(true);
    const _res = await API.Countries.getAll({ limit: 100 });

    if (!_res || _res.error) {
      return message.error(
        "Ошибка. Попробуйте снова или обратитесь к администратору.",
        2
      );
    }

    setOptions(_res.data.rows);
    setLoading(false);
  };

  const onFormSubmit = async (data) => {
    setLoading(true);
    const _res = await API.Cities.create(data);
    setLoading(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка при создании. Пожалуйста, попробуйте снова или обратитесь к администратору.",
        2
      );
    }

    return message.success("Запись успешно создана.", 1, () =>
      navigate("/dict/cities")
    );
  };

  return (
    <Card bordered={false}>
      <Form
        className="global-form"
        layout="vertical"
        scrollToFirstError
        onFinish={onFormSubmit}
      >
        <Form.Item
          label="Название"
          help="Введите название города"
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название.",
            },
          ]}
        >
          <Input placeholder="Введите название" />
        </Form.Item>
        <Form.Item
          label="Страна"
          name="country_id"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите страну.",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Выберите страну"
            options={options}
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>
        <Divider />
        <Form.Item name="submit">
          <Button loading={loading} type="primary" htmlType="submit">
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
