import { useEffect, useState } from "react";
import { message, Table, Button, Modal } from "antd";
import { tariffLength } from "@modules/customer/tariffs/packages/reference";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocale } from "@contexts/locale";
import * as API from "@api/customer";
import classNames from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
dayjs.locale("ru");

export default function Payments({ className, initialData }) {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [loading, setLoading] = useState(!initialData);
  const [data, setData] = useState(initialData?.data || []);
  const [pagination, setPagination] = useState(
    initialData?.pagination || {
      current: 1,
      pageSize: 10,
      total: 1,
      position: ["bottomright"],
      showSizeChanger: true
    }
  );

  if (locale === "kk") {
    dayjs.locale("kk");
  }

  useEffect(() => {
    if (!initialData) {
      getTableData();
    }
    /* eslint-disable-next-line */
  }, []);

  // Get all payments
  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Payments.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error(t("generic.serverError"), 2);
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setLoading(false);
  }

  async function onPaymentOpen(payment) {
    setLoading(true);
    const _res = await API.Payments.getOne({ id: payment.id });

    if (!_res.data) {
      return message.error(t("generic.serverError"), 2);
    }

    setLoading(false);
    Modal.info({
      width: 600,
      okText: t("generic.done"),
      icon: null,
      title: `${t("payments.payment")} №${_res.data.transaction_id}`,
      maskClosable: true,
      content: (
        <div className="grid grid-cols-2 gap-6 my-5">
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.number")}</span>
            <span className="text-base text-black">№{_res.data.transaction_id}</span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.date")}</span>
            <span className="text-base text-black">{dayjs(_res.data.created_at).format("HH:mm DD.MM.YYYY")}</span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.sum")}</span>
            <span className="text-base text-black">
              {new Intl.NumberFormat("ru-KZ", {
                style: "currency",
                currency: "kzt",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
              }).format(_res.data.amount)}
            </span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.description")}</span>
            <span className="text-base text-black">
              {t("payments.service")}: {_res.data.pg_description}
            </span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.packages")}</span>
            <span className="text-base text-black">{_res?.data?.package?.name || "Нет"}</span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.packages")}</span>
            <span className="text-base text-black">{_res?.data?.product?.title || "Нет"}</span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.receipt")}</span>
            <span className="text-base text-black">
              {t("payments.sentTo")}: {_res?.data?.pg_user_contact_email || "Уточните у менеджера"}
            </span>
          </div>
          <div className="col-span-2 md:col-span-1">
            <span className="block text-sm font-semibold text-slate-500 mb-1">{t("payments.status")}</span>
            {_res.data.is_charged ? (
              <span className="text-base text-green-600">{t("payments.paid")}</span>
            ) : (
              <span className="text-base text-rose-600">{t("payments.unpaid")}</span>
            )}
          </div>
        </div>
      )
    });
  }

  return (
    <div className={classNames("block w-full", className)}>
      <Table
        showHeader={false}
        onChange={getTableData}
        pagination={pagination}
        dataSource={data}
        loading={loading}
        className="rounded-sm overflow-hidden"
        rowKey="id"
        columns={[
          {
            dataIndex: "pg_description",
            key: "pg_description",
            align: "left",
            width: 250,
            render: (data, all) => (
              <div className="cursor-pointer" onClick={() => onPaymentOpen(all)}>
                <span className="block text-base font-semibold text-black leading-tight">{data}</span>
                <span className="block text-sm font-normal text-green-600 leading-snug my-1">
                  {all?.tariff?.name} ({all?.tariff?.quantity && tariffLength(all?.tariff)})
                </span>
                <span className="block text-xs text-slate-500 mb-2">
                  {t("payments.transaction")} №{all.transaction_id}
                </span>
              </div>
            )
          },
          {
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            responsive: ["lg"],
            render: data => <span className="block m-0 font-normal text-slate-500 text-sm">{dayjs(data).format("HH:mm DD.MM.YYYY")}</span>
          },
          {
            dataIndex: "amount",
            key: "amount",
            align: "right",
            render: (data, all) => (
              <div>
                <span className="block text-base leading-tight font-semibold text-black">
                  {new Intl.NumberFormat("ru-KZ", {
                    style: "currency",
                    currency: "kzt",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                  }).format(data)}
                </span>
                {all?.package_id && <span className="block text-sm leading-tight font-normal text-slate-500 my-1">{t("payments.inPackage")}</span>}
                {all.is_charged ? (
                  <span className="m-0 block text-sm text-green-600">{t("payments.paid")}</span>
                ) : (
                  <span className="m-0 block text-sm text-rose-600">{t("payments.unpaid")}</span>
                )}
              </div>
            )
          },
          {
            dataIndex: "actions",
            key: "actions",
            align: "right",
            responsive: ["lg"],
            render: (_, all) => (
              <Button onClick={() => onPaymentOpen(all)} icon={<EyeOutlined />}>
                {t("nav.open")}
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
