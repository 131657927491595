export const ARTICLE_STATUSES = {
  draft: {
    label: "Черновик",
    badgeStatus: "default"
  },
  published: {
    label: "Опубликован",
    badgeStatus: "success"
  },
  in_review: {
    label: "На рассмотрении",
    badgeStatus: "processing"
  },
  planned: {
    label: "Запланирован",
    badgeStatus: "warning"
  },
  unpublished: {
    label: "Снят с сайта",
    badgeStatus: "error"
  },
  removed: {
    label: "Удален",
    badgeStatus: "error"
  }
};

export const META_OBJECT_TYPES = {
  article: {
    label: "Материал",
    color: "default"
  },
  person: {
    label: "Личность",
    color: "cyan"
  },
  author: {
    label: "Автор",
    color: "green"
  },
  category: {
    label: "Рубрика",
    color: "orange"
  },
  tag: {
    label: "Тег",
    color: "blue"
  },
  page: {
    label: "Страница",
    color: "default"
  }
};
