import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@contexts";
import { useLocale } from "@contexts/locale";
import { Login } from "@modules";
import { LocaleSwitcher } from "@components";
import { startsWith } from "ramda";
import config from "@config";
import { useTranslation } from "react-i18next";

export default function LoginPage(props) {
  const { currentUser, isAwaitingVerification } = useAuth();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { locale } = useLocale();
  
  const redirectURL = params.get("redirect_url") || null;

  // If awaiting verification
  if (isAwaitingVerification) {
    return <Navigate to={"/confirm"} />;
  }

  // If user is authenticated
  if (currentUser) {
    // Check for redirect URL
    if (redirectURL && !startsWith("/", redirectURL)) {
      return window.location.replace(redirectURL);
    }

    return <Navigate to={redirectURL || "/"} />;
  }

  return (
    <div className="login-page">
      <div className="header mb-5">
        <div className="container content-max flex justify-between items-center">
          <a
            href={config.frontend}
            alt="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
            className="logo-top"
          >
            <img
              src="/img/logo.svg"
              alt="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
              title="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
              width={242}
              height={25}
            />
          </a>
          <LocaleSwitcher className="m-0" />
        </div>
      </div>
      <div className="container content-max after-header">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6 lg:col-span-5">
            <Login.Form />
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-7 flex flex-col justify-center">
            {locale === 'kk' ? <img
              src="/img/qz.png"
              className="block w-full max-w-sm mx-auto"
              alt="Войти в личный кабинет"
            /> : <img
              src="/img/ru.png"
              className="block w-full max-w-sm mx-auto"
              alt="Войти в личный кабинет"
            />}
            <span className="block w-full max-w-sm mx-auto mt-5 text-center leading-normal">
              {t("login.needHelp_begin")}
              <a
                className="no-underline text-primary"
                href="mailto:info@sdelka.kz"
              >
                info@sdelka.kz
              </a>
              {t("login.needHelp_middle")}
              <a className="no-underline text-primary" href="tel:+77773538888">
                +7 777 353-88-88
              </a>
              {t("login.needHelp_end")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
