const dictmap = {
  countries: "Страны",
  cities: "Города",
  options: "Опции объявления",
  orgtypes: "Виды организаций",
  categories: "Категории",
}

export async function getData({params}) {
  return {
    type: params.type,
    title: dictmap[params.type],
    id: params?.id,
  }
}