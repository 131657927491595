import { Button, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Imgproxy } from "@utils";
import { useAuth } from "@contexts";
import * as API from "@api";

export default function Logo({ form }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(form.getFieldValue("logo") || null);

  useEffect(() => {
    form.setFieldValue("logo", logo);
    /* eslint-disable-next-line */
  }, [logo]);

  return (
    <div className="flex flex-col justify-center items-center">
      <ImgCrop
        beforeCrop={file => {
          if (file.size > 3 * 1024 * 1024) {
            message.error("Размер изображения не должен превышать 3 МБ.", 2);
            return false;
          }

          return true;
        }}
        grid
        rotate
        modalTitle="Расположите фото"
      >
        <Upload
          disabled={loading}
          multiple={false}
          name="img"
          accept="image/jpeg,image/png"
          showUploadList={false}
          customRequest={async ({ onSuccess, onError, file }) => {
            try {
              setLoading(true);

              const _res = await API.Static.uploadFile({
                file,
                id: currentUser.id
              });

              if (!_res.data || _res.error) {
                throw new Error();
              }

              setLogo(_res?.data?.path);
              setLoading(false);
              onSuccess();
            } catch (error) {
              onError();
              setLoading(false);
              return message.error("Ошибка загрузки изображения. Попробуйте позже", 2);
            }
          }}
        >
          <div className="flex flex-col justify-center items-center">
            <img
              alt="Логотип"
              src={logo ? Imgproxy.getURL(logo, { width: 180, height: 180 }) : "/img/logo-placeholder.svg"}
              className="aspect-square w-32 h-32 object-contain object-center bg-neutral-100 border border-neutral-200 border-solid rounded-md overflow-hidden cursor-pointer"
            />
            <Button loading={loading} icon={<UploadOutlined />} type="link" className="m-0 w-fit p-0 h-fit mx-auto mt-2">
              {logo ? "Заменить (JPG или PNG)" : "Загрузить (JPG или PNG)"}
            </Button>
          </div>
        </Upload>
      </ImgCrop>
    </div>
  );
}
