import { Listings } from "@modules/customer";
import { useLoaderData } from "react-router-dom";

export default function EditListing({ isApp = false }) {
  const data = useLoaderData();

  return (
    <div className="my-5">
      <h2 className="mb-5 lg:mb-10">Редактирование объявления</h2>
      <Listings.Form.Edit data={data} isApp={isApp} />
    </div>
  );
}
