import { Button, message } from "antd";
import { useState, useEffect } from "react";
import { WhatsAppOutlined, PhoneOutlined } from "@ant-design/icons";
// import Verify from "./verify";
import VerifyWithFirebase from "./verify/firebase";
import PhoneInput from "react-phone-input-2";
import { startsWith } from "ramda";
import { useTranslation } from "react-i18next";
import locale from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/style.css";
import { verifyTypes } from "./typesVerify";

export default function ContactsModal({ onSelect, onClose, onPageChange }) {
  const { t } = useTranslation();
  const [isVerifying, setIsVerifying] = useState(false);
  const [phone, setPhone] = useState(null);
  const [withWhatsapp, setWithWhatsapp] = useState(false);

  useEffect(() => {
    setIsVerifying(false);
    setPhone(null);
  }, []);

  // Checks if the phone number is Altel/Tele2
  const isAltel = phone => {
    const altelPrefixes = ["7700", "7708", "7707", "7747"];
    const phonePrefix = phone.slice(0, 4);
    return altelPrefixes.includes(phonePrefix);
  };

  if (isVerifying && phone) {
    // this needs to be removed
    // For Kazakhstan numbers
    // if (startsWith("77", phone) && !isAltel(phone)) {
    //   return (
    //     <Verify
    //       onSelect={(contact) => {
    //         onSelect(contact);
    //         onPageChange("list");
    //       }}
    //       phone={phone}
    //       onCancel={() => setIsVerifying(false)}
    //     />
    //   );
    // }

    // For all other numbers
    return (
      <VerifyWithFirebase
        defaultVerifyType={
          withWhatsapp
            ? verifyTypes.whatsapp
            : startsWith("77", phone) // && !isAltel(phone)
            ? verifyTypes.smsc
            : verifyTypes.firebase
        }
        onSelect={contact => {
          onSelect(contact);
          onPageChange("list");
        }}
        phone={phone}
        onCancel={() => setIsVerifying(false)}
      />
    );
  }

  const onVerifyRequest = (withWhatsapp = false) => {
    if (!phone || phone.replace(/\D/g, "").length < 2) {
      return message.error(t("contacts.phoneIncorrect"), 2);
    }

    setWithWhatsapp(withWhatsapp);
    setIsVerifying(true);
  };

  return (
    <div>
      <span className="text-base block font-medium text-black mb-5">{t("contacts.newNumber")}</span>
      <span className="text-sm block w-full text-black mb-2">{t("contacts.enterNumber")}:</span>
      <PhoneInput
        className="w-full"
        inputClass="!w-full"
        localization={locale}
        country="kz"
        value={phone}
        onChange={setPhone}
        preferredCountries={["kz", "ru", "ua", "az", "am", "kg", "tj", "tm", "uz"]}
        priority={{ kz: 0, ru: 1 }}
        placeholder={t("contacts.enterNumberPlaceholder")}
      />

      <span className="w-full block rounded-md bg-neutral-100 p-3 text-xs text-neutral-600 mt-10 mb-3">{t("contacts.enterNumberPrompt")}</span>

      <Button onClick={() => onVerifyRequest(true)} className="mb-2 py-2 h-auto" type="primary" block icon={<WhatsAppOutlined />}>
        {t("contacts.veirfyWhatsapp")}
      </Button>
      <Button onClick={() => onVerifyRequest(false)} className="py-2 h-auto" type="text" block icon={<PhoneOutlined style={{ transform: "scaleX(-1)" }} />}>
        {t("contacts.verifySMS")}
      </Button>
      <Button onClick={onClose} className="mt-3" danger type="link" block>
        {t("generic.cancel")}
      </Button>
    </div>
  );
}
