import { Button } from "antd";
import {
  PlusOutlined,
  WarningOutlined,
  CheckSquareFilled,
} from "@ant-design/icons";
import classNames from "classnames";
import Modal from "./modal";
import { isEmpty, isNil, propEq, findIndex, remove } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Contacts({
  className,
  data = [],
  onChange,
  disabled = false,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(data);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setSelected(data);
  }, [data]);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
    /* eslint-disable-next-line */
  }, [selected]);

  const onModalRequest = () => {
    setShowModal(!showModal);
  };

  const onSelect = ({ id, phone }) => {
    const idx = findIndex(propEq("id", id), selected);

    // If found in list
    if (idx >= 0) {
      return setSelected(remove(idx, 1, selected));
    }

    setSelected([...selected, { id, phone }]);
  };

  if (isEmpty(selected) || isNil(selected)) {
    return (
      <div
        className={classNames("w-full p-5 bg-neutral-50 rounded-md", className)}
      >
        <div className="flex justify-start w-full items-start mt-1 mb-2">
          <img
            src="/img/icon-phone-add.svg"
            width={35}
            height={35}
            className="block mr-3"
            alt="Добавьте номер телефона"
          />
          <div>
            <span
              style={{ fontSize: 15 }}
              className="font-semibold text-black block leading-tight"
            >
              <WarningOutlined className="text-orange-500 mr-1" />{" "}
              {t("contacts.notConnected")}
            </span>
            <span className="text-sm text-neutral-500 block leading-tight mt-1">
              {t("contacts.addPhoneText")}
            </span>
          </div>
        </div>
        <Button
          onClick={onModalRequest}
          block
          className="mt-5"
          type="primary"
          icon={<PlusOutlined />}
        >
          {t("contacts.addPhone")}
        </Button>
        <Modal
          visible={showModal}
          onClose={onModalRequest}
          selected={selected}
          onSelect={onSelect}
        />
      </div>
    );
  }

  return (
    <div
      className={classNames("w-full p-5 bg-neutral-50 rounded-md", className)}
    >
      {selected.map((phone, i) => (
        <span
          onClick={!disabled && onModalRequest}
          className="block my-2 w-full p-2 lg:p-3 lg:px-4 bg-neutral-100 rounded-md select-none cursor-pointer font-normal text-base"
          key={i}
        >
          <CheckSquareFilled
            disabled={disabled}
            className="mr-2 text-green-600"
          />
          {phone.phone}
        </span>
      ))}
      <Button
        onClick={onModalRequest}
        block
        className="mt-5"
        type="primary"
        icon={<PlusOutlined />}
        disabled={disabled}
      >
        {t("contacts.addPhone")}
      </Button>
      <Modal
        visible={showModal}
        onClose={onModalRequest}
        selected={selected}
        onSelect={onSelect}
      />
    </div>
  );
}
