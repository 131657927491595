import { message } from "antd";
import * as API from "@api/partner";

export async function getCompany({ params }) {
  const { id } = params;
  return await API.Companies.getOne({ id })
    .then((res) => res?.data)
    .catch((err) => {
      message.error(
        "Ошибка загрузки компании, проверьте соединение с интернетом и попробуйте еще раз",
        3
      );
      return null;
    });
}
