import * as API from "@api/moderator";

export async function getListing({ params }) {
  const { type, id } = params;
  const api =
    type === "franchise"
      ? API.Franchises
      : type === "investment"
      ? API.Investments
      : API.Sales;
  return await api.getOne({ id }).then((res) => ({ ...res.data, type, id }));
}
