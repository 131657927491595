import { Loading } from "@components";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { isEmpty } from "ramda";
import classNames from "classnames";
import { useAuth } from "@contexts";
import * as API from "@api/customer";

export default function Publish({
  productId = null,
  form,
  visible = false,
  className,
  isApp = false,
}) {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isApp && visible) {
      return navigate(`/app/listings`);
    }

    if (currentUser?.role === "moderator" && visible) {
      return navigate(`/listings`);
    }

    if (visible) {
      if (!productId || !form.getFieldValue("type")) {
        return setLoading(false);
      }

      getCurrentPromotions();
    }

    async function getCurrentPromotions() {
      const _res = await API.Payments.getAll({
        filter: {
          product_id: productId,
          is_charged: 1,
        },
      });

      if (!_res) {
        return setLoading(false);
      }

      if (isEmpty(_res?.data?.rows)) {
        return navigate(`/promote/${form.getFieldValue("type")}/${productId}`);
      }

      setLoading(false);
    }
    /* eslint-disable-next-line */
  }, [visible]);

  if (loading && visible) {
    return (
      <Fade spy={visible} duration={300} distance="5px" bottom>
        <Loading />
      </Fade>
    );
  }

  return (
    <Fade spy={visible} duration={300} distance="5px" bottom>
      <div
        className={classNames("grid grid-cols-12 gap-0", className, {
          hidden: !visible,
        })}
      >
        <div className="col-span-12 lg:col-span-7 flex justify-center items-center">
          <img
            className="block w-full"
            src="/img/img-publish.png"
            alt="Публикация объявления"
          />
        </div>
        <div className="col-span-12 lg:col-span-5 flex flex-col justify-center items-start">
          <h4 className="text-2xl font-semibold text-black">Почти готово</h4>
          <p className="font-medium text-neutral-500 text-base">
            Ваше объявление отправлено на модерацию и опубликуется
            автоматически. Это не займет много времени.
          </p>
          <a href="/">Вернуться на главную</a>
        </div>
      </div>
    </Fade>
  );
}
