import { Select } from "antd";
import { Reports } from "@utils";

export default function ReportProductReasons(params) {
  // go through Reports.REASON_TITLES object, create array in format [{value: key, label: value}]
  const reasons = Object.keys(Reports.REASON_TITLES).map((key) => ({
    value: key,
    label: Reports.REASON_TITLES[key],
  }));

  return (
    <Select
      className="w-full"
      mode="multiple"
      placeholder="Причина"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("product_reason", v)}
      options={reasons}
    />
  );
}
