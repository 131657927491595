import React, { createContext, useContext, useEffect, useState } from "react";
import * as API from "@api";
import { auth } from "@utils/firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithPhoneNumber,
  updateEmail,
  updatePassword,
  signOut,
  reauthenticateWithPopup,
  OAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";
import config from "@config";
import * as Sentry from "@sentry/react";
import { pathOr, includes } from "ramda";
import { message } from "antd";

const AuthContext = createContext({
  currentUser: null,
  setCurrentUser: null,
  signInWithGoogle: () => Promise,
  signInWithApple: () => Promise,
  signInWithPhone: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
  updateFBEmail: () => Promise,
  changePassword: () => Promise,
  sendVerification: () => Promise,
  loading: false,
  isAwaitingVerification: false,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const searchParams = new URLSearchParams(window.location.search);

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAwaitingVerification, setIsAwaitingVerification] = useState(false);

  useEffect(() => {
    async function checkAuth() {
      setLoading(true);
      const user = await API.Auth.getAuth(); // GET /auth/

      if (user?.data && !user?.error) {
        setCurrentUser({
          email: pathOr(null, ["data", "email"], user),
          name: pathOr(null, ["data", "display_name"], user),
          id: pathOr(null, ["data", "id"], user),
          role: pathOr(null, ["data", "role"], user),
          provider: pathOr(null, ["data", "signup_method"], user),
          notifications: pathOr(false, ["data", "notifications"], user),
          avatar: pathOr(null, ["data", "avatar"], user),
          firebaseToken: pathOr(null, ["data", "firebase_token"], user),
        });

        // If user is not logged in to firebase, but has working jwt + firebase token
        if (
          pathOr(null, ["data", "firebase_token"], user) &&
          !auth.currentUser
        ) {
          await signInWithCustomToken(
            auth,
            pathOr(null, ["data", "firebase_token"], user)
          );
        }
      }

      setLoading(false);

      const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
        // Check if API auth already returned authorized user
        if (user?.status === 200 && currentUser) {
          return;
        }

        setLoading(true);

        if (!firebaseUser) {
          setCurrentUser(null);
          setLoading(false);
          return;
        }

        if (!firebaseUser.emailVerified) {
          return setIsAwaitingVerification(true);
        }

        setIsAwaitingVerification(false);

        // If user is logged in to firebase, but not with backend
        if (firebaseUser && firebaseUser.emailVerified && !currentUser) {
          await Promise.all([firebaseUser.getIdToken()])
            .then((token) => {
              const role =
                firebaseUser?.photoURL === "partner" ? "partner" : "customer";
              let withToken = null;

              if (
                searchParams.get("authtoken") === "yes" ||
                localStorage.getItem("token")
              ) {
                withToken = { authtoken: "yes" };
              }

              return API.Auth.signin({ token: token[0], role }, withToken);
            })
            .then((res) => {
              if (!res || res.error) {
                throw new Error("Ошибка авторизации, попробуйте еще раз");
              }

              setCurrentUser({
                email: pathOr(null, ["data", "email"], res),
                name: pathOr(null, ["data", "display_name"], res),
                id: pathOr(null, ["data", "id"], res),
                role: pathOr(null, ["data", "role"], res),
                provider: pathOr(null, ["data", "signup_method"], res),
                notifications: pathOr(false, ["data", "notifications"], res),
                avatar: pathOr(null, ["data", "avatar"], res),
                firebaseToken: pathOr(null, ["data", "firebase_token"], res),
              });

              if (res?.data?.token) {
                localStorage.setItem("token", JSON.stringify(res.data.token));
              }

              setLoading(false);
            })
            .catch((error) => message.error(error?.message || "Ошибка", 3));
        }
      });

      return () => {
        unsubscribe();
      };
    }

    checkAuth();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (currentUser && !loading) {
      const script = document.createElement("script");
      script.src = "//l.getsitecontrol.com/94myp0d4.js";
      script.async = true;
      document.body.appendChild(script);
    }

    Sentry.setUser(currentUser);
  }, [currentUser]);

  async function login({ email, password }) {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password).catch(() => {
      setLoading(false);
      message.error("Неверный логин или пароль, попробуйте еще раз", 2);
    });
  }

  async function register({ email, password, name, role = "customer" }) {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        // Ugly hack to make sure that user has correct role, to pass to backend
        await updateProfile(res.user, { displayName: name, photoURL: role });
      })
      .catch((err) => {
        if (includes("email-already-in-use", err.toString())) {
          return message.error(
            "Пользователь с такой эл.почтой уже существует.",
            2
          );
        }
        return message.error(
          "Произошла ошибка. Попробуйте еще раз или свяжитесь с нами.",
          2
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function updateFBEmail({ email }) {
    return updateEmail(auth.currentUser, email).catch((err) =>
      console.log(err)
    );
  }

  async function forgotPassword({ email }) {
    setLoading(true);
    return sendPasswordResetEmail(auth, email, {
      url: `${config.basepath}/login`,
    })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
        message.info(
          "Запрос на сброс пароля успешно отправлен. В случае, если почта указана верно, вам придет письмо.",
          3
        );
      });
  }

  function changePassword(newPassword) {
    return updatePassword(auth.currentUser, newPassword)
      .then(() => message.success("Пароль успешно обновлен", 2))
      .catch((err) => {
        if (err?.code === "auth/requires-recent-login") {
          return reauthenticateWithPopup(
            auth.currentUser,
            new GoogleAuthProvider()
          )
            .then(async function () {
              await changePassword(newPassword);
            })
            .catch(function () {
              return message.error(
                "Ошибка авторизации, проверьте пароль и попробуйте снова",
                3
              );
            });
        }

        return message.error("Ошибка, попробуйте обновить страницу", 2);
      });
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  async function sendVerification(user = null) {
    setLoading(true);
    return sendEmailVerification(user || auth.currentUser, {
      url: `${config.basepath}/login`,
    })
      .then(() => {
        setLoading(false);
        message.info("Ссылка с подтверждением направлена на вашу почту.", 2);
        return user || auth.currentUser;
      })
      .catch((error) => {
        setLoading(false);
        Sentry.setUser({ email: auth?.currentUser?.email });
        Sentry.captureException(error);
        message.error(
          "Слишком много запросов с этого устройства. Пожалуйста, попробуйте снова через пару минут.",
          2
        );
        return null;
      });
  }

  async function logout() {
    setLoading(true);
    return signOut(auth)
      .then(API.Auth.logout)
      .then(() => {
        localStorage.removeItem("token");
        setCurrentUser(null);
        setIsAwaitingVerification(false);
        setLoading(false);
        Sentry.setUser(null);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err?.message || "Ошибка", 2);
      });
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  function signInWithApple() {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    provider.setCustomParameters({
      locale: "ru",
    });

    return signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        // const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;

        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  }

  function signInWithPhone() {
    signInWithPhoneNumber(auth, "+000", window.recaptchaVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        console.log("recaptcha error", error);
        // Error; SMS not sent
        // ...
      });

    // const provider = new GoogleAuthProvider();
    // return signInWithPopup(auth, provider);
  }

  const value = {
    currentUser,
    setCurrentUser,
    signInWithGoogle,
    signInWithApple,
    signInWithPhone,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    loading,
    isAwaitingVerification,
    updateFBEmail,
    sendVerification,
    changePassword,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
