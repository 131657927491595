import { PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState, useEffect, Fragment } from "react";
import { Checkbox } from "antd";
import AddPhone from "./add";

import * as API from "@api/partner";
import { find, propEq } from "ramda";

export default function Phone({ form }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [phones, setPhones] = useState([]);
  const [selected, setSelected] = useState(form.getFieldValue("phones") || []);

  useEffect(() => {
    form.setFieldsValue({ phones: selected });
    /* eslint-disable-next-line */
  }, [selected]);

  useEffect(() => {
    API.CompanyContacts.getAll({ limit: 100, filter: { type: "phone" } }).then(
      (res) => {
        setPhones(res?.data?.rows || []);
      }
    );
  }, []);

  const getSelected = () => {
    if (phones?.length === 0) {
      return (
        <Fragment>
          <PhoneOutlined className="text-3xl my-3 mx-auto block text-neutral-300" />
          <span className="block w-full text-center text-sm text-black mb-5">
            Добавьте хотя бы один номер телефона.
          </span>
        </Fragment>
      );
    }

    return (
      <div className="my-5">
        {phones?.map((phone, i) => (
          <Checkbox
            className="mb-2 last:mb-0 mx-0 w-full"
            onChange={() => {
              if (find(propEq("id", phone?.id), selected)) {
                return setSelected(
                  selected.filter((item) => item.id !== phone?.id)
                );
              }

              setSelected([...selected, phone]);
            }}
            key={i}
            checked={find(propEq("id", phone?.id), selected)}
          >
            {phone?.contact}
          </Checkbox>
        ))}
      </div>
    );
  };

  const triggerModal = () => setModalOpen(!modalOpen);

  return (
    <div className="bg-neutral-100 p-5 rounded-md">
      <span className="block w-full text-center font-medium text-black text-base">
        Номера телефонов
      </span>

      {getSelected()}

      <Button
        onClick={triggerModal}
        icon={<PlusOutlined />}
        block
        type="default"
      >
        Добавить номер
      </Button>
      <AddPhone
        phones={phones}
        onAfterAdd={(phone) => {
          setPhones([...phones, phone]);
          setSelected([...selected, phone]);
        }}
        form={form}
        open={modalOpen}
        onCancel={triggerModal}
      />
    </div>
  );
}
