import classNames from "classnames";

export default function ExternalPromotion({ listing, className }) {
  return (
    <div className={classNames("mt-10", className)}>
      <span className="text-2xl font-semibold block text-neutral-800">
        Внешнее продвижение
      </span>

      <div className="grid grid-cols-12 gap-6 my-5 bg-white border border-solid border-neutral-200 p-6 rounded-md">
        <div className="col-span-12 lg:col-span-8">
          <img
            src="/img/promotions/kapital.svg"
            alt="Kapital.kz"
            height={26}
            className="block"
          />
          <span className="text-base text-neutral-800 block mt-3">
            Ваше объявление увидят пользователи новостного портала Капитал.
            Более миллиона ежемесячных пользователей, которых интересуют бизнес,
            экономика и финансы, что является лучшей аудиторией для продажи
            бизнеса.{" "}
          </span>
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-start lg:justify-end items-center">
          <a
            href={`mailto:info@sdelka.kz?subject=Внешнее продвижение (ID объявления: ${listing.product.id})&body=Здравствуйте, хочу узнать больше о продвижении объявления на сайте Kapital.kz.`}
            alt="Написать"
            className="text-base font-semibold block rounded-md bg-green-100 w-fit px-5 py-2"
          >
            Оставить заявку
          </a>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 my-5 bg-white border border-solid border-neutral-200 p-6 rounded-md">
        <div className="col-span-12 lg:col-span-8">
          <span className="block text-xl font-semibold text-neutral-800">
            Instagram, Facebook
          </span>
          <span className="text-base text-neutral-800 block mt-3">
            Внешнее продвижение Вашего объявления, через таргетированную рекламу
            компании Meta.
          </span>
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-start lg:justify-end items-center">
          <a
            href={`mailto:info@sdelka.kz?subject=Внешнее продвижение (ID объявления: ${listing.product.id})&body=Здравствуйте, хочу узнать больше о продвижении объявления на в Instagram и Facebook`}
            alt="Написать"
            className="text-base font-semibold block rounded-md bg-green-100 w-fit px-5 py-2"
          >
            Оставить заявку
          </a>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-6 my-5 bg-white border border-solid border-neutral-200 p-6 rounded-md">
        <div className="col-span-12 lg:col-span-8">
          <span className="block text-xl font-semibold text-neutral-800">
            Рекламная сеть Google
          </span>
          <span className="text-base text-neutral-800 block mt-3">
            Внешнее продвижение Вашего объявления на рекламной сети Google -
            поиск Google, YouTube, медийные объявления на сайтах и в
            приложениях.
          </span>
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-start lg:justify-end items-center">
          <a
            href={`mailto:info@sdelka.kz?subject=Внешнее продвижение (ID объявления: ${listing.product.id})&body=Здравствуйте, хочу узнать больше о продвижении объявления в Google.`}
            alt="Написать"
            className="text-base font-semibold block rounded-md bg-green-100 w-fit px-5 py-2"
          >
            Оставить заявку
          </a>
        </div>
      </div>
    </div>
  );
}
