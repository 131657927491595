import { message } from "antd";
import * as API_ALL from "@api/customer";
import { pathOr } from "ramda";

export async function getById({ type = "sale", id = null }) {
  const API =
    type === "franchise"
      ? API_ALL.Franchises
      : type === "investment"
      ? API_ALL.Investments
      : API_ALL.Sales;

  const _res = await API.getOne({ id });
  if (!_res || _res.error) {
    return message.error(
      "Произошла ошибка при загрузке вашего объявления. Попробуйте снова или обратиесь в службу поддержки.",
      3,
      () => document.location.replace("/")
    );
  }

  if (_res.data.product.status === "in_review") {
    return message.error(
      "Произошла ошибка при загрузке вашего объявления. Попробуйте снова или обратиесь в службу поддержки.",
      3,
      () => document.location.replace("/")
    );
  }

  return {
    ..._res.data.product,
    options: pathOr([], ["options"], _res.data.product).map(JSON.stringify),
    contacts: _res.data.product?.contacts || [],
    description: _res.data[_res.data.product.type].description,
    category_id: [_res.data.product.category_id],
    [_res.data.product.type]: _res.data[_res.data.product.type],
  };
}
