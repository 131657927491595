import PackageCard from "./card";
import classNames from "classnames";

export default function Packages({ isApp = false, data, listing, className }) {
  return (
    <div className={classNames("grid grid-cols-12 gap-6 mt-7", className)}>
      {data.packages
        .sort(function (a, b) {
          return a.id - b.id;
        })
        .map((pkg) => (
          <PackageCard
            isApp={isApp}
            key={pkg.id}
            className="col-span-12 lg:col-span-4"
            data={pkg}
            listing={listing}
          />
        ))}
    </div>
  );
}
