import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import config from "@config";

const LocaleContext = createContext({
  locale: "ru",
  code: "ru-RU",
  switchLocale: () => Promise,
  loading: false,
});

export const LOCALE_CODES = {
  ru: "ru-RU",
  kk: "kk-KZ",
  en: 'en-US'
};

const COOKIE_DOMAINS = {
  development: "127.0.0.1",
  staging: ".market.musan.digital",
  production: ".sdelka.kz",
  default: ".sdelka.kz",
};

export const useLocale = () => useContext(LocaleContext);

export default function LocaleProvider({ children }) {
  const [cookies, setCookie] = useCookies(["pref_locale2"]);
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(cookies.pref_locale2 || null);
  const [code, setCode] = useState(locale ? LOCALE_CODES[locale] : null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!locale) {
      setLocale("ru");
      setCode("ru-RU");

      // set cookie pref_locale2 to chosen locale, options: domain: .sdelka.kz, path: /, expires in 3 months
      setCookie("pref_locale2", "ru", {
        domain: COOKIE_DOMAINS[config.env],
        path: "/",
        expires: new Date(Date.now() + 7776000000),
      });

      return;
    }

    // change i18n locale
    i18n.changeLanguage(locale);
  }, [locale]);

  async function switchLocale({ locale, reload = true }) {
    if (!locale) {
      return;
    }

    setCookie("pref_locale2", locale, {
      domain: COOKIE_DOMAINS[config.env],
      path: "/",
      expires: new Date(Date.now() + 7776000000),
    });
    setLocale(locale);
    setCode(LOCALE_CODES[locale]);

    if (reload) {
      window.location.reload();
    }
  }

  const value = {
    locale,
    code,
    switchLocale,
    loading,
  };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}
