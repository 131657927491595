import { Empty } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export default function NewListingItem({ className, isApp = false }) {
  const { t } = useTranslation();

  return (
    <div className={classNames("py-10 flex flex-col text-center", className)}>
      <Empty description={<span>{t("listings.empty")}</span>} />
    </div>
  );
}
