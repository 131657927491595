import axios from "axios";
import config from "@config";

const API = `${config.api}/users`;

export const getOne = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const update = (data) =>
  axios
    .post(`${API}/`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const updateNotificationSettings = (data) =>
  axios
    .post(`${API}/notification_settings`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const updatePhone = (data) =>
  axios
    .post(`${API}/phone`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const removeById = (id) =>
  axios
    .delete(
      `${API}/`,
      { data: { id } },
      {
        withCredentials: true,
      }
    )
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
