import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading } from "@components";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import * as API from "@api/moderator";

export default function Page() {
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const filter = {
    locale_id: searchParams.get("locale_id") || 1,
    category_id: searchParams.get("category_id") || 1,
    type_id: searchParams.get("type_id") || 1,
    author_id: searchParams.get("author_id") || 1
  };

  useEffect(() => {
    if (!data) {
      API.Blog.createArticle({
        title: `Черновик ${new Date().toLocaleString()}`,
        slug: `draft-${Date.now()}`,
        locale_id: filter?.locale_id,
        category_id: filter?.category_id,
        type_id: filter?.type_id,
        author_id: filter?.author_id,
        is_commentable: true
      })
        .then(res => {
          if (!res || res?.error) {
            throw new Error("Ошибка создания черновика");
          }
          setData(res?.data);
        })
        .catch(err => {
          return message.error(err?.message || "Ошибка создания черновика", 2);
        });
    }
  }, [data]);

  if (data?.id) {
    return <Navigate to={`/blog/articles/edit/${data?.id}`} />;
  }

  return <Loading />;
}
