import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function StatusSuccess() {
  const [timeLeft, setTimeLeft] = useState(10);
  const navigate = useNavigate();

  const isWebview = () => {
    const navigator = window.navigator;
    const userAgent = navigator.userAgent;
    const normalizedUserAgent = userAgent.toLowerCase();
    const standalone = navigator.standalone;

    const isIos =
      /ip(ad|hone|od)/.test(normalizedUserAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    const isAndroid = /android/.test(normalizedUserAgent);
    const isSafari = /safari/.test(normalizedUserAgent);
    const isWebview =
      (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
      (isIos && !standalone && !isSafari);

    return isWebview;
  };

  useEffect(() => {
    const t = setInterval(() => {
      if (timeLeft <= 1) {
        navigate(isWebview() ? "/app/listings" : "/", { replace: true });
      } else {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(t);
    /* eslint-disable-next-line */
  }, [timeLeft]);

  return (
    <div className="container content-max-promote status-page py-5">
      <div className="layout-card p-5 lg:p-9">
        <img
          src="/img/promotions/icon-success.svg"
          width={50}
          height={50}
          className="block mx-auto"
          alt="Успешная оплата"
        />
        <span className="w-full block text-center font-semibold text-xl text-neutral-800 my-5">
          Оплата прошла успешно!
        </span>
        <span className="w-full block text-center text-base text-black">
          Благодарим за оплату. Выбранные вами услуги будут активированы
          автоматически.
        </span>
        <Link to={isWebview() ? "/app/listings" : "/"}>
          <Button className="mx-auto mt-10 block" type="primary">
            Вернуться в Личный Кабинет ({timeLeft} с.)
          </Button>
        </Link>
      </div>
    </div>
  );
}
