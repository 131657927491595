import axios from "axios";
import config from "@config";

const API = `${config.static}`;

// Загружает изображение через Static API
export const uploadFile = (data) => {
  const form = new FormData();
  form.append("file", data?.file);

  if (!data?.id) {
    return null;
  }

  return axios
    .post(`${API}/files/upload/${data.id}`, form, { withCredentials: true })
    .then((res) => {
      if (res.data.error) {
        return { success: false };
      }

      return { success: 1, data: res.data.data, error: false };
    })
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
};

// Загружает изображения через Static API
export const uploadFiles = (data) => {
  const form = new FormData();

  data.files.map((file) => form.append("files", file));

  if (!data?.id) {
    return null;
  }

  return axios
    .post(`${API}/files/upload_multiple/${data.id}`, form, {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data.error) {
        return { success: false };
      }

      return { success: 1, data: res.data.data, error: false };
    })
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
};
