import { useLoaderData } from "react-router-dom";
import { Payments } from "@modules/partner";

export default function PaymentsPage() {
  const initialData = useLoaderData();

  return (
    <div className="my-5">
      <h2 className="mb-5">Платежи</h2>
      <Payments initialData={initialData} />
    </div>
  );
}
