import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api/moderator";

export default function FormInput(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    // first request to get a count of authors API.Blog.getAuthors({limit: 1}) -> {...count: x}
    // second request to recursively get all authors with promises, max limit per request is 10

    const getAuthors = async (limit, offset, total) => {
      if (offset >= total) {
        return;
      }

      const res = await API.Blog.getAuthors({ limit: 10, offset });
      if (res.error || !res) {
        return;
      }

      setData(prev => [...prev, ...res?.data?.rows]);
      return getAuthors(10, offset + 10, total);
    };

    API.Blog.getAuthors({ limit: 1 }).then(res => {
      if (res.error || !res) {
        return;
      }

      getAuthors(10, 0, res?.data?.count);
    });
  }, []);

  return (
    <Form.Item
      {...props}
      name="author_id"
      className="w-full"
      label="Автор"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите автора"
        }
      ]}
    >
      <Select
        placeholder="Выберите"
        className="w-full"
        showSearch
        filterOption={(input, option) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
        options={data.map(val => {
          return {
            label: val.name,
            value: val.id
          };
        })}
      />
    </Form.Item>
  );
}
