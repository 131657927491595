import axios from "axios";
import config from "@config";

const API = `${config.api}/customer/favorites`;

// export const getAll = (params) =>
//   axios
//     .get(`${API}/`, {
//       params,
//       withCredentials: true,
//     })
//     .then((res) => res.data)
//     .catch((err) => ({
//       error: true,
//       message: err.message || err?.response?.data || "Ошибка сервера",
//     }));

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const remove = (data) =>
  axios
    .delete(`${API}/product_id/${data.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
