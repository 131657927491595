import * as Pages from "@pages/partner";
import * as Loaders from "./loaders";
import Layout from "@modules/layouts/partner";

const routes = [
  {
    path: "/payments",
    element: (
      <Layout page="payments">
        <Pages.Payments />
      </Layout>
    ),
  },
  {
    path: "/settings",
    element: (
      <Layout page="settings">
        <Pages.Settings />
      </Layout>
    ),
  },
  {
    path: "/notifications",
    element: (
      <Layout page="notifications">
        <Pages.Notifications />
      </Layout>
    ),
    loader: Loaders.Notifications,
  },
  {
    path: "/companies/edit/:id",
    element: (
      <Layout page="companies">
        <Pages.Companies.Edit />
      </Layout>
    ),
    loader: Loaders.Companies.getCompany,
  },
  {
    path: "/companies/new",
    element: (
      <Layout page="companies">
        <Pages.Companies.New />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
