import { useLoaderData } from "react-router-dom";
import { Promote } from "@modules/customer";

export default function PromotePage({ isApp = false }) {
  const { type, tariffsData, listing } = useLoaderData();

  return (
    <Promote
      isApp={isApp}
      listing={listing}
      tariffsData={tariffsData}
      type={type}
    />
  );
}
