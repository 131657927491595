import { useState } from "react";
import { Input, Button, Form, Card, message, Divider } from "antd";
import { useAuth } from "@contexts";

export default function Password() {
  const [loading, setLoading] = useState(false);
  const { changePassword } = useAuth();

  const onPasswordUpdate = async (values) => {
    if (values.password !== values.password_confirm) {
      return message.error("Пароли не совпадают", 2);
    }

    setLoading(true);
    await changePassword(values.password);
    setLoading(false);
  };

  return (
    <Card
      title="Сменить пароль"
      className="shadow-lg shadow-gray-100 transition-all h-full w-full"
    >
      <p className="text-slate-500">
        Новый пароль должен содержать мин. 6 символов.
      </p>
      <Form
        className="global-form"
        layout="vertical"
        scrollToFirstError
        onFinish={onPasswordUpdate}
      >
        <Form.Item
          label="Новый пароль"
          name="password"
          rules={[
            {
              required: true,
              min: 6,
              message: "Укажите новый пароль. Мин. 6 символов.",
            },
          ]}
        >
          <Input.Password placeholder="Укажите новый пароль" />
        </Form.Item>
        <Form.Item
          label="Повторите пароль"
          name="password_confirm"
          rules={[
            {
              required: true,
              min: 6,
              message: "Укажите новый пароль. Мин. 6 символов.",
            },
          ]}
        >
          <Input.Password placeholder="Повторите новый пароль" />
        </Form.Item>
        <Divider />
        <Form.Item className="!mb-0">
          <Button loading={loading} htmlType="submit" type="primary">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
