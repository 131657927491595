module.exports = {
  env: process.env.REACT_APP_ENV || process.env.NODE_ENV || "production",
  port: process.env.PORT || process.env.REACT_APP_PORT || "3001",
  api: process.env.REACT_APP_API_ENDPOINT,
  static: process.env.REACT_APP_STATIC_ENDPOINT,
  frontend: process.env.REACT_APP_FRONTEND_ENDPOINT,
  basepath: process.env.REACT_APP_BASEPATH,
  paidServices: process.env.REACT_APP_ENABLE_PAID_SERVICES === "TRUE",
  revalidateSecret: process.env.REACT_APP_REVALIDATE_SECRET,
  s3: {
    endpoint: process.env.REACT_APP_S3_BUCKET_ENDPOINT
  },
  antd: {
    theme: {
      token: {
        colorPrimary: "#00b25d",
        colorLink: "#00b25d",
        colorLinkActive: "#008c4d",
        colorLinkHover: "#1fbf6c"
      }
    }
  },
  imgproxy: {
    baseUrl: process.env.REACT_APP_IMGPROXY_BASEURL,
    signature: {
      key: process.env.REACT_APP_IMGPROXY_KEY,
      salt: process.env.REACT_APP_IMGPROXY_SALT,
      size: 8
    }
  },
  allowPartnerRegistration: true,
  ymaps: {
    // api_key: "77dda30d-3a0e-4d7e-bf79-d28312ea5f42" // index.html
    api_key: "1e8ba255-fdb8-4443-8327-0a53f3a918b5" // index.html
  }
};
