/* eslint-disable no-undef */
import React from "react";
import ReactDom from "react-dom";

ymaps3.import.registerCdn("https://cdn.jsdelivr.net/npm/{package}", "@yandex/ymaps3-default-ui-theme@latest");

const [ymaps3React, ymapsDefaulsMarkerModule, ymapsDefaulsUI] = await Promise.all([
  ymaps3.import("@yandex/ymaps3-reactify"),
  ymaps3.import("@yandex/ymaps3-markers@0.0.1"),
  ymaps3.import("@yandex/ymaps3-default-ui-theme"),
  ymaps3.ready
]);

export const reactify = ymaps3React.reactify.bindTo(React, ReactDom);
export const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker, YMapListener, YMapControls, search, suggest } = reactify.module(ymaps3);

export const YMapDefaultMarker = reactify.entity(ymapsDefaulsMarkerModule.YMapDefaultMarker);
export const YMapZoomControl = reactify.entity(ymapsDefaulsUI.YMapZoomControl);
