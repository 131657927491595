import { Button, Card, Segmented } from "antd";
import { Listings } from "@modules/moderator";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

export default function ListingsPage() {
  const [productType, setProductType] = useState(
    localStorage.getItem("listings:filter:type") || "sale"
  );
  const defaultFilterValues =
    JSON.parse(localStorage.getItem("listings:filter")) || null;

  useEffect(() => {
    localStorage.setItem("listings:filter:type", productType);
  }, [productType]);

  const resetFilters = () => {
    localStorage.removeItem("listings:filter");
    localStorage.removeItem("listings:filter:type");
    window.location.reload();
  };

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Объявления</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card
              bordered={false}
              className="h-full"
              extra={
                <Button
                  onClick={resetFilters}
                  type="link"
                  className="w-fit m-0 p-0"
                >
                  Сбросить фильтр
                </Button>
              }
            >
              <Segmented
                className="mb-7"
                block
                defaultValue={productType}
                onChange={(type) => setProductType(type)}
                options={[
                  { label: "Бизнес", value: "sale" },
                  { label: "Франшизы", value: "franchise" },
                  { label: "Инвестиции", value: "investment" },
                ]}
              />
              <Listings.List
                key={productType}
                pageSize={10}
                type={productType}
                defaultFilterValues={defaultFilterValues}
              />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
