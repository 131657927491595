import axios from "axios";
import config from "@config";
import * as Sentry from "@sentry/react";

const API = `${config.api}/customer/products`;

export const createFeedback = async (data) =>
  axios
    .post(`${API}/feedback`, data, {
      withCredentials: true,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // Capture new feedback error
      Sentry.captureException(err);
      Sentry.captureMessage("Error creating product feedback");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });
