import { useCallback, useEffect, useState } from "react";
import { Input, Button, Form, Card, message, Divider, Modal } from "antd";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as API from "@api";

export default function Details() {
  const { t } = useTranslation();
  const { loading, currentUser } = useAuth();
  const [updating, setUpdating] = useState(false);
  const [isModalDeleteOpen, setModalDeleteToggle] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const _res = await API.Users.getOne();

      if (!_res?.data || _res.error) {
        return message.error(t("generic.serverError"), 3);
      }

      setData(_res.data);
    })();
  }, [currentUser]);

  const onDetailsUpdate = async values => {
    setUpdating(true);
    const _res = await API.Users.update(values);
    setUpdating(false);

    if (!_res || _res.error) {
      return message.error(t("generic.serverError"), 3);
    }

    return message.success(t("generic.updatedSuccess"), 2);
  };

  const onDetailsRemoveAccount = useCallback(async () => {
    setUpdating(true);
    const _res = await API.Users.removeById(currentUser.id);
    setUpdating(false);

    if (!_res || _res.error) {
      return message.error(t("generic.serverError"), 3);
    }

    message.success(t("settings.accountDeletedSuccess"), 2);
    await API.Auth.logout();
    setTimeout(() => {
      window.location.href = "/login";
    }, 1500);
  }, [currentUser]);

  return (
    <Card title={t("settings.personalDetailsTitle")} className="shadow-lg shadow-gray-100 transition-all h-full w-full">
      {data && (
        <Form className="global-form" layout="vertical" scrollToFirstError onFinish={onDetailsUpdate} initialValues={data}>
          <Form.Item
            label={t("settings.nameTitle")}
            name="display_name"
            rules={[
              {
                required: true,
                message: t("settings.namePrompt")
              }
            ]}
          >
            <Input placeholder={t("settings.namePlaceholder")} />
          </Form.Item>
          <Form.Item
            label={t("settings.emailTitle")}
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: t("settings.emailPrompt")
              }
            ]}
          >
            <Input disabled placeholder={t("settings.emailPlaceholder")} />
          </Form.Item>
          <span className="text-sm block text-neutral-400 my-1">
            {t("settings.created")} {dayjs(data.created_at).format("DD.MM.YYYY")}
          </span>
          <span className="text-sm block text-neutral-400 mt-1">
            {t("settings.lastLogin")} {dayjs(data.last_login_at).format("HH:mm DD.MM.YYYY")}
          </span>
          <Divider />
          <Form.Item>
            <Button loading={loading || updating} htmlType="submit" type="primary">
              {t("generic.save")}
            </Button>
          </Form.Item>
          <Form.Item className="!mb-0">
            <Button loading={loading || updating} htmlType="button" type="primary" danger onClick={() => setModalDeleteToggle(true)}>
              {t("settings.deleteAcc")}
            </Button>
            <Modal
              title={t("settings.deleteAccTitle")}
              open={isModalDeleteOpen}
              okText={t("settings.deleteAccConfirm")}
              cancelText={t("generic.cancel")}
              onOk={() => onDetailsRemoveAccount()}
              onCancel={() => setModalDeleteToggle(false)}
              confirmLoading={loading || updating}
              okButtonProps={{
                danger: true
              }}
            >
              <p>{t("settings.deleteAccText")}</p>
            </Modal>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
}
