import { useState, useEffect } from "react";
import { Card, Table, message, Button } from "antd";
import { Link } from "react-router-dom";
import * as Icons from "@ant-design/icons";
import * as API from "@api/moderator";
import dayjs from "dayjs";

export default function OrgTypes() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, []);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.OrgTypes.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Ошибка. Попробуйте еще раз позже.", 2);
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setLoading(false);
  }

  return (
    <Card
      bordered={false}
      title="Все виды"
      extra={
        <Link to="/dict/orgtypes/new">
          <Button icon={<Icons.PlusOutlined />}>Создать</Button>
        </Link>
      }
    >
      <Table
        onChange={getTableData}
        pagination={pagination}
        dataSource={data}
        loading={loading}
        showHeader={false}
        className="rounded-sm overflow-hidden"
        scroll={{ x: "max-content" }}
        columns={[
          {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            align: "left",
            render: (data, all) => (
              <div>
                <span className="block text-base font-semibold text-black">{data}</span>
                <span className="block text-sm text-slate-500">
                  Создан {dayjs(all.created_at).format("HH:mm DD.MM.YYYY")} &middot; Обновлен {dayjs(all.updated_at).format("HH:mm DD.MM.YYYY")}
                </span>
              </div>
            )
          },
          {
            dataIndex: "actions",
            key: "actions",
            align: "right",
            render: (_, all) => (
              <Link to={`/dict/orgtypes/edit/${all.id}`}>
                <Button icon={<Icons.EyeOutlined />}>Открыть</Button>
              </Link>
            )
          }
        ]}
      />
    </Card>
  );
}
