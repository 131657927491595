import { Select } from "antd";

const services = [
  {
    value: "success",
    label: "Активно (успешный платеж)",
  },
];

export default function Status(params) {
  return (
    <Select
      className="w-full"
      placeholder="Статус"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("status", v)}
      options={services}
    />
  );
}
