import { useState } from "react";
import { Input, Button, Form, Card, message, Divider } from "antd";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";

export default function Password() {
  const [loading, setLoading] = useState(false);
  const { changePassword } = useAuth();
  const { t } = useTranslation();

  const onPasswordUpdate = async (values) => {
    if (values.password !== values.password_confirm) {
      return message.error(t("settings.pswMismatch"), 2);
    }

    setLoading(true);
    await changePassword(values.password);
    setLoading(false);
  };

  return (
    <Card
      title="Сменить пароль"
      className="shadow-lg shadow-gray-100 transition-all h-full w-full"
    >
      <p className="text-slate-500">{t("settings.pswDescription")}</p>
      <Form
        className="global-form"
        layout="vertical"
        scrollToFirstError
        onFinish={onPasswordUpdate}
      >
        <Form.Item
          label={t("settings.pswNew")}
          name="password"
          rules={[
            {
              required: true,
              min: 6,
              message: t("settings.pswNewPrompt"),
            },
          ]}
        >
          <Input.Password placeholder={t("settings.pswNewPlaceholder")} />
        </Form.Item>
        <Form.Item
          label={t("settings.pswRepeatTitle")}
          name="password_confirm"
          rules={[
            {
              required: true,
              min: 6,
              message: t("settings.pswRepeatPrompt"),
            },
          ]}
        >
          <Input.Password placeholder={t("settings.pswRepeatPlaceholder")} />
        </Form.Item>
        <Divider />
        <Form.Item className="!mb-0">
          <Button loading={loading} htmlType="submit" type="primary">
            {t("generic.save")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
