import { Tag, Card, List } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

export default function Options({ data, className }) {
  return (
    <Card bordered={false} title="Опции" className={className}>
      <List
        itemLayout="horizontal"
        dataSource={data?.product?.options || []}
        renderItem={(option, key) => (
          <Tag
            key={key}
            color="default"
            className="my-1 mr-2 capitalize"
            icon={<CheckCircleFilled className="text-primary" />}
          >
            {option?.name}
          </Tag>
        )}
      />
    </Card>
  );
}
