import * as API from "@api/partner";
import * as CoreAPI from "@api";

export default async function PageLoader() {
  const params = {
    current: 1,
    pageSize: 10,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
  };

  const [_resNotifications] = await Promise.all([
    API.Notifications.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    }),
    CoreAPI.Auth.getAuth(),
  ]);

  return {
    data: _resNotifications?.data?.rows || [],
    pagination: { ...params, total: _resNotifications?.data?.count || 0 },
  };
}
