import { Button } from "antd";
import { Fragment } from "react";
import config from "@config";

export default function Preview({ data = {}, isApp = false }) {
  const { product } = data
  const productType = product.type === "investment" ? 'startups' : product.type
  const productLink = product.status !== "published"
    ? `${config.frontend}/preview/${productType}/${product.id}`
    : `${config.frontend}/${productType}/${product.id}/${product.slug}`
  return (
    <Fragment>
      <span className="block text-xs text-neutral-400 whitespace-nowrap leading-none">
        {`ID ${product.id}`}
      </span>
      <div className="w-full bg-neutral-200 mx-3" style={{ height: 1 }}></div>
      {!isApp && (
        <Button
          rel="noreferrer"
          target="_blank"
          href={productLink}
          className="m-0 p-0 border-none h-fit bg-transparent"
          type="link"
        >
          { product.status !== "published" ? 'Предпросмотр' : 'Открыть на сайте'}
        </Button>
      )}
    </Fragment>
  );
}
