import { useState, useRef, useEffect } from "react";
import { Button, Popover } from "antd";
import classNames from "classnames";
import * as reference from "./reference";
import * as API from "@api/customer";

export default function Card({ data, listing, className, key }) {
  const formRef = useRef();
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const onPackageSelect = async ({ id }) => {
    const form = formRef.current;
    setLoading(true);

    const _res = await API.Payments.create({
      payment_type: "package",
      product_id: listing.product.id,
      package_id: id,
    });

    form.action = _res.data.url;
    form.method = _res.data.method;
    setFormFields(_res.data.body);

    return;
  };

  useEffect(() => {
    if (formFields?.pg_sig) {
      const timer = setTimeout(() => {
        formRef.current.submit();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [formFields]);

  const getFormData = () => {
    const inputs = [];
    for (const [key, value] of Object.entries(formFields)) {
      inputs.push({ key, value });
    }

    return inputs.map((i) => <input name={i.key} value={i?.value} />);
  };

  const tariffPreview = (tariff) => {
    const info = reference.tariffDescription(tariff);
    return (
      <div className="w-fit" style={{ maxWidth: 230 }}>
        <span className="text-sm text-black">{info.description}</span>
        <img src={info.img} alt="Изображение" className="block w-full mt-5" />
      </div>
    );
  };

  return (
    <div
      key={key}
      className={classNames(["package-card", className], { loading })}
    >
      <form className="hidden invisible h-0 w-0" ref={formRef} method="POST">
        {getFormData()}
      </form>
      <div className="header">
        <div>
          <span className="title">{data.name}</span>
          <span className="subtitle">{reference.packageLength(data)}</span>
          <span className="description">{data.description}</span>
        </div>
        <img
          className="icon"
          alt={data.name}
          src={reference.packageIcon(data)}
          width={56}
          height={56}
        />
      </div>
      <span className="price">{reference.packagePrices(data)}</span>

      <ul className="feature-list mb-8">
        {data.tariffs
          .sort(function (a, b) {
            return a.id - b.id;
          })
          .map((tariff) => (
            <li>
              <img
                className="block mr-2"
                alt={data.name}
                src={reference.tariffIcon(tariff)}
                width={16}
                height={16}
              />
              <span className="text-base font-normal leading-snug text-neutral-800 mr-2">
                {tariff.name}
              </span>
              <Popover
                placement="bottom"
                title={tariff.name}
                content={() => tariffPreview(tariff)}
              >
                <img
                  src="/img/promotions/info.svg"
                  className="block cursor-pointer"
                  alt="Информация"
                  width={16}
                  height={16}
                />
              </Popover>
            </li>
          ))}

        {data.id === 1 && [
          <li style={{ filter: "grayscale(1)" }}>
            <img
              className="block mr-2"
              alt="Выделить цветом"
              src="/img/promotions/icon-highlight.svg"
              width={16}
              height={16}
            />
            <span className="text-base font-normal leading-snug text-neutral-400">
              Выделить цветом
            </span>
          </li>,
          <li style={{ filter: "grayscale(1)" }}>
            <img
              className="block mr-2"
              alt="Поднять"
              src="/img/promotions/icon-raise.svg"
              width={16}
              height={16}
            />
            <span className="text-base font-normal leading-snug text-neutral-400">
              Поднять
            </span>
          </li>,
        ]}
      </ul>
      <Button
        onClick={() => !loading && onPackageSelect(data)}
        size="large"
        block
        type="primary"
        loading={loading}
      >
        Выбрать тариф
      </Button>
    </div>
  );
}
