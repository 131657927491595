import axios from "axios";
import config from "@config";

const API = `${config.api}/partner/companies`;

export const getAll = params =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getOne = params =>
  axios
    .get(`${API}/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getOneBySlug = params =>
  axios
    .get(`${API}/slug/${params.slug}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const create = data =>
  axios
    .post(`${API}/`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const destroy = data =>
  axios
    .delete(`${API}/id/${data.id}`, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const update = (id, data) =>
  axios
    .post(`${API}/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const unpublish = ({ id }) =>
  axios
    .post(`${API}/unpublish/id/${id}`, null, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateCountries = (id, data) =>
  axios
    .post(`${API}/countries/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateCities = (id, data) =>
  axios
    .post(`${API}/cities/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateContacts = (id, data) =>
  axios
    .post(`${API}/contacts/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
