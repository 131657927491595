import { Loading } from "@components";
import { useSearchParams } from "react-router-dom";
import { useLocale } from "@contexts/locale";
import classNames from "classnames";

export default function AppSellerLayout(props) {
  const { children, loading } = props;
  const [searchParams] = useSearchParams();
  const { locale, switchLocale } = useLocale();
  const searchLocale = searchParams.get("locale");

  if (searchLocale && searchLocale !== locale) {
    switchLocale({ locale: searchLocale, reload: false });
  }

  return (
    <div
      {...props}
      className={classNames(
        "w-full overflow-hidden max-w-xl mx-auto p-5",
        props.className
      )}
      style={{ paddingTop: 20, paddingBottom: 20, minHeight: 360 }}
    >
      {loading ? <Loading /> : children}
    </div>
  );
}
