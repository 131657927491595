import { Button, message, Checkbox } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Loading } from "@components";
import { useState, useEffect } from "react";
import { find, propEq, isEmpty, filter, pathOr } from "ramda";
import { useTranslation } from "react-i18next";
import * as API from "@api/customer";

export default function ContactsModal({
  selected = [],
  onPageChange,
  onClose,
  onSelect,
}) {
  const { t } = useTranslation();
  const [availablePhones, setAvailablePhones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const _res = await API.Contacts.getAll({ limit: 100 });

      if (!_res.data || !_res) {
        return message.error(t("contacts.errorLoading"), 3);
      }

      const contacts = filter(
        propEq("phone_confirmed", true),
        pathOr([], ["data", "rows"], _res)
      );

      setAvailablePhones(contacts || []);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (isEmpty(availablePhones)) {
    onPageChange("add");
    return null;
  }

  return (
    <div>
      <span className="text-base block font-medium text-black mb-5">
        {t("contacts.adding")}
      </span>
      {availablePhones.map((phone, i) => (
        <div
          key={`phone-${phone.id}-${i}`}
          className="w-full flex justify-between items-center my-2"
        >
          <Checkbox
            key={`cb-${phone.id}-${i}`}
            className="p-2 lg:p-3 lg:px-4 bg-neutral-50 rounded-md !w-full text-sm font-medium"
            checked={onSelect ? find(propEq("id", phone.id), selected) : true}
            onChange={() => onSelect && onSelect(phone)}
          >
            {phone.phone}
          </Checkbox>

          <Button
            hidden
            className="ml-3"
            type="text"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
          />
        </div>
      ))}
      <Button
        type="dashed"
        className="py-2 h-auto"
        block
        icon={<PlusOutlined />}
        onClick={() => onPageChange("add")}
      >
        {t("contacts.add")}
      </Button>
      <Button
        onClick={onClose}
        className="mt-10 py-2 h-auto"
        type="primary"
        block
      >
        {t("generic.done")}
      </Button>
    </div>
  );
}
