import { useState } from "react";
import { Card, Form, message, Button, Divider, Input } from "antd";
import { useNavigate } from "react-router-dom";
import * as API from "@api/moderator";

export default function New() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFormSubmit = async (data) => {
    setLoading(true);
    const _res = await API.Countries.create(data);
    setLoading(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка при создании. Пожалуйста, попробуйте снова или обратитесь к администратору.",
        2
      );
    }

    return message.success("Запись успешно создана.", 1, () =>
      navigate("/dict/countries")
    );
  };

  return (
    <Card bordered={false}>
      <Form
        className="global-form"
        layout="vertical"
        scrollToFirstError
        onFinish={onFormSubmit}
      >
        <Form.Item
          label="Название"
          help="Введите название страны"
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название.",
            },
          ]}
        >
          <Input placeholder="Введите название" />
        </Form.Item>
        <Divider />
        <Form.Item name="submit">
          <Button loading={loading} type="primary" htmlType="submit">
            Отправить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
