import { Modal, Button, Input, message } from "antd";
import { Loading } from "@components";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import config from "@config";
import * as API from "@api/customer/products";

const dictionary = {
  title: {
    sale: "Вам удалось продать бизнес?",
    franchise: "Вам удалось продать франшизу?",
    investment: "Вам удалось найти инвестиции?",
  },
  sold: {
    sale: "Где вам удалось продать бизнес?",
    franchise: "Где вам удалось продать франшизу?",
    investment: "Где вам удалось найти инвестиции?",
  },
  removed: "Причина неудачи?",
  reasonPrefix: {
    sale: "Продал (-а)",
    franchise: "Продал (-а)",
    investment: "Нашёл (-а)",
  },
};

export default function ProductFeedback({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("outcome");
  const [showFeedback, setShowFeedback] = useState(false);
  const [history, setHistory] = useState({
    outcome: null,
    outcome_reason: null,
    feedback: null,
  });

  useEffect(() => {
    if (stage === "thankyou") {
      return onSubmit();
    }

    setShowFeedback(false);
    setHistory({ ...history, feedback: null });
    /* eslint-disable-next-line */
  }, [stage]);

  const onSubmit = async () => {
    setLoading(true);

    await API.createFeedback({
      product_id: data.product.id,
      outcome: history.outcome,
      outcome_reason: history.outcome_reason,
      outcome_feedback: history.feedback,
    });

    setLoading(false);

    message.success(
      "Спасибо за обратную связь! Объявление снято с сайта",
      2,
      () => document.location.reload()
    );
  };

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={
        <span className="block p-3 w-full text-center text-sm text-neutral-500">
          Опрос проводится для проверки эффективности сайта.
        </span>
      }
      onCancel={onCancel}
      open
    >
      {loading && <Loading />}

      <div hidden={loading || stage !== "thankyou"} className="py-10 px-2">
        <CheckCircleFilled className="text-6xl block leading-none text-green-600" />
        <span className="block font-medium text-xl w-full text-center text-black">
          Спасибо, что уделили нам время!
        </span>

        <Button
          block
          className="mt-5"
          onClick={() => document.location.reload()}
        >
          Вернуться к объявлениям
        </Button>
      </div>

      <div hidden={loading || stage === "thankyou"}>
        <div className="flex justify-between items-center mb-3">
          <Button
            disabled={stage === "outcome"}
            onClick={() =>
              setStage(
                "outcome",
                setHistory({
                  outcome: null,
                  outcome_reason: null,
                  feedback: null,
                })
              )
            }
            shape="circle"
            icon={<ArrowLeftOutlined />}
            type="text"
            className="m-0 p-0"
          />
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            type="text"
            className="m-0 p-0"
          />
        </div>
        <span className="text-xl font-medium block text-black">
          {stage === "outcome" && dictionary.title[data.product.type]}
          {stage === "outcome_reason" &&
            history.outcome === "sold" &&
            dictionary.sold[data.product.type]}
          {stage === "outcome_reason" &&
            history.outcome === "removed" &&
            dictionary.removed}
        </span>
        <div
          hidden={loading || stage === "thankyou"}
          className="grid grid-cols-12 gap-4 my-5"
        >
          <div className="col-span-3">
            <img
              className="block w-full h-full max-h-16 rounded-sm shadow-md object-cover object-center"
              src={`${config.s3.endpoint}${data.product.image_main}`}
              alt="Ваше объявление"
            />
          </div>
          <div className="col-span-9">
            <span className="block font-medium text-base text-black leading-tight">
              {data.product.title}
            </span>
            <span className="block text-base font-medium text-green-500">
              {new Intl.NumberFormat("ru-KZ", {
                style: "currency",
                currency: "kzt",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(data?.product?.price)}
              {data?.product?.price_max &&
                ` — ${new Intl.NumberFormat("ru-KZ", {
                  style: "currency",
                  currency: "kzt",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(data?.product?.price_max)}`}
            </span>
            <span className="text-xs text-neutral-400 block">
              {data.category.name} &bull; {data.city.name}
            </span>
          </div>
        </div>
        {stage === "outcome" && (
          <div className="mt-5">
            <Button
              onClick={() =>
                setHistory(
                  {
                    ...history,
                    outcome: "sold",
                    outcome_reason: "sold_sdelka",
                  },
                  setStage("thankyou")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Да, через Sdelka.kz
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome: "sold", outcome_reason: null },
                  setStage("outcome_reason")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Да, в другом месте
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome: "removed", outcome_reason: null },
                  setStage("outcome_reason")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Нет, не удалось
            </Button>
          </div>
        )}
        {stage === "outcome_reason" && history.outcome === "sold" && (
          <div className="mt-5">
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "sold_other_website" },
                  setStage("thankyou")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              {`${dictionary.reasonPrefix[data.product.type]} на другом сайте`}
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "sold_friends" },
                  setStage("thankyou")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              {`${dictionary.reasonPrefix[data.product.type]} через знакомых`}
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "sold_other" },
                  setShowFeedback(true)
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Укажите свой вариант
            </Button>
          </div>
        )}
        {stage === "outcome_reason" && history.outcome === "removed" && (
          <div className="mt-5">
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "removed_low_calls" },
                  setStage("thankyou")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Мало звонков
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "removed_changed_mind" },
                  setStage("thankyou")
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Передумал (-а) продавать
            </Button>
            <Button
              onClick={() =>
                setHistory(
                  { ...history, outcome_reason: "removed_other" },
                  setShowFeedback(true)
                )
              }
              className="my-1"
              size="large"
              block
              type="default"
            >
              Укажите свой вариант
            </Button>
          </div>
        )}
        {showFeedback && (
          <div hidden={loading || stage === "thankyou"} className="mt-2">
            <Input.TextArea
              className="w-full"
              rows={4}
              placeholder={
                history.outcome === "sold"
                  ? `Пожалуйста, расскажите где ${dictionary.title[
                      data.product.type
                    ].toLowerCase()}`
                  : "Пожалуйста, расскажите что могло пойти не так?"
              }
              onChange={(e) =>
                setHistory({ ...history, feedback: e.target.value })
              }
            />
            <Button
              onClick={() => setStage("thankyou")}
              block
              type="primary"
              className="mt-2"
              size="large"
            >
              Отправить
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
