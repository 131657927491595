import Sale from "./sale";
import Franchise from "./franchise";
import Investment from "./investment";

export default function getDetails(type) {
  switch (type) {
    case "sale":
      return Sale;
    case "franchise":
      return Franchise;
    case "investment":
      return Investment;
    default:
      return Sale;
  }
}

export { Sale, Franchise, Investment };
