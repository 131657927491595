import { Fragment } from "react";
import { Form, Input } from "antd";

export default function Title(props) {
  return (
    <Fragment {...props}>
      <Form.Item
        tooltip="Укажите название компании, можно без ТОО, ИП и т.п."
        label="Название компании"
        name="title"
        rules={[
          {
            required: true,
            min: 2,
            max: 45,
            message: "Название должно содержать от 2 до 45 символов.",
            whitespace: true,
          },
          // {
          //   validator: Products.validateTitle,
          // },
        ]}
        normalize={(value) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }}
      >
        <Input placeholder="Укажите название компании" />
      </Form.Item>
      <Form.Item
        tooltip="Укажите слоган, можно без ТОО, ИП и т.п."
        label="Слоган"
        name="subtitle"
        rules={[
          {
            required: false,
            min: 2,
            max: 45,
            message: "Слоган должен содержать от 2 до 45 символов.",
            whitespace: true,
          },
        ]}
        normalize={(value) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }}
      >
        <Input placeholder="Укажите слоган компании (необязательно)" />
      </Form.Item>
    </Fragment>
  );
}
