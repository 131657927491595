// import Promotions from "./promotions";
import * as API from "@api/customer";
import { useEffect, useState } from "react";
import Preview from "./preview";
import Details from "./details";
import Status from "./status";
import Activity from "./activity";

/**
 * Listing Item - component that renders listing item
 * @param {Object} data - listing data
 */
export default function ListingItem({ data, isApp = false }) {
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);

  const api =
    data?.product?.type === "franchise"
      ? API.Franchises
      : data?.product?.type === "investment"
      ? API.Investments
      : API.Sales;

  useEffect(() => {
    api.getOne({ id: data.product.id }).then((_res) => {
      setInfo(_res.data[data.product.type]);
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 flex justify-between items-center w-full">
        <Preview isApp={isApp} data={data} />
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Details isApp={isApp} data={data} />
      </div>
      <div className="col-span-12 lg:col-span-4">
        <Status isApp={isApp} loading={loading} info={info} data={data} />
      </div>
      <div className="col-span-12 lg:col-span-5">
        <Activity isApp={isApp} data={data} info={info} />
      </div>
    </div>
  );
}
