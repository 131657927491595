import { Offline } from "react-detect-offline";
import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import style from "./offline.module.scss";

export default function OfflineWidget(params) {
  return (
    <Offline>
      <div className={style.container}>
        <Alert
          className={style.msg}
          icon={<LoadingOutlined className="text-white" />}
          banner
          message={<span className="text-white font-semibold">Нет подключения к интернету</span>}
          description="Проверьте соединение с интернетом, прежде чем сохранять данные."
        />
      </div>
    </Offline>
  );
}
