import { useEffect, useState } from "react";
import { Card } from "antd";
import { Reports } from "@modules/moderator";
import { Loading } from "@components";
import classNames from "classnames";
import Head from "./head";
import Gallery from "./gallery";
import Files from "./files";
import Contacts from "./contacts";
import Options from "./options";
import Info from "./info";
import * as API from "@api/moderator";

export default function CompanyInfo({
  className,
  id = null,
  initialData = null,
}) {
  const [loading, setLoading] = useState(!initialData);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
    /* eslint-disable-next-line */
  }, [data]);

  const fetchData = () =>
    API.Companies.getOne({ id })
      .then((res) => setData(res.data))
      .then(() => setLoading(false));

  if (loading) return <Loading compact />;

  return (
    <div className={classNames("grid grid-cols-12 gap-4", className)}>
      <div className="col-span-12">
        <Head data={data} />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col gap-4">
        <Gallery data={data} />
        <Files data={data} />
        <Contacts data={data} />
        <Options data={data} />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-8 flex flex-col gap-4">
        <Info data={data} />
        <Card bordered={false} className="p-0" title="Жалобы">
          <Reports.List
            compact
            showHeader={false}
            showFilters={[]}
            defaultFilterValues={{
              type: "company",
              company_id: data?.company?.id,
            }}
          />
        </Card>
      </div>
    </div>
  );
}
