import { useEffect, useState } from "react";
import { message, Select, Spin, Form } from "antd";
import debounce from "debounce";
import * as API from "@api/moderator";

export default function ArticleSearch(params) {
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const data = params?.form?.getFieldValue("translated_article_id") || null;

    if (data) {
      return API.Blog.getArticle({
        id: data
      })
        .then(res => {
          setInitialData([res?.data] || []);
          setSelected(res?.data || null);
        })
        .finally(() => setLoading(false));
    }

    return setLoading(false);
  }, []);

  useEffect(() => {
    params.form.setFieldsValue({ translated_article_id: selected?.id });
  }, [selected]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    setOptions(
      initialData?.map(item => ({
        value: item.id,
        label: `ID ${item.id}: ${item.title}`,
        data: item
      }))
    );
  }, [initialData]);

  let fetchData = async value => {
    setOptions([]);
    setFetching(true);
    const data = await API.Blog.getArticles({
      limit: 10,
      q: value
    });

    if (!data || data?.error) {
      return message.error("Невозможно произвести поиск, проверьте интернет-соединение", 3);
    }

    setOptions(
      data?.data?.rows?.map(item => ({
        value: item.id,
        label: `ID ${item.id}: ${item.title}`,
        disabled: item.id === params.form.getFieldValue("id"),
        data: item
      }))
    );

    setFetching(false);
  };

  fetchData = debounce(fetchData, 200);

  if (loading) return null;

  return (
    <Form.Item {...params} className="w-full" label="Версия на другом языке">
      <Select
        onChange={(_, value) => {
          setSelected(value?.data);
        }}
        defaultValue={initialData?.map(item => item.id) || []}
        showSearch
        className="w-full"
        allowClear
        placeholder="Введите название материала, чтобы найти"
        filterOption={false}
        onSearch={fetchData}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
