import { Input } from "antd";

export default function Search({ onSearch, byId = false, ...props }) {
  return (
    <Input.Search
      {...props}
      addonBefore={byId ? "Поиск по ID" : "Поиск по названию"}
      allowClear
      onSearch={(v) => onSearch(byId ? "id" : "q", v)}
      enterButton="Найти"
      placeholder="Введите текст для поиска"
    />
  );
}
