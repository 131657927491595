import classNames from "classnames";
import { Steps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function StepItems({
  current = 0,
  isReviewing = false,
  formData,
  className = "sticky top-10",
  direction = "vertical",
  responsive = true,
  showDescription = true,
  showTitle = true,
}) {
  const { t } = useTranslation();

  const getTypeDescription = () => {
    switch (formData?.type) {
      case "sales":
        return t("listings.businessSale");

      case "franchise":
        return t("listings.franchiseSale");

      case "investment":
        return t("listings.startupSale");

      default:
        return t("listings.chooseType");
    }
  };

  return (
    <Steps
      className={classNames(className)}
      direction={direction}
      responsive={responsive}
      current={current}
    >
      <Steps.Step
        title={showTitle && t("listings.listingTypeTitle")}
        description={showDescription && getTypeDescription()}
      />
      <Steps.Step
        title={showTitle && t("listings.listingInfoTitle")}
        description={showDescription && t("listings.listingInfoText")}
      />
      <Steps.Step
        title={showTitle && t("listings.listingDetailsTitle")}
        description={showDescription && t("listings.listingDetailsText")}
      />
      <Steps.Step
        icon={isReviewing && <LoadingOutlined />}
        title={showTitle && t("listings.publishTitle")}
        description={
          showDescription
            ? isReviewing
              ? t("listings.inReviewTitle2")
              : t("listings.inReviewTitle3")
            : null
        }
      />
    </Steps>
  );
}
