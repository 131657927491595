import {
  PlusOutlined,
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useState, useEffect, Fragment } from "react";
import { Checkbox } from "antd";
import AddSocial from "./add";
import { getContactType } from "../dict";

import * as API from "@api/partner";
import { find, propEq, filter } from "ramda";

export default function Social({ form }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [socials, setSocials] = useState([]);
  const [selected, setSelected] = useState(
    form.getFieldValue("messengers") || []
  );

  useEffect(() => {
    form.setFieldsValue({ messengers: selected });
    /* eslint-disable-next-line */
  }, [selected]);

  useEffect(() => {
    API.CompanyContacts.getAll({ limit: 100 }).then((res) => {
      setSocials(filter((s) => s.type !== "phone", res?.data?.rows) || []);
    });
  }, []);

  const parseSocialUrl = (contact = "", prefix = "") => {
      try {
          const urlData = new URL(contact)
          return urlData.pathname.replaceAll("/", '')
      } catch (e) {
          return prefix + contact
      }
  }

  const getSelected = () => {
    if (socials?.length === 0) {
      return (
        <Fragment>
          <div className="w-full flex justify-center items-center gap-4 my-3">
            <FacebookOutlined className="text-3xl block text-neutral-300" />
            <InstagramOutlined className="text-3xl block text-neutral-300" />
            <TwitterOutlined className="text-3xl block text-neutral-300" />
          </div>
          <span className="block w-full text-center text-sm text-black mb-5">
            Добавьте ссылки на соц.сети.
          </span>
        </Fragment>
      );
    }

    return (
      <div className="my-5">
        {socials?.map((social, i) => (
          <Checkbox
            className="mb-2 last:mb-0 mx-0 w-full"
            onChange={() => {
              if (find(propEq("id", social?.id), selected)) {
                return setSelected(
                  selected.filter((item) => item.id !== social?.id)
                );
              }

              setSelected([...selected, social]);
            }}
            key={i}
            checked={find(propEq("id", social?.id), selected)}
          >
            <Tooltip title={getContactType(social).label}>
              <span className="flex justify-center items-center gap-x-2 m-0 leading-none">
                {getContactType(social).icon}
                {parseSocialUrl(social.contact, getContactType(social).prefix)}
              </span>
            </Tooltip>
          </Checkbox>
        ))}
      </div>
    );
  };

  const triggerModal = () => setModalOpen(!modalOpen);

  return (
    <div className="bg-neutral-100 p-5 rounded-md mt-5">
      <span className="block w-full text-center font-medium text-black text-base">
        Соц.сети
      </span>

      {getSelected()}

      <Button
        onClick={triggerModal}
        icon={<PlusOutlined />}
        block
        type="default"
      >
        Добавить соц.сеть
      </Button>
      <AddSocial
        socials={socials}
        onAfterAdd={(social) => {
          setSocials([...socials, social]);
          setSelected([...selected, social]);
        }}
        form={form}
        open={modalOpen}
        onCancel={triggerModal}
      />
    </div>
  );
}
