import { Card } from "antd";
import { Companies } from "@modules/moderator";
import Fade from "react-reveal/Fade";

export default function CompaniesPage() {
  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Компании</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card bordered={false} className="h-full">
              <Companies.List pageSize={10} />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
