import axios from "axios";
import config from "@config";
import * as Sentry from "@sentry/react";

const API = `${config.api}/customer/franchises`;

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getOne = (params) =>
  axios
    .get(`${API}/product_id/${params.id}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const create = async (data) =>
  axios
    .post(`${API}/`, data, {
      withCredentials: true,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error creating product: Franchises");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });

export const update = (data) =>
  axios
    .post(`${API}/product_id/${data.product.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error updating product: Franchises");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });

export const unpublish = (data) =>
  axios
    .post(`${API}/unpublish/product_id/${data.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error unpublishing product: Franchises");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });

export const review = (data) =>
  axios
    .post(`${API}/review/product_id/${data.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error sending product to review: Franchises");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });
