import List from "./list";
import Info from "./info";

const statusMap = status => {
  switch (status) {
    case "draft":
      return {
        badgeStatus: "default",
        badgeLabel: "Черновик"
      };

    case "published":
      return {
        badgeStatus: "success",
        badgeLabel: "На сайте"
      };

    case "unpublished":
      return {
        badgeStatus: "error",
        badgeLabel: "Снят с сайта"
      };

    case "in_review":
      return {
        badgeStatus: "warning",
        badgeLabel: "Требует модерации"
      };

    case "declined":
      return {
        badgeStatus: "error",
        badgeLabel: "Отклонено"
      };

    case "removed":
      return {
        badgeStatus: "error",
        badgeLabel: "Удалено"
      };

    default:
      return {
        badgeStatus: "default",
        badgeLabel: "Черновик"
      };
  }
};

export { List, Info, statusMap };
