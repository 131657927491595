import { Card } from "antd";

export default function Details({ data }) {
  const details = data[data?.product?.type];

  return (
    <Card bordered={false} title="Подробная информация">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Месячная выручка
          </span>
          <span className="block text-base font-normal text-slate-800">
            {Intl.NumberFormat("ru-KZ", {
              currency: "kzt",
              style: "currency",
              maximumFractionDigits: 0,
            }).format(details.month_revenue)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Месячные расходы
          </span>
          <span className="block text-base font-normal text-slate-800">
            {Intl.NumberFormat("ru-KZ", {
              currency: "kzt",
              style: "currency",
              maximumFractionDigits: 0,
            }).format(details.month_costs)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Месячная прибыль
          </span>
          <span className="block text-base font-normal text-slate-800">
            {Intl.NumberFormat("ru-KZ", {
              currency: "kzt",
              style: "currency",
              maximumFractionDigits: 0,
            }).format(details.month_profit)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Окупаемость (мес.)
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.payback_period}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Форма организации
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.org_type?.name}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Год основания
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.foundation_year}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Кол-во сотрудников
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.staff_qty}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            БИН
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.bin === "000000000000" ? "Не указан" : details.bin}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Доля продажи
          </span>
          <span className="block text-base font-semibold text-primary">
            {details.sell_percentage}%
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Бизнес активен?
          </span>
          <span className="block text-base font-normal text-primary">
            {details.is_active ? (
              "Да"
            ) : (
              <i className="not-italic text-red-600">Нет</i>
            )}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Подтверждение доходов
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details?.revenue_confirmation?.join(", ")}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Причина продажи
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details?.sell_reason ? details.sell_reason : "Не указана"}
          </span>
        </div>
      </div>
    </Card>
  );
}
