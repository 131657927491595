import { Card, Segmented, Button } from "antd";
import { Listings, Analytics, Users } from "@modules/moderator";
import { useState } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function Dashboard() {
  const [productType, setProductType] = useState("sale");

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Сводка данных</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Новые объявления"
            >
              <Analytics.Cards.Products title="С начала месяца" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Оборот платежей (онлайн)"
            >
              <Analytics.Cards.Payments title="С начала месяца" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Новые пользователи"
            >
              <Analytics.Cards.Users title="С начала месяца" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Card
              className="overflow-scroll"
              bordered={false}
              title="Новые компании"
            >
              <Analytics.Cards.Companies title="С начала месяца" />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Card
              title="Объявления"
              className="h-full"
              extra={
                <Link to="/listings">
                  <Button>Все объявления</Button>
                </Link>
              }
            >
              <Segmented
                className="mb-3"
                block
                onChange={(type) => setProductType(type)}
                options={[
                  { label: "Бизнес", value: "sale" },
                  { label: "Франшизы", value: "franchise" },
                  { label: "Инвестиции", value: "investment" },
                ]}
              />
              <Listings.List
                pageSize={5}
                showPagination={false}
                showHeader={false}
                showFilters={[]}
                type={productType}
                compact
              />
            </Card>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Card
              title="Пользователи"
              className="h-full"
              extra={
                <Link to="/users">
                  <Button>Все пользователи</Button>
                </Link>
              }
            >
              <Users.List
                params={{ order: { last_login_at: "desc" }, limit: 10 }}
              />
            </Card>
          </div>
          <div className="col-span-12">
            <Card title="Снятия объявлений" className="h-full">
              <Listings.Feedback />
            </Card>
          </div>
        </div>
      </Fade>
    </div>
  );
}
