import axios from "axios";
import config from "@config";

const API = `${config.api}/moderator/analytics`;

export const getProducts = (params) =>
  axios
    .get(`${API}/products`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getCompanies = (params) =>
  axios
    .get(`${API}/companies`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getUsers = (params) =>
  axios
    .get(`${API}/users`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getPushNotifications = (params) =>
  axios
    .get(`${API}/push-notifications`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getContacts = (params) =>
  axios
    .get(`${API}/contacts`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getFavorites = (params) =>
  axios
    .get(`${API}/favorites`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getPayments = (params) =>
  axios
    .get(`${API}/payments`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
export const getProductContactsByUser = (data) =>
    axios
        .get(`${API}/product-contacts/user_id/${data.userId}`, {
            params: data.params,
            withCredentials: true,
        })
        .then((res) => res.data)
        .catch((err) => ({
            error: true,
            message: err.message || err?.response?.data || "Ошибка сервера",
        }));

export const getProductContactsByProductId = (data) =>
    axios
        .get(`${API}/product-contacts/product_id/${data.productId}`, {
            params: data.params,
            withCredentials: true,
        })
        .then((res) => res.data)
        .catch((err) => ({
            error: true,
            message: err.message || err?.response?.data || "Ошибка сервера",
        }));