import TariffCard from "./card";
import { Button } from "antd";
import { useState, useEffect } from "react";
import classNames from "classnames";

export default function AdditionalTariffs({ data, listing, className }) {
  const [collection, setCollection] = useState({});

  useEffect(() => {
    const results = data.tariffs.reduce(function (results, tariff) {
      (results[tariff.service_code] = results[tariff.service_code] || []).push(
        tariff
      );
      return results;
    }, {});

    setCollection(results);
  }, [data]);

  const getTariffs = () => {
    const results = [];

    /* eslint-disable-next-line */
    for (const [_, value] of Object.entries(collection)) {
      results.push(value);
    }

    return results.map((tariff) => (
      <TariffCard listing={listing} key={tariff.id} data={tariff} />
    ));
  };

  return (
    <div className={classNames("mt-5", className)}>
      <span className="text-2xl font-semibold block text-neutral-800">
        Выбрать способ продвижения вручную
        {getTariffs()}
      </span>
      <Button
        onClick={() => {
          window.history.back();
        }}
      >
        Вернуться назад
      </Button>
    </div>
  );
}
