import { Button, Card, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ExternalPromotions } from "@modules/moderator";
import { useState } from "react";
import Fade from "react-reveal/Fade";

export default function PromotionsPage() {
  const [newPromotion, setNewPromotion] = useState(false);

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Внешние продвижения</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card
              bordered={false}
              className="h-full"
              extra={
                <Button
                  onClick={() => setNewPromotion(true)}
                  icon={<PlusOutlined />}
                >
                  Новое продвижение
                </Button>
              }
            >
              <ExternalPromotions.List />
            </Card>
          </div>
        </div>
      </Fade>
      <Modal
        footer={null}
        destroyOnClose
        onCancel={() => setNewPromotion(false)}
        open={newPromotion}
        title="Новое продвижение"
      >
        <ExternalPromotions.New />
      </Modal>
    </div>
  );
}
