import { Card, Form, Input, Button, Divider, Radio, Checkbox } from "antd";
import { LoginButton } from "@components";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "@contexts";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from "./form.module.scss";
import classNames from "classnames";
import config from "@config";

export default function RegisterForm({ className }) {
  const {
    loading,
    register,
    signInWithGoogle,
    currentUser,
    isVerified,
    signInWithApple,
  } = useAuth();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <Card
      hidden={currentUser && !isVerified}
      className={classNames(style.login, className)}
    >
      <span className="text-lg font-semibold text-black block mb-3">
        {t("login.registration")}
      </span>
      <Form
        tabIndex={1}
        className="login-form !mb-0"
        name="signin"
        size="large"
        initialValues={{ role: searchParams.get("role") || "customer" }}
        onFinish={register}
      >
        {config?.allowPartnerRegistration && (
          <Form.Item name="role">
            <Radio.Group className={style.radioGroup}>
              <Radio value="customer" className={style.radio}>
                <span className="leading-none text-base block font-medium text-black">
                  {t("login.sellerBuyer")}
                </span>
                <span className="block mt-2 font-normal text-base text-black leading-snug">
                  {t("login.sellerBuyerInfo")}
                </span>
              </Radio>
              <Radio value="partner" className={style.radio}>
                <span className="leading-none text-base block font-medium text-black">
                  {t("login.partner")}
                </span>
                <span className="block mt-2 font-normal text-base text-black leading-snug">
                  {t("login.partnerInfo")}
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {config?.allowPartnerRegistration && <Divider />}
        <LoginButton.Google
          title={t("login.google")}
          loading={loading}
          onClick={signInWithGoogle}
        />
        <LoginButton.Apple
          title={t("login.apple")}
          loading={loading}
          onClick={signInWithApple}
        />
        <Divider style={{ fontSize: 10 }}>{t("login.or")}</Divider>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: t("login.nameRequired"),
            },
          ]}
        >
          <Input
            disabled={loading}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("login.name")}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("login.emailRequired"),
            },
            {
              type: "email",
              validateTrigger: "onSubmit",
              message: t("login.emailInvalid"),
            },
          ]}
        >
          <Input
            autoComplete="off"
            autoCapitalize="false"
            disabled={loading}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder={t("login.email")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              min: 6,
              required: true,
              message: t("login.passRequired"),
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder={t("login.pass")}
            autoComplete="new-password"
            disabled={loading}
          />
        </Form.Item>
        <Form.Item
          style={{ marginTop: 15, marginBottom: 15 }}
          name="agreement"
          valuePropName="checked"
          initialValue={true}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error(t("login.consetPrompt"))),
            },
          ]}
        >
          <Checkbox>
            Принимаю{" "}
            <a
              target="_blank"
              href={`${config.frontend}/terms`}
              rel="noreferrer"
            >
              Пользовательское Соглашение
            </a>{" "}
            и{" "}
            <a
              target="_blank"
              href={`${config.frontend}/privacy`}
              rel="noreferrer"
            >
              Политику Конфиденциальности
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="admin-login-form-button"
            loading={loading}
            block
          >
            {t("login.registerConfirm")}
          </Button>
        </Form.Item>
        <Form.Item className="!mb-0" style={{ fontSize: 15 }}>
          <div className="flex justify-between items-center w-full text-base">
            <span>
              {t("login.haveAccount")}{" "}
              <Link to={`${config.basepath}/login`}>{t("login.enter")}</Link>
            </span>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
