import * as Sales from "./sales";
import * as Franchises from "./franchises";
import * as Investments from "./investments";
import * as Contacts from "./contacts";
import * as Notifications from "./notifications";
import * as Favorites from "./favorites";
import * as Payments from "./payments";
import axios from "axios";
import qs from "qs";

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

export {
  Sales,
  Franchises,
  Investments,
  Contacts,
  Notifications,
  Favorites,
  Payments,
};
