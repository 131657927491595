import { Blog } from "@modules/moderator";
import { OfflineWidget } from "@modules";
import { useParams } from "react-router-dom";

export default function EditPage() {
  const { id } = useParams();

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Редактировать рубрику</h2>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Blog.Categories.Edit id={id} />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
