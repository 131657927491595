import { Modal, message, Select, Input } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useState } from "react";
import { find } from "ramda";
import PhoneInput from "react-phone-input-2";
import locale from "react-phone-input-2/lang/ru.json";
import * as API from "@api/partner";
import "react-phone-input-2/lib/style.css";

export default function Add({
  onCancel,
  open = false,
  onAfterAdd,
  socials = [],
}) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [value, setValue] = useState(null);

  const onSubmit = () => {
    const contact = type === "phone" ? `+${value}` : value;

    if (
      find(
        (social) => social?.type === type && social?.contact === contact,
        socials
      )
    ) {
      return message.error("Такая соц.сеть уже добавлена.", 3);
    }

    setLoading(true);

    API.CompanyContacts.create({
      contact,
      type,
    }).then((res) => {
      setLoading(false);
      if (res?.error || !res?.data?.id) {
        return message.error(
          "Не удалось добавить соц.сеть, проверьте соединение с интернетом и попробуйте еще раз.",
          3
        );
      }

      message.success(`Ссылка на соц.сеть успешно добавлена.`, 2);
      onAfterAdd({
        id: res.data.id,
        contact: res.data.contact,
        type: res.data.type,
      });
      onCancel();
    });
  };

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      title="Добавить соц.сеть компании"
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{
        type: "primary",
        block: true,
        className: "m-0 mt-2",
        loading,
      }}
      okText="Добавить"
      width={350}
      onOk={onSubmit}
      centered
    >
      <Select
        className="w-full mt-3 mb-2"
        onChange={setType}
        placeholder="Выберите тип соц.сети"
        value={type}
      >
        <Select.Option value="whatsapp">WhatsApp</Select.Option>
        <Select.Option value="telegram">Telegram</Select.Option>
        <Select.Option value="instagram">Instagram</Select.Option>
        <Select.Option value="facebook">Facebook</Select.Option>
      </Select>
      {type === "whatsapp" && (
        <PhoneInput
          disabled={loading}
          className="w-full"
          inputClass="!w-full"
          localization={locale}
          country="kz"
          value={value}
          onChange={setValue}
          preferredCountries={[
            "kz",
            "ru",
            "ua",
            "az",
            "am",
            "kg",
            "tj",
            "tm",
            "uz",
          ]}
          priority={{ kz: 0, ru: 1 }}
          placeholder="Укажите номер WhatsApp"
        />
      )}
      {type === "facebook" && (
        <Input
          value={value}
          onChange={(v) => setValue(v.target.value)}
          addonBefore={<LinkOutlined />}
          type="url"
          placeholder="Ссылка на Facebook"
        />
      )}
      {type === "instagram" && (
        <Input
          value={value}
          onChange={(v) => setValue(v.target.value)}
          addonBefore="@"
          type="url"
          placeholder="Имя пользователя Instagram"
        />
      )}
      {type === "telegram" && (
        <Input
          value={value}
          onChange={(v) => setValue(v.target.value)}
          addonBefore="@"
          placeholder="Имя пользователя Telegram"
        />
      )}
    </Modal>
  );
}
