import * as API from "@api/customer";

export default async function PageLoader() {
  const _res = await API.Favorites.getAll({
    limit: 9,
    offset: 0,
  });

  return {
    data: _res.data.rows,
    count: _res.data.count,
  };
}
