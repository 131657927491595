import { useEffect, useState } from "react";
import Name from "./name";
import NameKk from "./name_kk";
import NameEn from "./name_en";
import Slug from "./slug";
import Parent from "./parent";
import Color from "./color";
import * as API from "@api/moderator";

import { Divider, Tag } from "antd";

export default function Details(props) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    API.Blog.getLocales().then(res => setLocales(res?.data?.rows?.filter(locale => locale?.code !== "ru")));
  }, []);

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Tag>ID {props?.form?.getFieldValue("id")}</Tag>
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <NameKk {...props} />
      </div>
      <div className="col-span-12">
        <NameEn {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Color {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <Parent {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
    </div>
  );
}
