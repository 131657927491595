import { Card } from "antd";

export default function Details({ data }) {
  const details = data[data?.product?.type];

  return (
    <Card bordered={false} title="Подробная информация">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Мин. ежегодный прогнозируемый доход
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.year_profit_rate_min}%
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Макс. ежегодный прогнозируемый доход
          </span>
          <span className="block text-base font-normal text-slate-800">
            {details.year_profit_rate_max}%
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Текущая стадия
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data.investment.stage === "idea" && "Есть идея"}
            {data.investment.stage === "team" &&
              "Есть команда, продукт и/или прототип"}
            {data.investment.stage === "operating_w_profit" &&
              "Работающий с прибылью"}
            {data.investment.stage === "operating_wo_profit" &&
              "Работающий без прибыли"}
          </span>
        </div>
      </div>
    </Card>
  );
}
