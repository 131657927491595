import { Notifications } from "@modules/partner";
import { useLoaderData } from "react-router-dom";

export default function NotificationsPage() {
  const initialData = useLoaderData();
  return (
    <div className="my-5">
      <h2 className="mb-5">Уведомления</h2>
      <Notifications initialData={initialData} />
    </div>
  );
}
