import { Select } from "antd";

export default function Email(params) {
  return (
    <Select
      className="w-full"
      placeholder="Почта подтверждена"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("email_confirmed", v)}
    >
      {params.data.map((data) => (
        <Select.Option key={data.id} value={data.id}>
          {data.name}
        </Select.Option>
      ))}
    </Select>
  );
}
