import { Settings } from "@modules/customer";

export default function SettingsPage() {
  return (
    <div>
      <Settings.Avatar />
      <div className="grid grid-cols-12 gap-6 mb-10 lg:mb-20">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Settings.Details />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Settings.Password />
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <Settings.Notifications />
        </div>
      </div>
    </div>
  );
}
