import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RoutesHandler from "@utils/routes";
import * as Pages from "@pages";
import "./i18n";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Pages.Login />,
    },
    {
      path: "/register",
      element: <Pages.Register />,
    },
    {
      path: "/confirm",
      element: <Pages.Confirm />,
    },
    {
      path: "/logout",
      element: <Pages.Logout />,
    },
    ...RoutesHandler(),
    {
      path: "/404",
      element: <Pages.NotFound />,
    },
    {
      path: "*",
      element: <Pages.NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
}
