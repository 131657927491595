import { useState, useEffect } from "react";
import { Card, Divider, message, Button } from "antd";
import classNames from "classnames";
import * as API from "@api";
import * as PartnerAPI from "@api/partner";
import Logo from "./logo";
import Title from "./title";
import Details from "./details";
import Locations from "./locations";
import Contacts from "./contacts";

export default function Info({ className, ...props }) {
  const [dict, setDict] = useState({ countries: [], cities: [] });

  useEffect(() => {
    Promise.all([
      API.Countries.getAll({ limit: 1000, filter: { id: 1 } }),
      API.Cities.getAll({ limit: 1000, filter: { country_id: 1 } }),
      PartnerAPI.CompanyCategories.getAll({ limit: 1000 }),
    ]).then(([countries, cities, categories]) => {
      if (countries?.error || cities?.error) {
        return message.error(
          "Ошибка загрузки данных. Проверьте соединение с интернетом.",
          2
        );
      }

      setDict({
        countries: countries?.data?.rows?.map((data) => ({
          ...data,
          label: data?.name,
          value: data?.id,
        })),
        cities: cities?.data?.rows?.map((data) => ({
          ...data,
          label: data?.name,
          value: data?.id,
        })),
        categories: categories?.data?.rows?.map((data) => ({
          ...data,
          label: data?.name,
          value: data?.id,
        })),
      });
    });
  }, []);

  const onNext = () => {
    props.form
      .validateFields([
        "title",
        "category_id",
        "description",
        "countries",
        "cities",
      ])
      .then(() => {
        if (!props.form.getFieldValue("logo")) {
          throw Error("Необходимо добавить логотип компании");
        }
        if (props.form.getFieldValue("phones")?.length === 0) {
          throw Error("Добавьте хотя бы один номер телефона");
        }
      })
      .then(props.onNext)
      .catch((error) =>
        message.error(
          error?.message ||
            "Пожалуйста, заполните необходимые поля, чтобы продолжить.",
          2
        )
      );
  };

  return (
    <div
      className={classNames("grid grid-cols-12 gap-4", {
        hidden: !props?.visible,
      })}
    >
      <div className="col-span-12 lg:col-span-8">
        <Card title="1. Моя компания">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <Logo {...props} />
            </div>
            <div className="col-span-12">
              <Title {...props} />
            </div>
            <div className="col-span-12">
              <Divider className="my-0" />
            </div>
            <div className="col-span-12">
              <Details {...props} dict={dict} />
            </div>
            <div className="col-span-12">
              <Divider className="my-0">Страны и города присутствия</Divider>
              <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm my-2">
                Укажите в каких странах и городах вы предоставляете услуги.
              </span>
            </div>
            <Locations {...props} dict={dict} />
          </div>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <Card title="2. Контакты и соц.сети">
          <Contacts {...props} />
          <Button onClick={onNext} block type="primary" className="mt-5">
            Далее
          </Button>
        </Card>
      </div>
    </div>
  );
}
