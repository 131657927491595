import { Input } from "antd";

export default function Search({ onChange }) {
  return (
    <Input.Search
      placeholder="Поиск по ID объявления"
      allowClear
      onSearch={(v) => onChange("product_id", v)}
      enterButton="Найти"
    />
  );
}
