import { Breadcrumb } from "@components";
import { Listings } from "@modules/customer";
import { useLoaderData } from "react-router-dom";

export default function EditListing() {
  const data = useLoaderData();

  // Prepare object format for Listing Edit module
  const product = {
    ...data?.product,
    description: data?.[data?.product?.type]?.description,
    [data?.product?.type]: data?.[data?.product?.type],
    category_id: [data?.product?.category_id],
    options: data?.product?.options?.map(JSON.stringify),
  };

  return (
    <div className="my-5">
      <Breadcrumb
        showBack
        data={[
          { title: "Объявления", href: "/listings" },
          {
            title: "Данные объявления",
            href: `/listings/view/${data?.product?.type}/${data?.product?.id}`,
          },
          {
            title: "Редактирование объявления",
            href: `/listings/edit/${data?.product?.type}/${data?.product?.id}`,
          },
        ]}
      />
      <h2 className="mb-5 lg:mb-10">Редактирование объявления</h2>
      <Listings.Form.Edit data={product} />
    </div>
  );
}
