import { Tooltip } from "antd";
import {
  CheckCircleFilled,
  LoadingOutlined,
  InfoCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useLocale } from "@contexts/locale";
import { useTranslation } from "react-i18next";
import { or } from "ramda";

export default function Status({ data, info, loading }) {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const extraInfoChecker = () => {
    if (loading) {
      return (
        <span className="block text-base text-neutral-400">
          <LoadingOutlined />
        </span>
      );
    }

    switch (data.product.type) {
      case "sale":
        if (
          or(
            info.month_revenue === 0,
            info.month_costs === 0,
            info.month_profit === 0,
            info.payback_period === 0,
            info.bin === "000000000000"
          )
        ) {
          return (
            <span className="block text-base text-black">
              {t("listings.recommendToFill")}
              <Tooltip title={t("listings.recommendToFillExtraText")}>
                <InfoCircleFilled className="ml-2 text-xs text-orange-400" />
              </Tooltip>
            </span>
          );
        }
        return (
          <span className="block text-base text-black">
            {t("listings.handed")}
            <CheckCircleFilled className="ml-2 text-xs text-green-500" />
          </span>
        );

      default:
        return (
          <span className="block text-base text-black">
            {t("listings.handed")}
            <CheckCircleFilled className="ml-2 text-xs text-green-500" />
          </span>
        );
    }
  };

  const status = () => {
    switch (data?.product?.status) {
      case "in_review":
        return (
          <span className="block text-base text-black">
            {t("listings.inReviewTitle")}
            <Tooltip title={t("listings.inReviewText")}>
              <ClockCircleFilled className="ml-2 text-xs text-neutral-400" />
            </Tooltip>
          </span>
        );

      case "published":
        return (
          <span className="block text-base text-black">
            {t("listings.publishedTitle")}
            <Tooltip title={t("listings.publishedText")}>
              <CheckCircleFilled className="ml-2 text-xs text-green-500" />
            </Tooltip>
          </span>
        );

      case "rejected":
      case "declined":
        return (
          <span className="block text-base text-black">
            {t("listings.rejectedTitle")}
            <Tooltip title={t("listings.rejectedText")}>
              <CloseCircleFilled className="ml-2 text-xs text-red-600" />
            </Tooltip>
          </span>
        );

      case "archived":
      case "unpublished":
        return (
          <span className="block text-base text-black">
            {t("listings.unpublishedTitle")}
            <Tooltip title={t("listings.unpublishedText")}>
              <CloseCircleFilled className="ml-1 text-xs text-neutral-400" />
            </Tooltip>
          </span>
        );

      case "draft":
        return (
          <span className="block text-base text-black">
            {t("listings.draft")}
          </span>
        );

      default:
        break;
    }
  };

  return (
    <div className="border border-solid border-neutral-200 h-full rounded-md p-4">
      <div className="mb-3 w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.sum")}
        </span>
        <span className="block text-base font-normal text-green-500">
          {new Intl.NumberFormat("ru-KZ", {
            style: "currency",
            currency: "kzt",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(data?.product?.price)}
          {data?.product?.price_max &&
            ` — ${new Intl.NumberFormat("ru-KZ", {
              style: "currency",
              currency: "kzt",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(data?.product?.price_max)}`}
        </span>
      </div>
      <div className="mb-3 w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.categoryCity")}
        </span>
        <span className="block text-base text-black">
          {locale === "kk" ? data?.category?.name_kk : data.category.name}{" "}
          &bull; {locale === "kk" ? data?.city?.name_kk : data.city.name}
        </span>
      </div>
      <div className="mb-3 w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.mainDetails")}
        </span>
        <span className="block text-base text-black">
          {t("listings.handed")}{" "}
          <CheckCircleFilled className="ml-1 text-xs text-green-500" />
        </span>
      </div>
      <div className="mb-3 w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.extraDetails")}
        </span>
        {extraInfoChecker()}
      </div>
      <div className="w-full">
        <span className="text-xs leading-none text-neutral-400">
          {t("listings.listingStatus")}
        </span>
        {status()}
      </div>
    </div>
  );
}
