import icon from "./icons/logo-google";

export default function Google({
  title = "Войти с помощью Google",
  hidden = false,
  loading = false,
  onClick = null,
}) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      hidden={hidden}
      title={title}
      disabled={loading}
      className="social-login-btn google"
    >
      {icon} {title}
    </button>
  );
}
