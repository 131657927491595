import ImgCrop from "antd-img-crop";
import { Button, Divider, Upload, message } from "antd";
import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Imgproxy } from "@utils";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";
import * as API from "@api";

export default function Logo({ form }) {
  const type = form.getFieldValue("type");
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(form.getFieldValue([type, "logo"]) || null);
  const { t } = useTranslation();

  useEffect(() => {
    if (type) {
      form.setFieldValue([type, "logo"], logo);
    }
    /* eslint-disable-next-line */
  }, [logo]);

  if (type === "sale") {
    return null;
  }

  return (
    <div>
      <Divider>{t("listings.logoTitle")}</Divider>
      <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-2">
        {t("listings.logoTooltip")}
      </span>
      <div className="flex flex-col justify-center items-center my-10">
        <ImgCrop
          beforeCrop={(file) => {
            if (file.size > 3 * 1024 * 1024) {
              message.error(t("listings.logoMaxSizeError"), 2);
              return false;
            }

            return true;
          }}
          grid
          rotate
          modalTitle={t("listings.logoAdjust")}
        >
          <Upload
            disabled={loading}
            multiple={false}
            name="img"
            accept="image/jpeg"
            showUploadList={false}
            customRequest={async ({ onSuccess, onError, file }) => {
              try {
                setLoading(true);

                const _res = await API.Static.uploadFile({
                  file,
                  id: currentUser.id,
                });

                if (!_res.data || _res.error) {
                  throw new Error();
                }

                setLogo(_res?.data?.path);
                setLoading(false);
                onSuccess();
              } catch (error) {
                onError();
                setLoading(false);
                return message.error(t("generic.serverError"), 2);
              }
            }}
          >
            <div className="flex flex-col justify-center items-center">
              <img
                alt="Логотип"
                src={
                  logo
                    ? Imgproxy.getURL(logo, { width: 180, height: 180 })
                    : "/img/logo-placeholder.svg"
                }
                className="aspect-square w-32 h-32 object-contain object-center bg-neutral-100 border border-neutral-200 border-solid rounded-md overflow-hidden cursor-pointer"
              />
              <Button
                loading={loading}
                icon={<UploadOutlined />}
                type="link"
                className="m-0 w-fit p-0 h-fit mx-auto mt-2"
              >
                {logo ? t("generic.change") : t("generic.upload")}
              </Button>
            </div>
          </Upload>
        </ImgCrop>
      </div>
    </div>
  );
}
