import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function Search(params) {
  return (
    <Input.Search
      prefix={<SearchOutlined />}
      placeholder={params.title || "Поиск"}
      enterButton="Найти"
      allowClear
      onSearch={params.onChange}
    />
  );
}
