import { useEffect, useState } from "react";
import { Table, Tag, Button, message, Modal } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import View from "../view";
// import { services } from "./filters/services";
import * as API from "@api/moderator";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

export default function Payments({ showHeader = true, compact = false, pageSize = 10, showPagination = true }) {
  const [loading, setLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: total => `Найдено: ${total}`
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, []);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Payments.getAll({
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Ошибка, попробуйте еще раз");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: total => `Найдено: ${total}`
    });
    setLoading(false);
  }

  const columns = [
    {
      title: "Услуга",
      dataIndex: "pg_description",
      key: "pg_description",
      align: "left",
      render: (data, all) =>
        data ? (
          <div>
            <span className="block text-base font-medium text-black">{data}</span>
            <span className="block text-sm text-primary leading-tight">
              <Link to={`/users/view/${all.user_id}`}>{all.pg_user_contact_email}</Link>
            </span>
          </div>
        ) : (
          <span className="block text-base font-medium text-black">Индивидуальные услуги</span>
        )
    },
    {
      title: "Сумма",
      dataIndex: "total_payments",
      key: "total_payments",
      align: "center",
      render: data => (
        <div>
          {new Intl.NumberFormat(
            "ru-KZ",
            {
              style: "currency",
              currency: "KZT"
            },
            { maximumFractionDigits: 2, minimumFractionDigits: 2 }
          ).format(parseFloat(data))}
        </div>
      )
    },
    {
      title: "Вид оплаты",
      dataIndex: "payment_source",
      key: "payment_source",
      align: "center",
      render: data => <div>{data === "paybox" ? <Tag color="green">Онлайн (Paybox)</Tag> : <Tag>Другой (Безнал, Касса)</Tag>}</div>
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: data => (
        <div className="flex flex-col">
          <span className="m-0 p-0 text-sm font-medium text-black">{dayjs(data).fromNow()}</span>
          <span className="m-0 p-0 text-sm text-neutral-500">{dayjs(data).format("HH:mm DD.MM.YYYY")}</span>
        </div>
      )
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, data) => (
        <Button onClick={() => setTransactionId(data.transaction_id)} icon={<EyeOutlined />}>
          Открыть
        </Button>
      )
    }
  ];

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={compact ? columns.filter(col => col.key !== "image_main" && col.key !== "actions", columns) : columns}
        />
      </div>
      <Modal
        okButtonProps={{ hidden: true }}
        cancelText="Закрыть"
        width={970}
        onCancel={() => setTransactionId(null)}
        open={!!transactionId}
        title="Информация о платеже"
      >
        {transactionId && <View transactionId={transactionId} />}
      </Modal>
    </div>
  );
}
