import axios from "axios";
import config from "@config";
import * as Sentry from "@sentry/react";

const API = `${config.api}/moderator/products`;

export const getAll = async (params) =>
  axios
    .get(
      `${API}/`,
      { params },
      {
        withCredentials: true,
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error getting all products");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });

export const getFeedbacks = async (params) =>
  axios
    .get(
      `${API}/feedback`,
      { params },
      {
        withCredentials: true,
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      Sentry.captureMessage("Error getting all products");

      return {
        error: true,
        message: err.message || err?.response?.data || "Ошибка сервера",
      };
    });
