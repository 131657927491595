import { Listings } from "@modules/customer";

export default function NewListing({ isApp = false }) {
  return (
    <div className="my-5">
      <h2 className="mb-5 lg:mb-10">Новое объявление</h2>
      <Listings.Form.New isApp={isApp} />
    </div>
  );
}
