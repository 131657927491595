import * as Pages from "@pages/customer";
import * as Loaders from "./loaders";
import Layout from "@modules/layouts/customer";
import AppLayout from "@modules/layouts/app";

const routes = [
  {
    path: "/app/payments",
    element: (
      <AppLayout page="payments">
        <Pages.Payments isApp />
      </AppLayout>
    ),
    loader: Loaders.Payments,
  },
  {
    path: "/app/companies/*",
    element: (
      <AppLayout page="companies-banner">
        <Pages.Companies.Banner isApp />
      </AppLayout>
    ),
  },
  {
    path: "/companies/*",
    element: (
      <Layout page="companies-banner">
        <Pages.Companies.Banner />
      </Layout>
    ),
  },
  {
    path: "/app/listings/investment/edit/:id",
    element: (
      <AppLayout page="settings">
        <Pages.Listings.Edit isApp />
      </AppLayout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "investment" }),
  },
  {
    path: "/app/listings/franchise/edit/:id",
    element: (
      <AppLayout page="settings">
        <Pages.Listings.Edit isApp />
      </AppLayout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "franchise" }),
  },
  {
    path: "/app/listings/sale/edit/:id",
    element: (
      <AppLayout page="settings">
        <Pages.Listings.Edit isApp />
      </AppLayout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "sale" }),
  },
  {
    path: "/app/listings/new",
    element: (
      <AppLayout page="listings">
        <Pages.Listings.New isApp />
      </AppLayout>
    ),
  },
  {
    path: "/app/listings/all",
    element: (
      <AppLayout>
        <Pages.Dashboard isApp />
      </AppLayout>
    ),
    loader: Loaders.Dashboard,
  },
  {
    path: "/app/listings",
    element: (
      <AppLayout>
        <Pages.Dashboard isApp />
      </AppLayout>
    ),
    loader: Loaders.Dashboard,
  },
  {
    path: "/app",
    element: (
      <AppLayout>
        <Pages.Dashboard isApp />
      </AppLayout>
    ),
    loader: Loaders.Dashboard,
  },
  {
    path: "/payments",
    element: (
      <Layout page="payments">
        <Pages.Payments />
      </Layout>
    ),
    loader: Loaders.Payments,
  },
  {
    path: "/promote/success",
    element: (
      <Layout className="promote-page" page="promote">
        <Pages.Promote.Success />
      </Layout>
    ),
  },
  {
    path: "/promote/error",
    element: (
      <Layout className="promote-page" page="promote">
        <Pages.Promote.Error />
      </Layout>
    ),
  },
  {
    path: "/promote/:type/:id",
    element: (
      <Layout className="promote-page" page="promote">
        <Pages.Promote.View />
      </Layout>
    ),
    loader: async ({ params }) => Loaders.Promote.getListingById(params),
  },
  {
    path: "/promote/additional/:type/:id",
    element: (
      <Layout className="promote-page" page="promote">
        <Pages.Promote.Additional />
      </Layout>
    ),
    loader: async ({ params }) => Loaders.Promote.getListingById(params),
  },
  {
    path: "/app/promote/success",
    element: (
      <AppLayout className="promote-page" page="promote">
        <Pages.Promote.Success isApp />
      </AppLayout>
    ),
  },
  {
    path: "/app/promote/error",
    element: (
      <AppLayout className="promote-page" page="promote">
        <Pages.Promote.Error isApp />
      </AppLayout>
    ),
  },
  {
    path: "/app/promote/:type/:id",
    element: (
      <AppLayout className="promote-page" page="promote">
        <Pages.Promote.View isApp />
      </AppLayout>
    ),
    loader: async ({ params }) => Loaders.Promote.getListingById(params),
  },
  {
    path: "/app/promote/additional/:type/:id",
    element: (
      <AppLayout className="promote-page" page="promote">
        <Pages.Promote.Additional isApp />
      </AppLayout>
    ),
    loader: async ({ params }) => Loaders.Promote.getListingById(params),
  },
  {
    path: "/favorites",
    element: (
      <Layout page="favorites">
        <Pages.Favorites />
      </Layout>
    ),
    loader: Loaders.Favorites,
  },
  {
    path: "/notifications",
    element: (
      <Layout page="notifications">
        <Pages.Notifications />
      </Layout>
    ),
    loader: Loaders.Notifications,
  },
  {
    path: "/settings",
    element: (
      <Layout page="settings">
        <Pages.Settings />
      </Layout>
    ),
  },
  {
    path: "/listings/investment/edit/:id",
    element: (
      <Layout page="listings">
        <Pages.Listings.Edit />
      </Layout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "investment" }),
  },
  {
    path: "/listings/franchise/edit/:id",
    element: (
      <Layout page="settings">
        <Pages.Listings.Edit />
      </Layout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "franchise" }),
  },
  {
    path: "/listings/sale/edit/:id",
    element: (
      <Layout page="settings">
        <Pages.Listings.Edit />
      </Layout>
    ),
    loader: ({ params }) =>
      Loaders.Listings.getById({ id: params.id, type: "sale" }),
  },
  {
    path: "/listings/new",
    element: (
      <Layout page="listings">
        <Pages.Listings.New />
      </Layout>
    ),
  },
  {
    path: "/listings",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
    loader: Loaders.Dashboard,
  },
  {
    path: "/",
    index: true,
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
    loader: Loaders.Dashboard,
  },
];

export default routes;
