import * as Companies from "./companies";
import * as CompanyBanners from "./company_banners";
import * as CompanyContacts from "./company_contacts";
import * as CompanyCategories from "./company_categories";
import * as Notifications from "./notifications";
import * as Payments from "./payments";
import axios from "axios";
import qs from "qs";

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

export {
  Companies,
  CompanyBanners,
  CompanyContacts,
  CompanyCategories,
  Notifications,
  Payments,
};
