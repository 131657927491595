import imgproxy from "@bitpatty/imgproxy-url-builder";
import config from "@config";

export function getURL(file, options = {}) {
  const {
    width = null,
    height = null,
    format = "webp",
    enlarge = false,
    resize = "auto",
    blur = null,
    quality = 100,
    background = null,
  } = options;

  if (!file || file === "") return "/img/no-image.svg";

  const image = imgproxy();

  if (height || width) {
    image.resize({
      type: resize,
      width,
      height,
    });
  }

  if (blur) {
    image.blur(blur);
  }

  if (background) {
    image.background(background);
  }

  if (enlarge) {
    image.enlarge();
  }

  return image
    .quality(quality)
    .format(format)
    .build({
      ...config.imgproxy,
      path: `s3://kapital-market/${file}`,
    });
}
