import { Breadcrumb } from "@components";
import { Companies } from "@modules/moderator";
import { useLoaderData } from "react-router-dom";

export default function ListingsPage() {
  const data = useLoaderData();

  return (
    <div>
      <Breadcrumb
        showBack
        data={[
          { title: "Компании", href: "/companies" },
          {
            title: "Данные компании",
            href: `/companies/view/${data?.id}`,
          },
        ]}
      />
      <Companies.Info id={data?.id} initialData={data} />
    </div>
  );
}
