import { Collapse, Button } from "antd";
import classNames from "classnames";

export default function PremiumBlock({ className }) {
  return (
    <Collapse
      bordered={false}
      className={classNames("my-5 p-2 rounded-md", className)}
      style={{ backgroundColor: "#FFF6DF" }}
      expandIcon={() => null}
    >
      <Collapse.Panel
        header={
          <div>
            <div className="flex items-center justify-start">
              <img
                src="/img/promotions/icon-premium.svg"
                alt="Premium"
                width={20}
                height={20}
                className="block mr-2"
              />
              <span className="block text-lg font-semibold text-neutral-800">
                Premium
              </span>
            </div>
            <div className="w-full flex flex-col lg:flex-row justify-between items-start">
              <p className="block m-0 mt-2 text-base text-neutral-800 w-full">
                Уникальное предложение для продажи бизнеса - упаковка Вашего
                бизнеса под продажу, индивидуальный дизайн страницы, фото-,
                видеосъемка, внутреннее и внешние продвижение.
              </p>
              <Button size="large" className="mt-5 lg:m-0">
                Подробнее
              </Button>
            </div>
          </div>
        }
        key="1"
      >
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                Landing page
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Ваша отдельная страница на sdelka.kz, с отличащимся дизайном и
                возможностью сборов контактных данных потенциальных покупателей
                (генерация лидов).
              </p>
            </div>
            <img
              src="/img/promotions/premium/landing.svg"
              alt="Landing Page"
              className="block w-full mt-10"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                Kapital.kz
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Ваше объявление увидят пользователи новостного портала Капитал.
                Более миллиона ежемесячных пользователей, которых интересуют
                бизнес, экономика и финансы, что является лучшей аудиторией для
                продажи бизнеса.
              </p>
            </div>
            <img
              src="/img/promotions/premium/kapital.svg"
              alt="Kapital.kz"
              className="block w-full mt-10"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                Instagram, Facebook
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Внешнее продвижение Вашего объявления, через таргетированную
                рекламу компании Meta.
              </p>
            </div>
            <img
              src="/img/promotions/premium/facebook.svg"
              alt="Facebook"
              className="block w-full mt-10"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                Рекламная сеть Google
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Внешнее продвижение Вашего объявления на рекламной сети Google -
                поиск Google, YouTube, медийные объявления на сайтах и в
                приложениях.
              </p>
            </div>
            <img
              src="/img/promotions/premium/google.svg"
              alt="Рекламная сеть Google"
              className="block w-full mt-10"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                E-mail-рассылка
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Рассылка информации о вашем объявлении нашей базе пользователей.
              </p>
            </div>
            <img
              src="/img/promotions/premium/email.svg"
              alt="E-mail-рассылка"
              className="block w-full mt-10"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 bg-white border border-solid border-neutral-300 p-6 rounded-md flex flex-col justify-between">
            <div>
              <span className="block m-0 font-semibold text-xl text-neutral-800">
                Оформление
              </span>
              <p className="block mt-2 text-base font-light text-black">
                Упаковка вашего бизнеса под продажу - дизайн, фото, видео.
              </p>
            </div>
            <img
              src="/img/promotions/premium/style.svg"
              alt="Оформление"
              className="block w-full mt-10"
            />
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
