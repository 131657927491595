import { useEffect, useState } from "react";

export const useDebounce = (value, delay, delayOnlyToTrue = false) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // To be true -> delay; to be false - immediately
    if (delayOnlyToTrue) {
      !value && setDebouncedValue(value);
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, delayOnlyToTrue]);

  return debouncedValue;
};
