import { Loading } from "@components";
import { Card, Collapse, Form, Checkbox, Divider, Button, message } from "antd";
import { useState, useEffect } from "react";
import { BellOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import * as API from "@api";

export default function Password() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    API.Users.getOne()
      .then((res) => setData(res?.data?.notification_settings || null))
      .catch((err) =>
        message.error(
          err.message ||
            err?.response?.data ||
            "Ошибка во время загрузки данных уведомлений",
          2
        )
      )
      .finally(() => setLoading(false));
  }, []);

  const onSave = (values) => {
    setLoading(true);

    API.Users.updateNotificationSettings({
      ...values,
      filter_subscribe: data?.filter_subscribe || {
        filter_subscribe: { push: true, email: true, notification: true },
      },
    })
      .catch((err) =>
        message.error(
          err.message ||
            err?.response?.data ||
            "Ошибка во время сохранения данных уведомлений",
          2
        )
      )
      .finally(
        () => message.success("Настройки уведомлений обновлены", 2),
        setLoading(false)
      );
  };

  if (loading && !data) {
    return <Loading compact />;
  }

  return (
    <Card
      extra={<BellOutlined />}
      title="Уведомления и рассылка"
      className="shadow-lg shadow-gray-100 transition-all h-full w-full"
      bodyStyle={{ padding: 10 }}
    >
      <Form
        initialValues={data}
        layout="vertical"
        onFinish={onSave}
        onFinishFailed={({ errorFields }) => {
          errorFields.forEach(({ errors }) => {
            errors.forEach((error) => {
              message.error(error, 3);
            });
          });
        }}
      >
        <Collapse
          accordion
          defaultActiveKey={["promo"]}
          size="small"
          ghost
          className="w-full p-0"
        >
          <Collapse.Panel
            forceRender
            className="p-0"
            header="Акции и скидки"
            key="promo"
          >
            <span className="text-sm m-0 text-neutral-500">
              Рассылки с последними акциями и скидками на платформе.
            </span>
            <Form.Item
              className="m-0 mt-3"
              name={["promo", "email"]}
              label="Получать уведомления на:"
              valuePropName="checked"
            >
              <Checkbox className="m-0">Эл.почту</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["promo", "notification"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">В личном кабинете</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["promo", "push"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">Приложение Sdelka.kz (Push)</Checkbox>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            className="p-0"
            header="Платные сервисы"
            key="monetization"
          >
            <span className="text-sm m-0 text-neutral-500">
              Изменения статуса купленных платных сервисов.
            </span>
            <Form.Item
              className="m-0 mt-3"
              name={["monetization", "email"]}
              label="Получать уведомления на:"
              valuePropName="checked"
            >
              <Checkbox className="m-0">Эл.почту</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["monetization", "notification"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">В личном кабинете</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["monetization", "push"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">Приложение Sdelka.kz (Push)</Checkbox>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            className="p-0"
            header="Мои объявления"
            key="product_status_change"
          >
            <span className="text-sm m-0 text-neutral-500">
              Изменения статуса объявлений.
            </span>
            <Form.Item
              className="m-0 mt-3"
              name={["product_status_change", "email"]}
              label="Получать уведомления на:"
              valuePropName="checked"
            >
              <Checkbox className="m-0">Эл.почту</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["product_status_change", "notification"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">В личном кабинете</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["product_status_change", "push"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">Приложение Sdelka.kz (Push)</Checkbox>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            forceRender
            className="p-0"
            header="Мои компании"
            key="company_status_change"
          >
            <span className="text-sm m-0 text-neutral-500">
              Изменения статуса компании.
            </span>
            <Form.Item
              className="m-0 mt-3"
              name={["company_status_change", "email"]}
              label="Получать уведомления на:"
              valuePropName="checked"
            >
              <Checkbox className="m-0">Эл.почту</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["company_status_change", "notification"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">В личном кабинете</Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0"
              name={["company_status_change", "push"]}
              valuePropName="checked"
            >
              <Checkbox className="m-0">Приложение Sdelka.kz (Push)</Checkbox>
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        <div className="p-3 pt-0">
          <Divider className="mt-2" />
          <Form.Item className="!mb-0">
            <Button
              disabled={loading}
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              {t("generic.save")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
}
