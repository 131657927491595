import { useEffect, useState } from "react";
import {
  Form,
  Upload,
  message,
  Input,
  Button,
  Popconfirm,
  Tooltip,
  Divider,
} from "antd";
import {
  FileOutlined,
  FilePdfOutlined,
  PictureOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import * as API from "@api";
import { remove } from "ramda";
import { useAuth } from "@contexts";
import { useTranslation } from "react-i18next";
import config from "@config";

const { Dragger } = Upload;

export default function Files({ form }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(form.getFieldValue("files_list") || []);
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({ file_list: files });
    /* eslint-disable-next-line */
  }, [files]);

  const onFileDelete = (idx) => {
    setFiles(remove(idx, 1, files));
  };

  const FileList = () => {
    return files.map((file, idx) => {
      let icon = <FileOutlined />;

      switch (true) {
        case file.type === "application/pdf":
          icon = <FilePdfOutlined />;
          break;

        case /image/.test(file.type):
          icon = <PictureOutlined />;
          break;

        default:
          break;
      }

      return [
        <div
          key={idx}
          className="w-full flex flex-col lg:flex-row justify-start items-center my-5"
        >
          <Tooltip title={t("listings.enterFileDescription")}>
            <Input
              className="mb-3 lg:mb-0 lg:rounded-r-none lg:border-r-0"
              placeholder={t("listings.enterFileText")}
              onChange={(e) => {
                files[idx].caption = e.target.value;
                form.setFieldsValue({ file_list: [...files] });
              }}
              defaultValue={file.caption}
            />
          </Tooltip>
          <div className="flex items-center">
            <a
              rel="noreferrer"
              title="Загрузить файд"
              href={`${config.s3.endpoint}${file.url}`}
              target="_blank"
            >
              <Button className="mx-1 lg:mx-0 lg:rounded-none" icon={icon}>
                {t("generic.open")}
              </Button>
            </a>
            <Popconfirm
              okText={t("generic.delete")}
              title={t("listings.deleteFile")}
              cancelText={t("generic.cancel")}
              onConfirm={() => onFileDelete(idx)}
            >
              <Button
                className="mx-1 lg:mx-0 lg:rounded-l-none lg:border-l-none"
                danger
                type="primary"
                icon={<DeleteOutlined />}
              >
                {t("generic.delete")}
              </Button>
            </Popconfirm>
          </div>
        </div>,
      ];
    });
  };

  return (
    <div>
      <Divider>{t("listings.attachedFilesTitle")}</Divider>
      <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-2">
        {t("listings.attachedFilesText")}
      </span>
      <FileList />

      {loading && (
        <div className="text-center w-full my-5">
          <LoadingOutlined
            style={{ fontSize: 22 }}
            className="text-neutral-400"
          />
        </div>
      )}

      <Form.Item
        className="mt-5"
        tooltip={t("listings.attachedFilesTooltip")}
        label={t("listings.docsAndPres")}
        name="file_upload"
        required={false}
      >
        <Dragger
          multiple={false}
          name="files"
          accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.presentationml.slide,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.template,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template,image/*"
          disabled={files.length >= 5}
          showUploadList={false}
          // beforeUpload={() => null}
          className="my-5 py-5"
          customRequest={async ({ onSuccess, onError, file }) => {
            try {
              setLoading(true);
              const _res = await API.Static.uploadFile({
                file,
                id: currentUser.id,
              });

              if (!_res.data || _res.error) {
                throw new Error();
              }

              setLoading(false);
              setFiles([
                ...files,
                {
                  url: _res.data.path,
                  type: _res.data.mime,
                  caption: "Документ",
                },
              ]);
              onSuccess();
            } catch (error) {
              onError();
              return message.error(t("generic.serverError"), 2);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <FileOutlined />
          </p>
          <p className="text-sm text-black w-full block text-center">
            {t("listings.dragToLoad")}
          </p>
          <p className="text-xs text-neutral-500 w-full block text-center mt-2">
            {t("listings.dragToLoadText")}
          </p>
        </Dragger>
      </Form.Item>
    </div>
  );
}
