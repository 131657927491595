import { useState } from "react";
import { message, Select, Spin, Tag } from "antd";
import debounce from "debounce";
import * as API from "@api/moderator";

export default function Products({loading, ...props}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  let fetchList = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Products.getAll({
      limit: 10,
      filter: { title: value },
    });

    if (!data || data?.error) {
      return message.error(
        "Невозможно произвести поиск, проверьте интернет-соединение",
        3
      );
    }

    setOptions(data?.data?.rows?.map(product => ({
      value: product?.id,
      label: `${product.title} (ID ${product.id})`,
      data: product,
    })));

    setFetching(false);
  };

  fetchList = debounce(fetchList, 200);

  return (
    <Select
      showSearch
      allowClear
      className="w-full"
      placeholder="Поиск по объявлению"
      filterOption={false}
      onSearch={fetchList}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      optionLabelProp="label"
      loading={loading}
      {...props}
    >
      {options?.map(option => <Select.Option label={option.label} value={option.value}>
        <div className="py-2">
            <Tag
              className="block w-fit"
              color={
                option?.data?.type === "sale"
                  ? "green"
                  : option?.data?.type === "franchise"
                  ? "blue"
                  : "default"
              }
            >
              {option?.data?.type === "sale" && "Бизнес"}
              {option?.data?.type === "franchise" && "Франшиза"}
              {option?.data?.type === "investment" && "Инвестиции"}
            </Tag>
            <span className="font-medium text-sm block mt-1">
              {option?.data.title} (ID {option?.data.id})
            </span>
          </div>
      </Select.Option>)}
    </Select>
  );
}
