import { useState, useEffect } from "react";
import { message, Button, Upload, Avatar } from "antd";
import { Loading } from "@components";
import { useAuth } from "@contexts";
import { Imgproxy } from "@utils";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";
import * as API from "@api";

export default function UserAvatar() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [imageLoading, setImageLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    getUserData();
  }, [currentUser]);

  const getUserData = async () => {
    setImageLoading(true);
    const _res = await API.Users.getOne();

    if (!_res?.data || _res.error) {
      return message.error(t("generic.serverError"), 3);
    }

    setData(_res.data);
    setImageLoading(false);
  };

  if (imageLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col justify-center items-center m-0 mb-10">
      <Avatar
        alt="Фото профиля"
        src={Imgproxy.getURL(data?.avatar, { height: 170, width: 170 })}
        size={100}
        icon={<UserOutlined />}
        className="mt-2 bg-neutral-50 overflow-hidden rounded-full block object-cover object-center border border-solid border-neutral-200"
      />
      <ImgCrop
        beforeCrop={(file) => {
          if (file.size > 3 * 1024 * 1024) {
            message.error(t("settings.avatarError"), 2);
            return false;
          }

          return true;
        }}
        grid
        rotate
        modalTitle="Расположите фото"
      >
        <Upload
          multiple={false}
          name="img"
          accept="image/jpeg"
          showUploadList={false}
          customRequest={async ({ onSuccess, onError, file }) => {
            try {
              setImageLoading(true);

              const _res = await API.Static.uploadFile({
                file,
                id: currentUser.id,
              });

              if (!_res.data || _res.error) {
                throw new Error();
              }

              const _resUpdate = await API.Users.update({
                avatar: _res.data.path,
              });

              if (!_resUpdate.data || _resUpdate.error) {
                throw new Error();
              }

              getUserData();
              onSuccess();
            } catch (error) {
              onError();
              setImageLoading(false);
              return message.error(t("generic.serverError"), 2);
            }
          }}
        >
          <Button type="link" className="mt-2" icon={<UploadOutlined />}>
            {data?.avatar
              ? t("settings.avatarChange")
              : t("settings.avatarUpload")}
          </Button>
        </Upload>
      </ImgCrop>
    </div>
  );
}
