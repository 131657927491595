import { Empty } from "antd";
import { Pie } from "@ant-design/plots";
import { uniq } from "ramda";

export default function Chart({ data = [], categories }) {
  const _data = [];

  /* eslint-disable-next-line */
  categories.map((cat) => {
    _data.push({
      type: cat.name,
      value: data.filter((d) => d.outcome_reason === cat.id)?.length,
    });
  });

  const config = {
    appendPadding: 10,
    data: uniq(_data),
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };

  if (data?.length === 0 || !data) {
    return <Empty description="Нет данных" />;
  }

  return <Pie className="w-full" {...config} />;
}
