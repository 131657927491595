import { useState } from "react";
import { Card, Form, Select, Divider, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import Contacts from "./contacts";
import Fade from "react-reveal/Fade";
import classNames from "classnames";

export default function FranchiseDetails({
  loading,
  setStage,
  form,
  visible = false,
  isApp = false,
}) {
  const { t } = useTranslation();

  const [isLumpRange, setIsLumpRange] = useState(
    form.getFieldValue(["franchise", "is_lump_range"])
  );
  const [royaltyType, setRoyaltyType] = useState(
    form.getFieldValue(["franchise", "royalty_type"])
  );

  const onBackClick = () => {
    setStage(1);
  };

  return (
    <Fade spy={visible} exit={false} duration={300} distance="5px" bottom>
      <Card
        hidden={!visible}
        className={classNames("shadow-lg shadow-gray-100 transition-all", {
          "app-card": isApp,
        })}
      >
        <h2 className="w-full text-center my-1">
          {t("listings.extraInfoTitle")}
        </h2>
        <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-10">
          {t("listings.extraInfoText")}
        </span>

        <Divider>{t("listings.lumpTitle")}</Divider>
        <div className="grid grid-cols-3 gap-x-4">
          <div className="col-span-3 lg:col-span-1">
            <Form.Item
              tooltip={t("listings.lumpTooltip")}
              label={t("listings.lumpAskTitle")}
              name={["franchise", "is_lump_range"]}
              rules={[
                {
                  required: true,
                  message: t("generic.selectOption"),
                },
              ]}
            >
              <Select onChange={setIsLumpRange} placeholder="Выберите значение">
                <Select.Option value={false}>{t("generic.yes")}</Select.Option>
                <Select.Option value={true}>
                  {t("generic.no")}, диапазон
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <Form.Item
              label={
                isLumpRange
                  ? t("listings.lumpSumMinTitle")
                  : t("listings.lumpSumMinTitleAlt")
              }
              name={["franchise", "lump_sum"]}
              rules={[
                {
                  required: true,
                  message: t("generic.fillRequired"),
                },
              ]}
            >
              <InputNumber
                className="w-full"
                formatter={(value) =>
                  `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder={t("listings.sumPlaceholder")}
              />
            </Form.Item>
          </div>
          {isLumpRange && (
            <div className="col-span-3 lg:col-span-1">
              <Form.Item
                label={t("listings.lumpMaxTitle")}
                name={["franchise", "lump_max"]}
                rules={[
                  {
                    required: form.getFieldValue("is_price_range"),
                    message: t("listings.lumpMaxPrompt"),
                  },
                ]}
              >
                <InputNumber
                  className="w-full"
                  formatter={(value) =>
                    `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  placeholder={t("listings.sumPlaceholder")}
                />
              </Form.Item>
            </div>
          )}
          <div className="col-span-3 lg:col-span-1">
            <Form.Item
              tooltip={t("listings.lumpIncludedTitle")}
              label={t("listings.lumpIncludedLabel")}
              name={["franchise", "is_lump_included"]}
              rules={[
                {
                  required: true,
                  message: t("generic.selectOption"),
                },
              ]}
            >
              <Select placeholder={t("listings.chooseOption")}>
                <Select.Option value={true}>{t("generic.yes")}</Select.Option>
                <Select.Option value={false}>{t("generic.no")}</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <Divider>{t("listings.royaltyTitle")}</Divider>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="col-span-2 lg:col-span-1">
            <Form.Item
              label={t("listings.royaltyLabel")}
              name={["franchise", "royalty_type"]}
              rules={[
                {
                  required: true,
                  message: t("generic.selectOption"),
                },
              ]}
            >
              <Select placeholder="Выберите значение" onChange={setRoyaltyType}>
                <Select.Option value="fixed">
                  {t("listings.royaltyFixed")}
                </Select.Option>
                <Select.Option value="rate">
                  {t("listings.royaltyPercent")}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-2 lg:col-span-1">
            {royaltyType === "rate" ? (
              <Form.Item
                label={t("listings.royaltyPercentTitle")}
                name={["franchise", "royalty_value"]}
                rules={[
                  {
                    required: true,
                    message: t("generic.fillRequired"),
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  className="w-full"
                  placeholder={t("listings.royaltyPercentPlaceholder")}
                  addonAfter="%"
                  max={100}
                  min={0}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={t("listings.royaltySumTitle")}
                name={["franchise", "royalty_value"]}
                rules={[
                  {
                    required: true,
                    message: t("listings.sumPrompt"),
                  },
                ]}
              >
                <InputNumber
                  className="w-full"
                  formatter={(value) =>
                    `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  placeholder={t("listings.sumPlaceholder")}
                  min={0}
                />
              </Form.Item>
            )}
          </div>
        </div>
        <Divider />
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.profitTitle")}
              name={["franchise", "month_profit"]}
              help={t("listings.profitHelp")}
              rules={[
                {
                  required: true,
                  message: t("listings.profitPrompt"),
                },
              ]}
            >
              <InputNumber
                className="w-full"
                formatter={(value) =>
                  `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder={t("listings.profitPlaceholder")}
              />
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.paybackTitle")}
              help={t("listings.paybackHelp")}
              name={["franchise", "payback_period"]}
              rules={[
                {
                  required: true,
                  type: "number",
                  message: t("listings.paybackPromptAlt"),
                },
              ]}
            >
              <InputNumber
                className="w-full"
                controls={false}
                type="number"
                addonAfter="мес."
                placeholder={t("listings.paybackPlaceholder")}
              />
            </Form.Item>
          </div>
        </div>
      </Card>
      <Contacts
        isApp={isApp}
        visible={visible}
        form={form}
        loading={loading}
        onBackClick={onBackClick}
      />
    </Fade>
  );
}
