import axios from "axios";
import config from "@config";

const API = `${config.api}/moderator/companies`;

export const getAll = params =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getOne = params =>
  axios
    .get(`${API}/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const activateCompany = (id, data) =>
  axios
    .post(`${API}/activate/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deactivateCompany = (id, data) =>
  axios
    .post(`${API}/deactivate/id/${id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const changeStatus = data =>
  axios
    .post(`${API}/status/id/${data?.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
