import { Layout, Menu, Dropdown, Avatar, Badge, Button, Divider } from "antd";
import { useLocation, Link } from "react-router-dom";
import { Loading, LocaleSwitcher } from "@components";
import { useAuth } from "@contexts";
import config from "@config";
import pkg from "@base/package.json";
import {
  ContainerOutlined,
  GlobalOutlined,
  NotificationOutlined,
  HeartOutlined,
  CreditCardOutlined,
  SettingOutlined,
  LogoutOutlined,
  RightOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Imgproxy } from "@utils";
import { useTranslation } from "react-i18next";

const { Header, Content, Footer } = Layout;

export default function SellerLayout({ showNav = true, ...props }) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const { children, loading } = props;
  const { currentUser: user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setDrawerVisible(false);
  }, [location]);

  const onHardRefresh = (e) => {
    e.preventDefault();
    return document.location.reload(true);
  };

  const selectedKeys = () => {
    if (location.pathname === "/") {
      return ["listings"];
    }

    const loc = location.pathname.substr(1).split("/");
    return [loc[0]];
  };

  const usermenu = (
    <Menu className="w-64">
      {user && (
        <div className="w-full py-2 px-3">
          <span className="block mb-1 w-full text-black font-semibold text-base">
            {user.name}
          </span>
          <span className="break-words block mb-1 w-full text-neutral-400 font-normal text-xs">
            ID {user.id} &middot; {user.email}
          </span>
        </div>
      )}
      <Menu.Divider />
      {/* <Menu.Item> */}
        <LocaleSwitcher
          icon={null}
          className="m-0 p-0 text-black w-full text-left"
          // type="link"
          useDropdown={false}
          // reload={false}
        />
      {/* </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item icon={<ContainerOutlined />} className="py-2">
        <Link to="/">{t("nav.myListings")}</Link>
      </Menu.Item>
      <Menu.Item icon={<NotificationOutlined />} className="py-2">
        <Link to="/notifications">
          {t("nav.notifications")}{" "}
          {user.notifications && (
            <Badge className="ml-1" size="small" status="error" />
          )}
        </Link>
      </Menu.Item>
      <Menu.Item icon={<HeartOutlined />} className="py-2">
        <Link to="/favorites">{t("nav.favorites")}</Link>
      </Menu.Item>
      <Menu.Item icon={<CreditCardOutlined />} className="py-2">
        <Link to="/payments">{t("nav.payments")}</Link>
      </Menu.Item>
      <Menu.Item icon={<SettingOutlined />} className="py-2">
        <Link to="/settings">{t("nav.settings")}</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item icon={<LogoutOutlined />} danger>
        <Link className="!transition-none" to="/logout">
          {t("nav.logout")}
        </Link>
      </Menu.Item>
    </Menu>
  );

  const MobileMenu = () => (
    <div className={`mobile-nav-alt-wrapper ${drawerVisible && "nav-open"}`}>
      <div className="mobile-nav-alt">
        <div className="divider-bgr" />
        <div className="nav-alt-container">
          <Link to="/settings" className="nav-header">
            <div className="flex justify-start items-center">
              <img
                className="nav-avatar"
                src={
                  user?.avatar
                    ? `${Imgproxy.getURL(user.avatar, {
                        width: 150,
                        height: 150,
                      })}`
                    : "/img/icon-avatar-empty.svg"
                }
                alt={user.name}
              />
              <span className="nav-name">{user.name}</span>
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Divider />
          <Link to="/" className="nav-item">
            <div className="flex justify-start items-center">
              <img
                width={20}
                height={20}
                src="/img/icon-listings.svg"
                className="mr-2"
                alt="Мои объявления"
              />{" "}
              {t("nav.myListings")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to="/notifications" className="nav-item">
            <div className="flex justify-start items-center">
              <img
                width={20}
                height={20}
                src="/img/icon-messages.svg"
                className="mr-2"
                alt="Сообщения"
              />{" "}
              {t("nav.messages")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to="/favorites" className="nav-item">
            <div className="flex justify-start items-center">
              <img
                width={20}
                height={20}
                src="/img/icon-heart.svg"
                className="mr-2"
                alt="Избранное"
              />{" "}
              {t("nav.favorites")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
        </div>
        <div className="divider-bgr" />
        <div className="nav-alt-container">
          <span
            className="block font-semibold text-xl"
            style={{ color: "#404040" }}
          >
            {t("nav.categories")}
          </span>
          <Link to={`${config.frontend}/sale`} className="nav-item">
            {t("nav.findBusiness")}
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to={`${config.frontend}/franchise`} className="nav-item">
            {t("nav.findFranchise")}
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to={`${config.frontend}/startups`} className="nav-item">
            {t("nav.findInvestment")}
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
        </div>
        <div className="divider-bgr" />
        <div className="nav-alt-container">
          <LocaleSwitcher
            useDropdown={false}
            // className="nav-item flex justify-center m-0 h-fit"
            // style={{ padding: "9px 19px 0px" }}
            // icon={null}
            // title="Сменить язык"
          />
          {/* <div to="/settings" className="nav-item">
            <div className="flex justify-start items-center">
              <SettingOutlined className="m-0 mr-2 h-5 text-xl leading-none" />
              {t("nav.settingsProfile")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </div> */}
          <Link to="/settings" className="nav-item">
            <div className="flex justify-start items-center">
              <SettingOutlined className="m-0 mr-2 h-5 text-xl leading-none" />
              {t("nav.settingsProfile")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to="/payments" className="nav-item">
            <div className="flex justify-start items-center">
              <CreditCardOutlined className="m-0 mr-2 h-5 text-xl leading-none" />
              {t("nav.payments")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
          <Link to="/logout" className="nav-item">
            <div className="flex justify-start items-center">
              <LogoutOutlined className="m-0 mr-2 h-5 text-xl leading-none text-red-500" />
              {t("nav.logout")}
            </div>
            <RightOutlined style={{ color: "rgba(0, 0, 0, 0.85)" }} />
          </Link>
        </div>
      </div>
    </div>
  );

  const onNavTrigger = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout {...props} className="global-layout">
      {showNav && <MobileMenu />}
      <Header
        hidden={!showNav}
        className="bg-neutral-50 flex flex-col justify-center px-0"
      >
        <div className="container content-max flex justify-between items-center">
          <a href={`${config.frontend}`} className="logo-top">
            <img
              width={134}
              height={34}
              alt="Вернуться на главную"
              src="/img/logo.svg"
            />
          </a>
          <div className="flex items-center">
            <Menu
              className="menu-layout bg-neutral-50 hidden lg:block lg:visible"
              mode="horizontal"
              defaultSelectedKeys={selectedKeys}
            >
              <Menu.Item key="listings">
                <Link to="/">{t("nav.myListings")}</Link>
              </Menu.Item>
              <Menu.Item key="notifications">
                <Link to="/notifications">
                  {t("nav.notifications")}{" "}
                  {user.notifications && (
                    <Badge className="ml-1" size="small" status="error" />
                  )}
                </Link>
              </Menu.Item>
              <Menu.Item key="favorites">
                <Link to="/favorites">{t("nav.favorites")}</Link>
              </Menu.Item>
            </Menu>
            {isMobile ? (
              drawerVisible ? (
                <CloseOutlined
                  className="text-xl leading-none m-0 p-0"
                  onClick={onNavTrigger}
                />
              ) : (
                <Avatar
                  size="large"
                  onClick={onNavTrigger}
                  src={
                    user?.avatar &&
                    Imgproxy.getURL(user?.avatar, {
                      width: 100,
                      height: 100,
                    })
                  }
                  className="bg-neutral-200 text-neutral-400"
                  style={{ cursor: "pointer" }}
                  icon={<UserOutlined />}
                />
              )
            ) : (
              <Dropdown placement="bottom" overlay={usermenu}>
                <Avatar
                  size="large"
                  src={
                    user?.avatar &&
                    Imgproxy.getURL(user?.avatar, {
                      width: 100,
                      height: 100,
                    })
                  }
                  className="bg-neutral-200 text-neutral-400"
                  style={{ cursor: "pointer" }}
                  icon={<UserOutlined />}
                />
              </Dropdown>
            )}
          </div>
        </div>
      </Header>
      <Content style={{ margin: 0 }}>
        <div
          className={`site-layout-background container content-max ${props.className}`}
          style={{ paddingTop: 20, paddingBottom: 20, minHeight: 360 }}
        >
          {loading ? <Loading /> : children}
        </div>
      </Content>
      <Footer
        hidden={!showNav}
        className="text-center bg-neutral-50 flex flex-wrap items-center justify-center"
      >
        <a className="block mr-1" href="https://musan.kz" title="Musan Digital">
          Musan Digital
        </a>{" "}
        © {new Date().getFullYear()} &middot; Версия {pkg.version} &middot;
        <Button
          onClick={onHardRefresh}
          className="m-0 p-0 ml-1"
          shape="circle"
          size="small"
          type="link"
        >
          {t("nav.update")}
        </Button>
      </Footer>
    </Layout>
  );
}
