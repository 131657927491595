import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { revalidateClientData, Cities } from "@api";
import { Loading } from "@components";
import { Steps, TypeSelector, Info, SalesDetails, FranchiseDetails, InvestmentDetails, Publish } from "../components";
import { useTranslation } from "react-i18next";
import { pathOr } from "ramda";
import { useAuth } from "@contexts";
import classNames from "classnames";
import * as API_ALL from "@api/customer";
import * as API_ALL_MODERATOR from "@api/moderator";
import { Geocode } from "@utils";

export default function EditListing({ data, initialStage = 1, isApp = false }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [stage, setStage] = useState(initialStage);
  const { t } = useTranslation();

  const API = {
    customer: {
      sale: API_ALL.Sales,
      franchise: API_ALL.Franchises,
      investment: API_ALL.Investments
    },
    moderator: {
      sale: API_ALL_MODERATOR.Sales,
      franchise: API_ALL_MODERATOR.Franchises,
      investment: API_ALL_MODERATOR.Investments
    }
  };

  useEffect(() => {
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [stage]);

  const onFormSubmit = async values => {
    const type = form.getFieldValue("type");
    const contacts = form.getFieldValue("contacts") || [];
    const logo = form.getFieldValue([type, "logo"]) || data[type]?.logo || null;

    setLoading(true);

    const { image_list = [], file_list = [] } = form.getFieldsValue(["image_list", "file_list"]);

    if (image_list.length === 0) {
      setLoading(false);
      return message.error(t("listings.photoPrompt"), 3);
    }

    if (contacts.length === 0) {
      setLoading(false);
      return message.error(t("listings.phonePrompt"), 3);
    }

    // Prepare Product data
    const product = {
      id: data.id,
      status: "in_review",
      title: values.title.trim(),
      subtitle: values.title,
      contacts: contacts.map(({ id }) => ({ id })) || undefined,
      display_name: values?.display_name || "Sdelka.kz",
      description: pathOr("", ["description"], values),
      description_short: "",
      price: values.price,
      price_max: pathOr(null, ["price_max"], values),
      is_price_range: pathOr(false, ["is_price_range"], values),
      category_id: values.category_id[values.category_id.length - 1],
      image_main: image_list[0],
      country_id: values.country_id,
      city_id: values.city_id,
      website: pathOr(null, ["website"], values) || null,
      image_list,
      files_list: file_list,
      meta: pathOr(null, ["meta"], values),
      options: pathOr([], ["options"], values).map(JSON.parse)
    };

    let payload = {};

    // Prepare payload data depending on type of listing
    switch (type) {
      case "sale":
        payload = {
          month_revenue: pathOr(null, ["sale", "month_revenue"], values),
          month_costs: pathOr(null, ["sale", "month_costs"], values),
          month_profit: pathOr(null, ["sale", "month_profit"], values),
          payback_period: pathOr(null, ["sale", "payback_period"], values),
          revenue_confirmation: pathOr([], ["sale", "revenue_confirmation"], values),
          staff_qty: pathOr(1, ["sale", "staff_qty"], values),
          sell_percentage: pathOr(null, ["sale", "sell_percentage"], values),
          is_active: pathOr(null, ["sale", "is_active"], values),
          foundation_year: pathOr(null, ["sale", "foundation_year"], values),
          org_type_id: pathOr(null, ["sale", "org_type_id"], values),
          description: pathOr("", ["description"], values),
          sell_reason: pathOr(null, ["sale", "sell_reason"], values),
          bin: String(pathOr("", ["sale", "bin"], values)),
          display_name: values?.display_name || "Sdelka.kz"
        };
        break;

      case "franchise":
        payload = {
          lump_sum: pathOr(0, ["franchise", "lump_sum"], values),
          is_lump_range: pathOr(false, ["franchise", "is_lump_range"], values),
          lump_max: pathOr(null, ["franchise", "lump_max"], values),
          is_lump_included: pathOr(false, ["franchise", "is_lump_included"], values),
          royalty_value: pathOr(null, ["franchise", "royalty_value"], values),
          royalty_type: pathOr(null, ["franchise", "royalty_type"], values),
          month_profit: pathOr(null, ["franchise", "month_profit"], values),
          payback_period: pathOr(null, ["franchise", "payback_period"], values),
          description: pathOr("", ["description"], values),
          display_name: values?.display_name || "Sdelka.kz",
          logo
        };
        break;

      case "investment":
        payload = {
          year_profit_rate_min: pathOr(0, ["investment", "year_profit_rate_min"], values),
          year_profit_rate_max: pathOr(0, ["investment", "year_profit_rate_max"], values),
          stage: pathOr(null, ["investment", "stage"], values),
          description: pathOr("", ["description"], values),
          display_name: values?.display_name || "Sdelka.kz",
          logo
        };
        break;

      default:
        return message.error(t("listings.sendingError"), 2);
    }

    // делаем geocode, если бизнес и если был вписан только адрес
    if (type === "sale" && product.meta?.location?.address && !product?.meta?.location?.pos) {
      const cityID = form.getFieldValue("city_id");
      const cityResp = await Cities.getOne({ id: cityID });

      const { data } = await Geocode.geocode(`${cityResp?.data?.name}, ${product.meta.location.address}`);

      if (data) {
        const geoObject = data.response.GeoObjectCollection.featureMember[0].GeoObject;

        product.meta = {
          ...product.meta,
          location: {
            ...product.meta?.location,
            lon: geoObject.Point.pos.split(" ")[0],
            lat: geoObject.Point.pos.split(" ")[1],
            pos: geoObject.Point.pos,
            country: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "country")?.name,
            city: geoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(e => e.kind === "locality")?.name
          }
        };
      }
    }

    const _res = await API[currentUser?.role][data?.type].update({
      product,
      [type]: payload
    });

    setLoading(false);

    if (!_res || _res.error) {
      return message.error(t("listings.sendingError"), 3);
    }

    await revalidateClientData({
      id: data.id,
      category: type,
      slug: _res?.data?.product?.slug
    });

    setStage(3);
    return message.success(t("listings.sentToReview"), 5);
  };

  if (loading && !data?.id) {
    return <Loading />;
  }

  return (
    <Form
      form={form}
      className="global-form"
      layout="vertical"
      scrollToFirstError
      onFinish={onFormSubmit}
      onFinishFailed={({ errorFields }) => {
        errorFields.forEach(({ errors }) => {
          errors.forEach(error => {
            message.error(error, 3);
          });
        });
      }}
      size={isApp ? "large" : "middle"}
      initialValues={data}
    >
      <div className="grid grid-cols-12 gap-6">
        <div
          className={classNames("col-span-12", {
            "lg:col-span-4": currentUser?.role !== "moderator"
          })}
        >
          <Steps
            direction={isApp || currentUser?.role === "moderator" ? "horizontal" : "vertical"}
            form={form}
            current={stage}
            setStage={setStage}
            responsive={!isApp}
            showDescription={!isApp || currentUser?.role === "moderator"}
            showTitle={!isApp}
          />
        </div>
        <div
          className={classNames("col-span-12", {
            "lg:col-span-8": currentUser?.role !== "moderator"
          })}
        >
          <TypeSelector visible={stage === 0} form={form} stage={stage} setStage={setStage} isApp={isApp} />
          <Info allowBack={false} visible={stage === 1} form={form} stage={stage} setStage={setStage} isApp={isApp} />
          {form.getFieldValue("type") === "sale" && (
            <SalesDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} loading={loading} isApp={isApp} />
          )}
          {form.getFieldValue("type") === "franchise" && <FranchiseDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} isApp={isApp} />}
          {form.getFieldValue("type") === "investment" && (
            <InvestmentDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} loading={loading} isApp={isApp} />
          )}
          <Publish productId={data?.id || null} visible={stage === 3} form={form} stage={stage} setStage={setStage} isApp={isApp} />
        </div>
      </div>
    </Form>
  );
}
