export default (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9673 17.924C20.6195 18.7275 20.2078 19.4672 19.7308 20.1472C19.0805 21.0743 18.5481 21.716 18.1378 22.0724C17.5018 22.6573 16.8204 22.9568 16.0906 22.9739C15.5667 22.9739 14.935 22.8248 14.1996 22.5224C13.4617 22.2214 12.7837 22.0724 12.1637 22.0724C11.5135 22.0724 10.8161 22.2214 10.0702 22.5224C9.32315 22.8248 8.72133 22.9824 8.2612 22.998C7.56143 23.0278 6.86393 22.7197 6.1677 22.0724C5.72333 21.6848 5.16751 21.0204 4.50167 20.0791C3.78727 19.0739 3.19994 17.9084 2.73981 16.5795C2.24703 15.1442 2 13.7543 2 12.4087C2 10.8673 2.33306 9.53789 3.00019 8.42385C3.52448 7.529 4.22199 6.82312 5.09497 6.30493C5.96795 5.78674 6.9112 5.52267 7.92701 5.50578C8.48282 5.50578 9.2117 5.6777 10.1175 6.01559C11.0207 6.35462 11.6006 6.52655 11.8549 6.52655C12.045 6.52655 12.6893 6.32552 13.7815 5.92473C14.8143 5.55305 15.686 5.39916 16.4001 5.45978C18.3352 5.61595 19.789 6.37876 20.7558 7.75303C19.0252 8.80163 18.1691 10.2703 18.1861 12.1544C18.2017 13.622 18.7341 14.8432 19.7804 15.8129C20.2546 16.2629 20.7842 16.6107 21.3734 16.8578C21.2456 17.2283 21.1107 17.5832 20.9673 17.924V17.924ZM16.5293 0.460131C16.5293 1.61039 16.1091 2.68439 15.2714 3.67847C14.2606 4.86023 13.038 5.54311 11.7121 5.43536C11.6952 5.29736 11.6854 5.15213 11.6854 4.99951C11.6854 3.89526 12.1661 2.71349 13.0198 1.74724C13.446 1.25801 13.988 0.851218 14.6454 0.526714C15.3013 0.207053 15.9217 0.0302728 16.5052 0C16.5222 0.153772 16.5293 0.307554 16.5293 0.460116V0.460131Z"
      fill="black"
    />
  </svg>
);
