import { useEffect, useState } from "react";
import { Form, message, Steps } from "antd";
import { Info, Services } from "../components";
import * as API from "@api/partner";
import { pathOr, filter, propEq } from "ramda";
import { Navigate } from "react-router-dom";

export default function EditCompany({ data, initialStage = 0, isApp = false }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [stage, setStage] = useState(initialStage);

  useEffect(() => {
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [stage]);

  const onFormSubmit = (values) => {
    setLoading(true);
    const services = form.getFieldValue("services") || [];
    const image_list = form.getFieldValue("image_list") || [];
    const image_main = image_list.length > 0 ? image_list[0] : null;
    const logo = form.getFieldValue("logo") || null;
    const phones = form.getFieldValue("phones") || [];
    const messengers = form.getFieldValue("messengers") || [];

    if (!services || services.length === 0) {
      setLoading(false);
      return message.error("Необходимо добавить хотя бы одну услугу", 2);
    }

    API.Companies.update(data?.company?.id, {
      title: pathOr(null, ["title"], values),
      subtitle: pathOr(null, ["subtitle"], values),
      category_id: pathOr(null, ["category_id"], values),
      description: pathOr(null, ["description"], values),
      image_list,
      image_main,
      logo,
      services,
    })
      .then(async (res) => {
        if (!res || res?.error || !res?.data?.company?.id) {
          throw new Error(
            "Ошибка обновлении компании, проверьте соединение с интернетом и попробуйте еще раз"
          );
        }

        const { id } = res?.data?.company;

        await Promise.all([
          API.Companies.updateCountries(id, pathOr([], ["countries"], values)),
          API.Companies.updateCities(id, pathOr([], ["cities"], values)),
          API.Companies.updateContacts(id, [
            ...phones.map((phone) => phone.id),
            ...messengers.map((messenger) => messenger.id),
          ]),
        ])
          .then(([countries, cities, contacts]) => {
            setLoading(false);
            if (!countries || countries?.error) {
              throw new Error(
                "Ошибка обновления стран, попробуйте еще раз или обратитесь к нам: info@sdelka.kz"
              );
            }

            if (!cities || cities?.error) {
              throw new Error(
                "Ошибка обновления городов, попробуйте еще раз или обратитесь к нам: info@sdelka.kz"
              );
            }

            if (!contacts || contacts?.error) {
              throw new Error(
                "Ошибка обновления контактов, попробуйте еще раз или обратитесь к нам: info@sdelka.kz"
              );
            }

            setStage(2);
            message.success("Компания успешно обновлена", 3);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            return message.error(
              err?.message ||
                "Ошибка обновления компании, проверьте соединение с интернетом",
              3
            );
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return message.error(
          err?.message ||
            "Ошибка обновления компании, проверьте соединение с интернетом",
          3
        );
      });
  };

  if (stage === 2) {
    return <Navigate to="/" />;
  }

  return (
    <Form
      form={form}
      className="global-form"
      layout="vertical"
      scrollToFirstError
      onFinish={onFormSubmit}
      onErrorCapture={console.log}
      onFinishFailed={console.log}
      size={isApp ? "large" : "middle"}
      initialValues={{
        ...data?.company,
        phones: filter(propEq("type", "phone"), data?.contacts),
        messengers: filter(
          (contact) => contact?.type !== "phone",
          data?.contacts
        ),
        countries: data?.countries?.map((c) => c?.id),
        cities: data?.cities?.map((c) => c?.id),
      }}
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <Steps
            current={stage}
            className="mb-10"
            items={[
              {
                title: "Основная информация",
              },
              {
                title: "Услуги",
              },
              {
                title: "Публикация",
              },
            ]}
          />
          <Info visible={stage === 0} form={form} onNext={() => setStage(1)} />
          <Services
            visible={stage === 1}
            form={form}
            onPrev={() => setStage(0)}
            onNext={() => setStage(2)}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  );
}
