import { Modal, message } from "antd";
import { useState } from "react";
import { propEq, find } from "ramda";
import * as API from "@api/partner";
import PhoneInput from "react-phone-input-2";
import locale from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/style.css";

export default function Add({
  onCancel,
  open = false,
  onAfterAdd,
  phones = [],
}) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(null);

  const onNumberAdd = () => {
    if (find(propEq("contact", `+${phone}`), phones)) {
      return message.error("Такой номер телефона уже добавлен.", 3);
    }

    setLoading(true);

    API.CompanyContacts.create({
      contact: `+${phone}`,
      type: "phone",
    }).then((res) => {
      setLoading(false);
      if (res?.error || !res?.data?.id) {
        return message.error(
          "Не удалось добавить номер телефона, проверьте соединение с интернетом и попробуйте еще раз.",
          3
        );
      }

      message.success(`Номер +${phone} успешно добавлен.`, 2);
      onAfterAdd({ id: res.data.id, contact: res.data.contact });
      onCancel();
    });
  };

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      title="Добавить номер телефона"
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{
        type: "primary",
        block: true,
        className: "m-0",
        loading,
      }}
      okText="Добавить"
      width={350}
      onOk={onNumberAdd}
      centered
    >
      <PhoneInput
        disabled={loading}
        className="w-full my-5"
        inputClass="!w-full"
        localization={locale}
        country="kz"
        value={phone}
        onChange={setPhone}
        preferredCountries={[
          "kz",
          "ru",
          "ua",
          "az",
          "am",
          "kg",
          "tj",
          "tm",
          "uz",
        ]}
        priority={{ kz: 0, ru: 1 }}
        placeholder="Укажите ваш номер телефона"
      />
    </Modal>
  );
}
