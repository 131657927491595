export function packageLength(pkg) {
  switch (pkg.id) {
    case 1:
      return "7 дней";

    case 2:
      return "30 дней";

    case 3:
      return "90 дней";

    default:
      break;
  }
}

export function packageIcon(pkg) {
  switch (pkg.id) {
    case 1:
      return "/img/promotions/icon-start.svg";

    case 2:
      return "/img/promotions/icon-fast.svg";

    case 3:
    default:
      return "/img/promotions/icon-turbo.svg";
  }
}

export function tariffIcon(tariff) {
  switch (parseInt(tariff.service_code)) {
    case 102:
      return "/img/promotions/icon-hot.svg";

    case 103:
      return "/img/promotions/icon-interesting.svg";

    case 104:
      return "/img/promotions/icon-highlight.svg";

    case 105:
      return "/img/promotions/icon-raise.svg";

    default:
      return "/img/promotions/icon-highlight.svg";
  }
}

export function tariffDescription(tariff) {
  switch (parseInt(tariff.service_code)) {
    case 102:
      return {
        name: "Горячее",
        description:
          "Ваше объявление будет выведено на главную и иметь значок «Горячее».",
        img: "/img/promotions/tariffs/hot.png",
        icon: tariffIcon(tariff),
      };

    case 103:
      return {
        name: "Интересное",
        description:
          "Ваше объявление будет появляться в блоках рекомендации по всему сайту.",
        img: "/img/promotions/tariffs/interesting.png",
        icon: tariffIcon(tariff),
      };

    case 104:
      return {
        name: "Выделение цветом",
        description:
          "Блок с вашим объявлением будет выделен цветом среди других объявлений.",
        img: "/img/promotions/tariffs/highlight.png",
        icon: tariffIcon(tariff),
      };

    case 105:
      return {
        name: "Авто-поднятие",
        description:
          "Ваше объявление будет автоматически подниматься в поиске несколько раз в день.",
        img: "/img/promotions/tariffs/raise.png",
        icon: tariffIcon(tariff),
      };

    default:
      return null;
  }
}

export function packagePrices(pkg) {
  let total = 0;

  /* eslint-disable-next-line */
  pkg.tariffs.map((tariff) => {
    total += tariff?.prices[0]?.price || 0;
  });

  return new Intl.NumberFormat("ru-KZ", {
    style: "currency",
    currency: "kzt",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(total);
}

export function tariffPrice(tariff) {
  return new Intl.NumberFormat("ru-KZ", {
    style: "currency",
    currency: "kzt",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(tariff.prices[0].price);
}

export function tariffLength(tariff) {
  switch (tariff.unit) {
    case "hour":
      return `${tariff.quantity} часов`;

    case "day":
      return `${tariff.quantity} дней`;

    case "week":
      return `${tariff.quantity} недель`;

    case "month":
      return `${tariff.quantity} мес.`;

    default:
      return `${tariff.quantity} ${tariff.unit}`;
  }
}
