import { Button, Dropdown, Menu } from "antd";
import { GlobalOutlined, DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocale } from "@contexts/locale";

export default function LocaleSwitcher({ useDropdown = true, reload = true, ...props }) {
  const { t } = useTranslation();
  const { locale, switchLocale } = useLocale();
  console.log(locale);

  const onLocaleSwitch = ({ key }) => {
    if (key === locale) {
      return;
    }

    switchLocale({ locale: key, reload });
  };

  const items = [
    {
      key: "ru",
      label: "Русский"
    },
    {
      key: "kk",
      label: "Қазақша"
    },
    {
      key: "en",
      label: "English"
    }
  ];

  if (!useDropdown) {
    // return (
    //   <Button
    //     onClick={() =>
    //       onLocaleSwitch({ key: locale === "kk" ? "ru" : "kk", reload })
    //     }
    //     icon={<GlobalOutlined />}
    //     className="m-0"
    //     type="text"
    //     {...props}
    //   >
    //     {props?.title ||
    //       items?.find((item) => item.key === locale)?.label ||
    //       "Сменить язык"}
    //   </Button>
    // );
    const subItems = [
      {
        key: "lang",
        label: t("settings.language"),
        children: items
      }
    ];

    return (
      <Menu
        items={subItems}
        selectedKeys={[locale]}
        mode="inline"
        expandIcon={<DownOutlined />}
        onSelect={({ item, key }) => {
          onLocaleSwitch({ key });
        }}
        {...props}
      />
    );
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: onLocaleSwitch
      }}
      arrow
      {...props}
    >
      <Button icon={<GlobalOutlined />} className="m-0" type="text">
        {items?.find(item => item.key === locale)?.label || "Язык"}
      </Button>
    </Dropdown>
  );
}
