import classNames from "classnames";
import { Card, Button, Badge, Dropdown, Descriptions, Modal, Divider } from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getURL } from "@utils/imgproxy";
import { splitAt } from "ramda";
import config from "@config";

export default function CompanyItem({ className, data, onCompanyDestroy, onCompanyUnpublish }) {
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "edit":
        return navigate(`/companies/edit/${data?.company?.id}`);

      case "view":
        if (data?.company?.status === "published" && data?.company?.is_active) {
          window.location.href = `${config.frontend}/service/${data?.company?.id}/${data?.company?.slug}`;
        }

        return;

      case "unpublish":
        return Modal.confirm({
          okText: "Снять с сайта",
          okButtonProps: { danger: true },
          cancelText: "Отменить",
          title: "Снять компанию с сайта?",
          width: 500,
          maskClosable: true,
          content: (
            <span className="text-base">
              Вы действительно хотите снять компанию <strong>{data?.company?.title}</strong> с сайта? Профиль компании будет снят с сайта, вы можете
              отредактировать его позже.
            </span>
          ),
          onOk: () => onCompanyUnpublish({ id: data?.company?.id })
        });
      // return (window.location.href = `${config.frontend}/services/s/${data?.company?.id}/${data?.company?.slug}`);
      case "delete":
        return Modal.confirm({
          okText: "Удалить безвозвратно",
          okButtonProps: { danger: true },
          cancelText: "Отменить",
          title: "Удалить компанию?",
          width: 500,
          maskClosable: true,
          content: (
            <span className="text-base">
              Вы действительно хотите удалить компанию <strong>{data?.company?.title}</strong>? Профиль компании будет снят с сайта и удален безвозвратно.
            </span>
          ),
          onOk: () => onCompanyDestroy({ id: data?.company?.id })
        });

      default:
        break;
    }
  };

  const options = {
    onClick: handleMenuClick,
    items: [
      {
        label: "Редактировать",
        key: "edit",
        icon: <EditOutlined />
      },
      {
        disabled: data?.company?.status !== "published" || !data?.company?.is_active,
        label: "Посмотреть на сайте",
        key: "view",
        hidden: !data?.company?.is_active,
        icon: <EyeOutlined />
      },
      {
        type: "divider",
        key: "divider"
      },
      {
        disabled: data?.company?.status !== "published" || !data?.company?.is_active,
        label: "Снять с сайта",
        key: "unpublish",
        danger: true,
        icon: <CloseOutlined />
      },
      {
        label: "Удалить компанию",
        key: "delete",
        danger: true,
        hidden: !data?.company?.is_active,
        icon: <DeleteOutlined />
      }
    ]
  };

  const statusMap = status => {
    switch (status) {
      case "draft":
        return {
          badgeStatus: "default",
          badgeLabel: "Черновик"
        };

      case "published":
        return {
          badgeStatus: "success",
          badgeLabel: "На сайте"
        };

      case "unpublished":
        return {
          badgeStatus: "error",
          badgeLabel: "Снят с сайта"
        };

      case "in_review":
        return {
          badgeStatus: "warning",
          badgeLabel: "На проверке"
        };

      case "declined":
        return {
          badgeStatus: "error",
          badgeLabel: "Отклонено"
        };

      case "removed":
        return {
          badgeStatus: "error",
          badgeLabel: "Удалено"
        };

      default:
        return {
          badgeStatus: "default",
          badgeLabel: "Черновик"
        };
    }
  };

  return (
    <Card className={classNames("w-full my-6 md:my-10", className)} bodyStyle={{ marginTop: -40 }}>
      <div className="flex justify-start">
        <img
          className="w-20 h-20 rounded-md block mr-3 bg-white shadow-md"
          src={getURL(data?.company?.logo, { width: 100, height: 100 })}
          alt={data?.company?.title}
        />
        <div className="flex w-full justify-between items-start mt-7">
          <div>
            <span className="text-base font-medium block w-full text-black mb-1 leading-tight">{data?.company?.title}</span>
            <span className="text-sm block w-full text-neutral-400 leading-tight">
              {<Badge status={statusMap(data?.company?.status)?.badgeStatus} text={statusMap(data?.company?.status)?.badgeLabel} />} | {data?.category?.name}
            </span>
          </div>
          <Dropdown.Button
            disabled={data?.company?.status === "in_review"}
            className="hidden md:flex w-fit"
            menu={options}
            onClick={() => handleMenuClick({ key: "edit" })}
          >
            Редактировать
          </Dropdown.Button>
        </div>
      </div>
      <Button disabled={data?.company?.status === "in_review"} className="md:hidden mt-5" block type="primary" onClick={() => handleMenuClick({ key: "edit" })}>
        Редактировать
      </Button>
      <Button
        hidden={data?.company?.status !== "published" || !data?.company?.is_active}
        className="md:hidden mt-2"
        block
        type="link"
        onClick={() => handleMenuClick({ key: "view" })}
      >
        Посмотреть на сайте
      </Button>
      <Divider className="my-5 md:hidden" />
      <div className="flex flex-col justify-center gap-3">
        <Button
          hidden={data?.company?.status === "in_review"}
          danger
          className="md:hidden"
          block
          type="default"
          onClick={() => handleMenuClick({ key: "unpublish" })}
        >
          Снять с сайта
        </Button>
        <Button
          hidden={data?.company?.status === "in_review"}
          danger
          className="md:hidden"
          block
          type="primary"
          onClick={() => handleMenuClick({ key: "delete" })}
        >
          Удалить
        </Button>
      </div>
      <Descriptions layout="vertical" colon={false} size="small" column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }} className="w-full mt-5">
        <Descriptions.Item label="Страны присутствия">
          {data?.countries?.length > 2
            ? `${splitAt(2, data.countries)[0]
                .map(d => d.name)
                .join(",")}, +${data.countries.length - 2}`
            : data.countries.map(d => d.name).join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Города присутствия">
          {data?.cities?.length > 2
            ? `${splitAt(2, data.cities)[0]
                .map(d => d.name)
                .join(", ")}, +${data.cities.length - 2}`
            : data.cities.map(d => d.name).join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Добавленные услуги">{data?.company?.services?.length}</Descriptions.Item>
        <Descriptions.Item label="Добавленные фотографии">{data?.company?.image_list?.length}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}
