import { List, Card, Button } from "antd";
import { FileOutlined } from "@ant-design/icons";
import config from "@config";

export default function Files({ data, className }) {
  return (
    <Card bordered={false} title="Прикрепленные файлы" className={className}>
      <List
        className="w-full p-0"
        dataSource={data?.product?.files_list || []}
        renderItem={(item, key) => (
          <List.Item key={key} className="px-0">
            <Button
              target="_blank"
              className="m-0 p-0 bg-transparent border-none w-fit h-fit"
              type="link"
              icon={<FileOutlined />}
              href={`${config.s3.endpoint}${item.url}`}
            >
              {item?.caption || `Документ ${key + 1}`}
            </Button>
          </List.Item>
        )}
      />
    </Card>
  );
}
