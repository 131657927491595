import { Card } from "antd";
import { Breadcrumb } from "@components";
import { Users, Listings } from "@modules/moderator";
import { useLoaderData, useParams } from "react-router-dom";

export default function User() {
  const { id } = useParams();
  const initialData = useLoaderData();

  return (
    <div>
      <Breadcrumb
        showBack
        data={[
          { title: "Пользователи", href: "/users" },
          {
            title: "Данные пользователя",
            href: `/users/view/${id}`,
          },
        ]}
      />
      <Users.View intialData={initialData} />

      <Card
        className="mt-6"
        bordered="false"
        title="Объявления пользователя: Бизнес"
      >
        <Listings.List
          showHeader={false}
          showFilters={[]}
          defaultFilterValues={{ user_id: id }}
          type="sale"
        />
      </Card>

      <Card
        className="mt-6"
        bordered="false"
        title="Объявления пользователя: Франшизы"
      >
        <Listings.List
          showHeader={false}
          showFilters={[]}
          defaultFilterValues={{ user_id: id }}
          type="franchise"
        />
      </Card>

      <Card
        className="mt-6"
        bordered="false"
        title="Объявления пользователя: Инвестиции"
      >
        <Listings.List
          showHeader={false}
          showFilters={[]}
          defaultFilterValues={{ user_id: id }}
          type="investment"
        />
      </Card>
    </div>
  );
}
