import {
  UnorderedListOutlined,
  BankOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  FundViewOutlined,
  LogoutOutlined,
  DashboardOutlined,
  CreditCardOutlined,
  WarningOutlined,
  ReadOutlined
} from "@ant-design/icons";
import pkg from "@base/package.json";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import config from "@config";

const { Header, Content, Footer, Sider } = Layout;

export default function Moderator({ children, page, ...props }) {
  const { currentUser } = useAuth();

  return (
    <Layout hasSider={false} style={{ minHeight: "100vh" }}>
      <Header
        className="bg-white shadow-sm flex justify-between items-center p-5 z-50"
        style={{
          height: 70,
          position: "fixed",
          // top: 0,
          zIndex: 1,
          width: "100%"
        }}
      >
        <div className="flex justify-start items-center">
          <img src="/img/logo.svg" className="block" width={110} height={28} alt="Sdelka.kz" />
        </div>
        <Dropdown
          menu={{
            className: "min-w-44 max-w-xs",
            items: [
              {
                key: "profile",
                type: "group",
                label: (
                  <div className="w-full p-0">
                    <span className="break-words block mb-1 w-full text-neutral-400 font-normal text-xs">
                      ID {currentUser.id} &middot; {currentUser.email}
                    </span>
                  </div>
                )
              },
              {
                key: "divider",
                type: "divider"
              },
              {
                key: "logout",
                icon: <LogoutOutlined />,
                label: (
                  <Link className="transition-none" to="/logout">
                    Выйти
                  </Link>
                ),
                danger: true
              }
            ]
          }}
        >
          <div className="flex items-center">
            <Avatar
              src={currentUser?.avatar && `${config.s3.endpoint}${currentUser.avatar}`}
              style={{ cursor: "pointer" }}
              icon={<UserOutlined />}
              size="large"
            />
            <div className="flex flex-col justify-center items-start ml-3 text-left cursor-pointer">
              <span className="text-base font-medium text-slate-900 leading-none m-0">{currentUser?.name}</span>
              <span className="text-sm text-neutral-400 leading-none mt-1">Модератор</span>
            </div>
          </div>
        </Dropdown>
      </Header>
      <Layout
        hasSider={false}
        className="ml-16 lg:ml-52"
        style={{
          marginTop: 70,
          backgroundColor: "#fafafb"
        }}
      >
        <Sider
          className="bg-white pt-5"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            bottom: 0
          }}
          breakpoint="lg"
          collapsedWidth="64"
          width={208}
        >
          <div
            style={{
              height: 32,
              margin: 16
            }}
          />
          <Menu
            mode="inline"
            selectedKeys={[page]}
            items={[
              {
                key: "dashboard",
                icon: <DashboardOutlined />,
                label: (
                  <Link className="transition-none" to="/">
                    Аналитика
                  </Link>
                )
              },
              {
                key: "listings",
                icon: <ShopOutlined />,
                label: (
                  <Link className="transition-none" to="/listings">
                    Объявления
                  </Link>
                )
              },
              {
                key: "companies",
                icon: <BankOutlined />,
                label: (
                  <Link className="transition-none" to="/companies">
                    Компании
                  </Link>
                )
              },
              {
                key: "promotions",
                icon: <FundViewOutlined />,
                label: "Продвижения",
                children: [
                  {
                    key: "promotions",
                    label: (
                      <Link className="transition-none" to="/promotions">
                        Внутренние
                      </Link>
                    )
                  },
                  {
                    key: "external-promotions",
                    label: (
                      <Link className="transition-none" to="/promotions/external">
                        Внешние
                      </Link>
                    )
                  }
                ]
              },
              {
                key: "payments",
                icon: <CreditCardOutlined />,
                label: (
                  <Link className="transition-none" to="/payments">
                    Платежи
                  </Link>
                )
              },
              {
                key: "reports",
                icon: <WarningOutlined />,
                label: (
                  <Link className="transition-none" to="/reports">
                    Жалобы
                  </Link>
                )
              },
              {
                key: "users",
                icon: <TeamOutlined />,
                label: (
                  <Link className="transition-none" to="/users">
                    Пользователи
                  </Link>
                )
              },
              {
                key: "blog",
                icon: <ReadOutlined />,
                label: "Блог",
                children: [
                  {
                    key: "articles",
                    label: (
                      <Link className="transition-none" to="/blog/articles">
                        Материалы
                      </Link>
                    )
                  },
                  {
                    key: "tags",
                    label: (
                      <Link className="transition-none" to="/blog/tags">
                        Теги
                      </Link>
                    )
                  },
                  {
                    key: "blog-categories",
                    label: (
                      <Link className="transition-none" to="/blog/categories">
                        Рубрики
                      </Link>
                    )
                  }
                ]
              },
              {
                key: "dict",
                icon: <UnorderedListOutlined />,
                label: "Справочник",
                children: [
                  {
                    key: "countries",
                    label: (
                      <Link className="transition-none" to="/dict/countries">
                        Страны
                      </Link>
                    )
                  },
                  {
                    key: "cities",
                    label: (
                      <Link className="transition-none" to="/dict/cities">
                        Города
                      </Link>
                    )
                  },
                  {
                    key: "categories",
                    label: (
                      <Link className="transition-none" to="/dict/categories">
                        Категории
                      </Link>
                    )
                  },
                  {
                    key: "orgtypes",
                    label: (
                      <Link className="transition-none" to="/dict/orgtypes">
                        Виды организаций
                      </Link>
                    )
                  },
                  {
                    key: "options",
                    label: (
                      <Link className="transition-none" to="/dict/options">
                        Опции объявления
                      </Link>
                    )
                  }
                ]
              }
            ]}
          />
        </Sider>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial"
          }}
        >
          <div className="bg-none p-0 md:lg-p-3 lg:p-6">{children}</div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#fafafb",
            textAlign: "center"
          }}
        >
          Musan Digital © {new Date().getFullYear()} | Sdelka.kz | Версия {pkg.version}
        </Footer>
      </Layout>
    </Layout>
  );
}
