import { useEffect, useState } from "react";
import { Form, message } from "antd";
import * as API from "@api/customer";
import { Steps, TypeSelector, Info, SalesDetails, FranchiseDetails, InvestmentDetails, Publish } from "../components";
import { useTranslation } from "react-i18next";
import { useAuth } from "@contexts";
import { pathOr } from "ramda";

export default function NewListing({ isApp = false }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [stage, setStage] = useState(0);
  const [productId, setProductId] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [stage]);

  const onFormSubmit = async values => {
    const type = form.getFieldValue("type");
    const contacts = form.getFieldValue("contacts") || [];
    const logo = form.getFieldValue([type, "logo"]) || null;

    const api = type === "franchise" ? API.Franchises : type === "investment" ? API.Investments : API.Sales;

    setLoading(true);
    const { image_list = [], file_list = [] } = form.getFieldsValue(["image_list", "file_list"]);

    if (image_list.length === 0) {
      setLoading(false);
      return message.error(t("listings.photoPrompt"), 3);
    }

    if (contacts.length === 0) {
      setLoading(false);
      return message.error(t("listings.phonePrompt"), 3);
    }

    // Prepare Product data
    const product = {
      title: values.title.trim(),
      contacts: contacts.map(({ id }) => ({ id })),
      subtitle: values.title,
      display_name: values?.display_name || "Sdelka.kz",
      description: pathOr("", ["description"], values),
      description_short: "",
      price: values.price,
      price_max: pathOr(null, ["price_max"], values),
      is_price_range: pathOr(false, ["is_price_range"], values),
      category_id: values.category_id[values.category_id.length - 1],
      image_main: image_list[0],
      country_id: values.country_id,
      city_id: values.city_id,
      website: pathOr(null, ["website"], values) || null,
      image_list,
      meta: pathOr(null, ["meta"], values),
      files_list: file_list,
      options: pathOr([], ["options"], values).map(JSON.parse)
    };

    let payload = {};

    // Prepare payload data depending on type of listing
    switch (type) {
      case "sale":
        payload = {
          month_revenue: pathOr(null, ["sale", "month_revenue"], values),
          month_costs: pathOr(null, ["sale", "month_costs"], values),
          month_profit: pathOr(null, ["sale", "month_profit"], values),
          payback_period: pathOr(null, ["sale", "payback_period"], values),
          revenue_confirmation: pathOr([], ["sale", "revenue_confirmation"], values),
          staff_qty: pathOr(1, ["sale", "staff_qty"], values),
          sell_percentage: pathOr(null, ["sale", "sell_percentage"], values),
          is_active: pathOr(null, ["sale", "is_active"], values),
          foundation_year: pathOr(null, ["sale", "foundation_year"], values),
          org_type_id: pathOr(null, ["sale", "org_type_id"], values),
          description: pathOr("", ["description"], values),
          sell_reason: pathOr(null, ["sale", "sell_reason"], values),
          bin: String(pathOr("", ["sale", "bin"], values)),
          display_name: values?.display_name || "Sdelka.kz"
        };
        break;

      case "franchise":
        payload = {
          lump_sum: pathOr(0, ["franchise", "lump_sum"], values),
          is_lump_range: pathOr(false, ["franchise", "is_lump_range"], values),
          lump_max: pathOr(null, ["franchise", "lump_max"], values),
          is_lump_included: pathOr(false, ["franchise", "is_lump_included"], values),
          royalty_value: pathOr(null, ["franchise", "royalty_value"], values),
          royalty_type: pathOr(null, ["franchise", "royalty_type"], values),
          month_profit: pathOr(null, ["franchise", "month_profit"], values),
          payback_period: pathOr(null, ["franchise", "payback_period"], values),
          description: pathOr("", ["description"], values),
          display_name: values?.display_name || "Sdelka.kz",
          logo
        };
        break;

      case "investment":
        payload = {
          year_profit_rate_min: pathOr(0, ["investment", "year_profit_rate_min"], values),
          year_profit_rate_max: pathOr(0, ["investment", "year_profit_rate_max"], values),
          stage: pathOr(null, ["investment", "stage"], values),
          description: pathOr("", ["description"], values),
          display_name: values?.display_name || "Sdelka.kz",
          logo
        };
        break;

      default:
        return message.error(t("listings.sendingError"), 2);
    }

    const _res = await api.create({ product, [type]: payload });
    setLoading(false);

    if (!_res || _res.error) {
      return message.error(t("listings.sendingError"), 3);
    }

    setProductId(_res?.data?.product?.id || null);

    setStage(3);
    return message.success(t("listings.sentToReview"), 5);
  };

  return (
    <Form
      form={form}
      className="global-form"
      layout="vertical"
      scrollToFirstError
      onFinish={onFormSubmit}
      onFinishFailed={({ errorFields }) => {
        errorFields.forEach(({ errors }) => {
          errors.forEach(error => {
            message.error(error, 3);
          });
        });
      }}
      size={isApp ? "large" : "middle"}
      initialValues={{
        country_id: 1,
        type: null,
        is_price_range: false,
        image_list: [],
        contacts: [],
        display_name: currentUser?.name || "",
        meta: {
          published_by_broker: false,
          location: {
            lat: "",
            lon: "",
            pos: "",
            city: "",
            country: "",
            address: ""
          }
        }
      }}
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-4">
          <Steps
            direction={isApp ? "horizontal" : "vertical"}
            form={form}
            current={stage}
            setStage={setStage}
            responsive={!isApp}
            showDescription={!isApp}
            showTitle={!isApp}
          />
        </div>
        <div className="col-span-12 lg:col-span-8">
          <TypeSelector visible={stage === 0} form={form} stage={stage} setStage={setStage} isApp={isApp} />
          <Info visible={stage === 1} form={form} stage={stage} setStage={setStage} isApp={isApp} />
          {form.getFieldValue("type") === "sale" && (
            <SalesDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} loading={loading} isApp={isApp} />
          )}
          {form.getFieldValue("type") === "franchise" && (
            <FranchiseDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} loading={loading} isApp={isApp} />
          )}
          {form.getFieldValue("type") === "investment" && (
            <InvestmentDetails visible={stage === 2} form={form} stage={stage} setStage={setStage} loading={loading} isApp={isApp} />
          )}
          <Publish productId={productId} visible={stage === 3} form={form} stage={stage} setStage={setStage} isApp={isApp} />
        </div>
      </div>
    </Form>
  );
}
