export const REASON_TITLES = {
  inaccurate_or_misleading_information: "Обман или ложное объявление",
  offensive_or_inappropriate_content: "Оскорбительный или неприемлемый контент",
  suspicious_or_scam_activity: "Подозрительная или мошенническая активность",
  duplicate_or_repetitive_listings: "Дубликаты или повторяющиеся объявления",
  violation_of_intellectual_property_rights:
    "Нарушение прав интеллектуальной собственности",
  unresponsive_listing_owner: "Владелец не отвечает",
  unverified_or_unreliable_information:
    "Непроверенная или недостоверная информация",
  outdated_or_inactive_listing: "Устаревшее или неактивное объявление",
  violation_of_website_terms_of_service: "Нарушение правил пользования сайтом",
  other_concerns: "Другая причина",
};
