import { OfflineWidget } from "@modules";
import { Blog } from "@modules/moderator";
import { useParams } from "react-router-dom";

export default function Page() {
  const { id } = useParams();

  return (
    <div>
      <h2 className="mb-5 lg:mb-8 text-xl lg:text-3xl">Редактировать материал</h2>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Blog.Articles.Edit id={id} />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
