import { Tariffs } from "@modules/customer";

export default function Promote({ listing, tariffsData }) {
  return (
    <div className="promote-page">
      <div className="container content-max-promote py-5">
        <div className="layout-card p-5 lg:p-9">
          <Tariffs.ProductCard listing={listing} />
          <Tariffs.Additional listing={listing} data={tariffsData} />
        </div>
      </div>
    </div>
  );
}
