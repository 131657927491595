import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Favorites } from "@modules/customer";

export default function FavoritesPage() {
  const initialData = useLoaderData();
  const { t } = useTranslation();

  return (
    <div className="my-5">
      <h2 className="mb-5">{t("nav.favorites")}</h2>
      <Favorites initialData={initialData} />
    </div>
  );
}
