import { useEffect, useState } from "react";
import { Form, message, Steps } from "antd";
import { Info, Services } from "../components";
import * as API from "@api/partner";
import { useAuth } from "@contexts";
import { pathOr } from "ramda";
import { Navigate } from "react-router-dom";

export default function NewCompany({ isApp = false }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [stage, setStage] = useState(0);

  useEffect(() => {
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [stage]);

  const onFormSubmit = (values) => {
    setLoading(true);
    const services = form.getFieldValue("services") || [];
    const image_list = form.getFieldValue("image_list") || [];
    const image_main = image_list.length > 0 ? image_list[0] : null;
    const logo = form.getFieldValue("logo");
    const phones = form.getFieldValue("phones");
    const messengers = form.getFieldValue("messengers");

    if (!services || services.length === 0) {
      setLoading(false);
      return message.error("Необходимо добавить хотя бы одну услугу", 2);
    }

    API.Companies.create({
      title: pathOr(null, ["title"], values),
      subtitle: pathOr(null, ["subtitle"], values),
      category_id: pathOr(null, ["category_id"], values),
      description: pathOr(null, ["description"], values),
      image_list: form.getFieldValue("image_list"),
      image_main,
      logo,
      services,
    })
      .then(async (res) => {
        if (!res || res?.error || !res?.data?.company?.id) {
          return message.error(
            "Ошибка создания компании, проверьте соединение с интернетом и попробуйте еще раз",
            2
          );
        }

        const { id } = res?.data?.company;

        await Promise.all([
          API.Companies.updateCountries(id, pathOr([], ["countries"], values)),
          API.Companies.updateCities(id, pathOr([], ["cities"], values)),
          API.Companies.updateContacts(id, [
            ...phones.map((phone) => phone.id),
            ...messengers.map((messenger) => messenger.id),
          ]),
        ])
          .then(([countries, cities, contacts]) => {
            setLoading(false);
            if (!countries || countries?.error) {
              return message.error(
                "Ошибка обновления стран, попробуйте еще раз или обратитесь к нам: info@sdelka.kz",
                3
              );
            }

            if (!cities || cities?.error) {
              return message.error(
                "Ошибка обновления городов, попробуйте еще раз или обратитесь к нам: info@sdelka.kz",
                3
              );
            }

            if (!contacts || contacts?.error) {
              return message.error(
                "Ошибка обновления контактов, попробуйте еще раз или обратитесь к нам: info@sdelka.kz",
                3
              );
            }

            setStage(2);
            message.success("Компания успешно создана", 3);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            return message.error(
              "Ошибка создания компании, проверьте соединение с интернетом",
              3
            );
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return message.error(
          "Ошибка создания компании, проверьте соединение с интернетом",
          3
        );
      });
  };

  if (stage === 2) {
    return <Navigate to="/" />;
  }

  return (
    <Form
      form={form}
      className="global-form"
      layout="vertical"
      scrollToFirstError
      onFinish={onFormSubmit}
      onErrorCapture={console.log}
      onFinishFailed={console.log}
      size={isApp ? "large" : "middle"}
      initialValues={{
        country_id: 1,
        type: null,
        is_price_range: false,
        image_list: [],
        contacts: [],
        display_name: currentUser?.name || "",
      }}
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12">
          <Steps
            current={stage}
            className="mb-10"
            items={[
              {
                title: "Основная информация",
              },
              {
                title: "Услуги",
              },
              {
                title: "Публикация",
              },
            ]}
          />
          <Info visible={stage === 0} form={form} onNext={() => setStage(1)} />
          <Services
            visible={stage === 1}
            form={form}
            onPrev={() => setStage(0)}
            onNext={() => setStage(2)}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  );
}
