import { Card, Modal, Button, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Payments } from "@modules/moderator";
import { useState } from "react";
import Fade from "react-reveal/Fade";

export default function PaymentsPage() {
  const [newPayment, setNewPayment] = useState(false);

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">Платежи</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Card
              bordered={false}
              className="h-full"
              extra={
                <Popconfirm
                  placement="left"
                  okText="Да"
                  title="Вы уверены?"
                  onConfirm={() => setNewPayment(true)}
                  description={() => (
                    <span className="block text-sm max-w-xs">
                      Только для безналичных платежей. Новый платеж
                      автоматически активирует услугу.
                    </span>
                  )}
                >
                  <Button icon={<PlusOutlined />}>Новый платеж</Button>
                </Popconfirm>
              }
            >
              <Payments.List />
            </Card>
          </div>
        </div>
      </Fade>
      <Modal
        footer={null}
        destroyOnClose
        onCancel={() => setNewPayment(false)}
        open={newPayment}
        title="Новый платеж"
      >
        <Payments.New />
      </Modal>
    </div>
  );
}
