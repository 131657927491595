import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { message, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { RecaptchaVerifier } from "firebase/auth";
import {
  sendCode,
  confirmPhone,
  SMSCsendCode,
  SMSCconfirmPhone,
  whatsappSendCode,
  whatsappConfirmCode,
} from "@api/auth";
import { auth } from "@utils/firebase";
import { useTranslation } from "react-i18next";

import * as API from "@api/customer";

import { verifyTypes } from "../typesVerify";

export default function VerifyWithFirebase({
  phone,
  onCancel,
  onSelect,
  defaultVerifyType = verifyTypes.firebase,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [regStatus, setRegStatus] = useState(null);
  const [otp, setOTP] = useState("");
  const phoneSanitized = `+${phone.replace(/\D/g, "")}`;
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [sendTypeId, setSendType] = useState(defaultVerifyType);

  useEffect(() => {
    if (!recaptchaVerifier) {
      setRecaptchaVerifier(
        new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
        )
      );
    }
  }, [recaptchaVerifier]);

  useEffect(() => {
    if (recaptchaVerifier) {
      onSMSSend();
    }
    /* eslint-disable-next-line */
  }, [recaptchaVerifier]);

  useEffect(() => {
    if (!loading && otp.length === 6) {
      onOTPVerify();
    }
    /* eslint-disable-next-line */
  }, [otp]);

  useEffect(() => {
    if (regStatus && regStatus?.allowResend) {
      onSMSSend();
    }
    /* eslint-disable-next-line */
  }, [sendTypeId]);

  // Set limit for 60 secs before sending another request
  useEffect(() => {
    if (regStatus && !regStatus?.allowResend) {
      let time = setTimeout(() => {
        if (regStatus?.timePast >= 59) {
          return setRegStatus({
            ...regStatus,
            timePast: 0,
            allowResend: true,
          });
        }

        setRegStatus({ ...regStatus, timePast: regStatus?.timePast + 1 });
      }, 1000);

      return () => clearTimeout(time);
    }
  }, [regStatus]);

  const reSendCode = () => {
    if (sendTypeId === verifyTypes.whatsapp) {
      onSMSSend();
    } else {
      reSendWithSms();
    }
  };
  const reSendWithSms = () => {
    setSendType(
      sendTypeId === verifyTypes.smsc ? verifyTypes.firebase : verifyTypes.smsc
    );
  };
  const reSendWithWhatsapp = () => {
    setSendType(verifyTypes.whatsapp);
  };
  const onSMSSend = async () => {
    setLoading(true);

    // Check for existing number
    let existing = await API.Contacts.getByPhone({
      phone: phoneSanitized,
    });

    // If number is already confirmed
    if (existing && existing?.data?.phone_confirmed) {
      onCancel();
      setLoading(false);
      return message.success(t("contacts.alreadyVerified"), 3);
    }

    // If no existing number, create it
    if (!existing || existing?.error) {
      existing = await API.Contacts.create({ phone: phoneSanitized });

      if (!existing?.data?.id) {
        setLoading(false);
        return message.error(t("contacts.errorAdding"), 3, onCancel);
      }
    }

    const token = await recaptchaVerifier.verify().catch(console.log);

    if (!token) {
      setLoading(false);
      return message.error(t("contacts.errConnection"), 3, onCancel);
    }

    let sent;
    if (sendTypeId === verifyTypes.whatsapp) {
      sent = await whatsappSendCode({ id: existing.data.id });
    }
    // if simple verify
    else if (sendTypeId === verifyTypes.smsc) {
      sent = await SMSCsendCode({ id: existing.data.id });
    }
    // if firebase verify
    else {
      sent = await sendCode({ token, id: existing.data.id });
    }

    if (sent?.status !== 200) {
      setLoading(false);
      return message.error(t("contacts.tryLater"), 3, onCancel);
    }

    message.info(`${t("contacts.sentToNumber")}: ${phoneSanitized}.`, 5);
    setRegStatus({
      timePast: 0,
      lastRequestedAt: new Date(),
      allowResend: false,
    });
    setLoading(false);
  };

  const onOTPVerify = async () => {
    setLoading(true);

    let existing = await API.Contacts.getByPhone({
      phone: phoneSanitized,
    });

    if (!existing?.data?.id) {
      setLoading(false);
      return message.error(t("contacts.errorVerify"), 3);
    }

    let confirm;
    // if sms is whatsapp
    if (sendTypeId === verifyTypes.whatsapp) {
      confirm = await whatsappConfirmCode({ code: otp, id: existing.data.id });
    }
    // if sms is simple
    else if (sendTypeId === verifyTypes.smsc) {
      confirm = await SMSCconfirmPhone({ code: otp, id: existing.data.id });
    }
    // if sms is firebase
    else {
      confirm = await confirmPhone({ code: otp, id: existing.data.id });
    }

    if (confirm?.status !== 200) {
      setLoading(false);
      return message.error(t("contacts.invalidCode"), 3);
    }

    message.success(t("contacts.verifiedSuccess"), 3);
    setLoading(false);
    onSelect(existing.data);
  };

  return (
    <div>
      <div id="recaptcha-container"></div>
      <span className="text-base block font-medium text-black mb-5">
        {t("contacts.enterCode")}
      </span>

      <span className="text-base block font-normal text-black">
        {t("contacts.sentCode")}: {phoneSanitized}.
      </span>
      <span className="text-base block font-normal text-neutral-400">
        {t("contacts.enterCodeBelow")}
      </span>

      <span className="text-sm font-medium block w-full text-black mt-5">
        {t("contacts.enterCodeShort")}
      </span>
      <OtpInput
        className="otp-input my-2"
        numInputs={6}
        isInputNum
        isDisabled={loading}
        onChange={setOTP}
        value={otp}
      />
      <div className="flex justify-start items-center flex-wrap w-full">
        <span className="text-sm font-normal block text-neutral-500 mr-2">
          {t("contacts.didntReceive")}
        </span>
        <Button
          icon={<ReloadOutlined />}
          type="link"
          className="m-0 p-0"
          onClick={reSendCode}
          disabled={!regStatus?.allowResend}
        >
          {regStatus?.allowResend
            ? t("contacts.requestAgain")
            : `${t("contacts.requestAgain")} (${
                60 - regStatus?.timePast || 0
              } c.)`}
        </Button>
      </div>
      <div className="flex justify-start items-center flex-wrap w-full">
        <span className="text-sm font-normal block text-neutral-500 mr-2">
          {t("contacts.requestWith")}{" "}
          {sendTypeId === verifyTypes.whatsapp ? "SMS" : "WhatsApp"}
        </span>
        <Button
          icon={<ReloadOutlined />}
          type="link"
          className="m-0 p-0"
          onClick={() =>
            sendTypeId === verifyTypes.whatsapp
              ? reSendWithSms()
              : reSendWithWhatsapp()
          }
          disabled={!regStatus?.allowResend}
        >
          {regStatus?.allowResend
            ? t("contacts.requestAgain")
            : `${t("contacts.requestAgain")} (${
                60 - regStatus?.timePast || 0
              } c.)`}
        </Button>
      </div>
      <Button
        onClick={onOTPVerify}
        className="mt-10 py-2 h-auto"
        type="primary"
        block
        loading={loading}
      >
        {t("contacts.confirm")}
      </Button>
      <Button
        disabled={loading}
        onClick={onCancel}
        className="mt-3"
        danger
        type="link"
        block
      >
        {t("generic.cancel")}
      </Button>
    </div>
  );
}
