import { Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api/moderator";

export default function Categories(params) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.CompanyCategories.getAll({ limit: 200 }).then((res) =>
      setData(
        res?.data?.rows?.map((d) => ({
          ...d,
          value: d.id,
          label: d.name,
        }))
      )
    );
  }, []);

  return (
    <Select
      mode="multiple"
      loading={data?.length === 0}
      className="w-full"
      placeholder="Категории бизнеса"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(v) => params.onChange("category_id", v)}
      options={data}
    />
  );
}
