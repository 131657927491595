import { Input } from "antd";

export default function Search({ onSearch }) {
  return (
    <Input.Search
      placeholder="Поиск по названию"
      allowClear
      onSearch={(v) => onSearch(v)}
      enterButton="Найти"
    />
  );
}
