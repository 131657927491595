import { Select } from "antd";
import { statusMap } from "../../";

export default function Status(params) {
  return (
    <Select
      mode="multiple"
      className="w-full"
      placeholder="Статус"
      allowClear
      showSearch
      filterOption={(input, option) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
      onChange={v => params.onChange("status", v)}
      options={[
        {
          label: "Черновик",
          value: "draft"
        },
        {
          label: "Требует модерации",
          value: "in_review"
        },
        {
          label: "На сайте",
          value: "published"
        },
        {
          label: "Снят с сайта",
          value: "unpublished"
        },
        {
          label: "Отклонен модератором",
          value: "declined"
        }
      ]}
    />
  );
}
