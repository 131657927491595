import {
  Card,
  Form,
  Input,
  Select,
  Divider,
  InputNumber,
  Collapse,
  Tag,
  Tooltip,
  Alert,
} from "antd";
import Fade from "react-reveal/Fade";
import Contacts from "./contacts";
import { Products } from "@utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export default function SalesDetails({
  loading,
  setStage,
  form,
  visible = false,
  isApp = false,
}) {
  const { t } = useTranslation();
  const [paybackAdjusted, setPaybackAdjusted] = useState(false);
  const onBackClick = () => {
    setStage(1);
  };

  const getFormattedSum = () => {
    try {
      return form.getFieldValue("is_price_range")
        ? `${new Intl.NumberFormat("kk-KZ").format(
            parseInt(form.getFieldValue("price"))
          )} ~ ${new Intl.NumberFormat("kk-KZ").format(
            parseInt(form.getFieldValue("price_max"))
          )}`
        : new Intl.NumberFormat("kk-KZ").format(
            parseInt(form.getFieldValue("price"))
          );
    } catch (error) {
      return form.getFieldValue("is_price_range")
        ? `${form.getFieldValue("price")} ~ ${form.getFieldValue("price_max")}`
        : form.getFieldValue("price");
    }
  };

  return (
    <Fade spy={visible} exit={false} duration={300} distance="5px" bottom>
      <Card
        hidden={!visible}
        className={classNames("shadow-lg shadow-gray-100 transition-all", {
          "app-card": isApp,
        })}
      >
        <h2 className="w-full text-center my-1">
          {t("listings.extraInfoTitle")}
        </h2>
        <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-10">
          {t("listings.extraInfoText")}
        </span>

        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.orgFormTitle")}
              help={t("listings.orgFormHelp")}
              name={["sale", "org_type_id"]}
              rules={[
                {
                  required: true,
                  message: t("listings.orgFormPrompt"),
                },
              ]}
            >
              <Select placeholder={t("generic.choose")}>
                <Select.Option value={1}>ТОО</Select.Option>
                <Select.Option value={2}>ИП</Select.Option>
                <Select.Option value={3}>АО</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.staffTitle")}
              help={t("listings.staffHelp")}
              name={["sale", "staff_qty"]}
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 1,
                  message: t("listings.staffPrompt"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                min={1}
                placeholder={t("listings.staffPlaceholder")}
                addonAfter="чел."
              />
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.estYearTitle")}
              help={t("listings.estYearHelp")}
              name={["sale", "foundation_year"]}
              rules={[
                {
                  required: true,
                  message: t("listings.estYearPrompt"),
                },
              ]}
            >
              <InputNumber
                className="w-full"
                controls={false}
                maxLength={4}
                minLength={4}
                min={1900}
                max={new Date().getFullYear()}
                addonAfter="г."
                placeholder={t("listings.estYearPlaceholder")}
              />
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.isBizActiveTitle")}
              help={t("listings.isBizActiveHelp")}
              name={["sale", "is_active"]}
              rules={[
                {
                  required: true,
                  message: t("listings.isBizActivePrompt"),
                },
              ]}
            >
              <Select placeholder={t("generic.choose")}>
                <Select.Option value={true}>{t("generic.yes")}</Select.Option>
                <Select.Option value={false}>{t("generic.no")}</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-12">
            <Divider className="mt-2" />
          </div>
          <div className="col-span-12">
            <Collapse activeKey={["info"]}>
              <Collapse.Panel
                key="info"
                forceRender
                header={
                  <span className="text-neutral-700 m-0 block">
                    {t("listings.extraInfoTitle")}{" "}
                    <Tag className="m-0 ml-2" color="green">
                      {t("listings.sellFaster")}
                    </Tag>
                  </span>
                }
                extra={
                  <Tooltip title={t("listings.sellFasterTooltip")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              >
                <p className="p-5 mb-5 block bg-neutral-50 rounded-lg text-neutral-600">
                  <InfoCircleOutlined className="mr-2" />
                  {t("listings.sellFasterHelp")}
                </p>
                <div className="grid grid-cols-12 gap-x-4">
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.revenueTitle")}
                      help={t("listings.revenueHelp")}
                      name={["sale", "month_revenue"]}
                      requiredMark="optional"
                      rules={[
                        {
                          required: true,
                          message: t("listings.revenuePrompt"),
                        },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        onChange={function (revenue) {
                          Products.calculatePaybackPeriod({ form, revenue });
                        }}
                        className="w-full"
                        formatter={(value) =>
                          `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        controls={false}
                        placeholder={t("listings.revenuePlaceholder")}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.costsTitle")}
                      name={["sale", "month_costs"]}
                      help={t("listings.costsHelp")}
                      requiredMark="optional"
                      rules={[
                        {
                          required: true,
                          message: t("listings.costsPrompt"),
                        },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        onChange={(costs) =>
                          Products.calculatePaybackPeriod({ form, costs })
                        }
                        className="w-full"
                        formatter={(value) =>
                          `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        controls={false}
                        placeholder={t("listings.costsPlaceholder")}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.profitTitle")}
                      name={["sale", "month_profit"]}
                      help={t("listings.profitHelp")}
                      requiredMark="optional"
                      rules={[
                        {
                          required: true,
                          message: t("listings.profitPrompt"),
                        },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        onChange={(profit) =>
                          Products.calculatePaybackPeriod({ form, profit })
                        }
                        className="w-full"
                        formatter={(value) =>
                          `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        controls={false}
                        placeholder={t("listings.profitPlaceholder")}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.paybackTitle")}
                      help={t("listings.paybackHelp")}
                      name={["sale", "payback_period"]}
                      requiredMark="optional"
                      rules={[
                        {
                          warningOnly: true,
                          validator: () =>
                            paybackAdjusted
                              ? Promise.reject(new Error())
                              : Promise.resolve(),
                        },
                        {
                          required: true,
                          type: "number",
                          message: t("listings.paybackPrompt"),
                        },
                      ]}
                      initialValue={0}
                    >
                      <InputNumber
                        onChange={function (payback) {
                          Products.onPaybackAdjust({
                            form,
                            payback,
                            setPaybackAdjusted,
                          });
                        }}
                        className="w-full"
                        controls={false}
                        type="number"
                        addonAfter="мес."
                        placeholder={t("listings.paybackPlaceholder")}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.revConfirmationTitle")}
                      help={t("listings.revConfirmationHelp")}
                      name={["sale", "revenue_confirmation"]}
                      requiredMark="optional"
                      rules={[
                        {
                          type: "array",
                          required: true,
                          message: t("listings.revConfirmationPrompt"),
                        },
                      ]}
                      initialValue={["Другое"]}
                    >
                      <Select
                        mode="tags"
                        placeholder={t("listings.revConfirmationPlaceholder")}
                      >
                        <Select.Option value="1C">1C</Select.Option>
                        <Select.Option value="R-Keeper">R-Keeper</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-span-12 lg:col-span-4">
                    <Form.Item
                      label={t("listings.binTitle")}
                      help={t("listings.binHelp")}
                      name={["sale", "bin"]}
                      requiredMark="optional"
                      rules={[
                        {
                          required: true,
                          len: 12,
                          min: 12,
                          max: 12,
                          // transform: (value) => value.toString(),
                          pattern: /^[0-9]*$/,
                          message: t("listings.binPrompt"),
                        },
                      ]}
                      initialValue="000000000000"
                    >
                      <Input
                        type="tel"
                        controls={false}
                        maxLength={12}
                        minLength={12}
                        min={12}
                        max={12}
                        className="w-full"
                        placeholder={t("listings.binPlaceholder")}
                      />
                    </Form.Item>
                  </div>
                </div>
                {paybackAdjusted && (
                  <Alert
                    className="w-full mt-5"
                    type="warning"
                    showIcon
                    message={t("listings.miscalcError")}
                  />
                )}
              </Collapse.Panel>
            </Collapse>
          </div>
          <div className="col-span-12">
            <Divider className="mt-2">{t("listings.shareTitle")}</Divider>
            <span className="block font-medium text-center max-w-2xl w-fit mx-auto">
              {t("listings.shareHelp")}
            </span>
            <div className="flex flex-col mx-auto justify-center items-center mt-7 w-full max-w-xs p-5 rounded-lg bg-green-50 border-solid border-1 border-green-300">
              <span className="font-bold text-lg text-black">
                ₸ {getFormattedSum()}
              </span>
              <Divider className="text-xs">{t("listings.shareFor")}</Divider>
              <Form.Item
                className="mt-2 w-full"
                style={{ marginBottom: 0, maxWidth: 90 }}
                name={["sale", "sell_percentage"]}
                rules={[
                  {
                    required: true,
                    message: t("listings.sharePrompt"),
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={100}
                  size="large"
                  addonAfter="%"
                  keyboard={false}
                  controls={false}
                  placeholder="100"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Card>
      <Contacts
        isApp={isApp}
        visible={visible}
        form={form}
        loading={loading}
        onBackClick={onBackClick}
      />
    </Fade>
  );
}
