import axios from "axios";
import config from "@config";

const API = `${config.api}/moderator/blog`;

export const getArticles = params =>
  axios
    .get(`${API}/articles`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getArticle = params =>
  axios
    .get(`${API}/articles/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createArticle = data =>
  axios
    .post(`${API}/articles`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateArticle = data =>
  axios
    .post(`${API}/articles/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const publishArticle = data =>
  axios
    .post(`${API}/articles/publish`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const unpublishArticle = data =>
  axios
    .post(`${API}/articles/unpublish`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getLocales = params =>
  axios
    .get(`${API}/locales`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getLocale = params =>
  axios
    .get(`${API}/locales/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createLocale = data =>
  axios
    .post(`${API}/locales`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateLocale = data =>
  axios
    .post(`${API}/locales/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deleteLocale = data =>
  axios
    .delete(`${API}/locales/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getAuthors = params =>
  axios
    .get(`${API}/authors`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getAuthor = params =>
  axios
    .get(`${API}/authors/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createAuthor = data =>
  axios
    .post(`${API}/authors`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateAuthor = data =>
  axios
    .post(`${API}/authors/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deleteAuthor = data =>
  axios
    .delete(`${API}/authors/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getCategories = params =>
  axios
    .get(`${API}/categories`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getCategory = params =>
  axios
    .get(`${API}/categories/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createCategory = data =>
  axios
    .post(`${API}/categories`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateCategory = data =>
  axios
    .post(`${API}/categories/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deleteCategory = data =>
  axios
    .delete(`${API}/categories/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getTypes = params =>
  axios
    .get(`${API}/articles/types`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getType = params =>
  axios
    .get(`${API}/articles/types/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createType = data =>
  axios
    .post(`${API}/articles/types`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateType = data =>
  axios
    .post(`${API}/articles/types/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deleteType = data =>
  axios
    .delete(`${API}/articles/types/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getTags = params =>
  axios
    .get(`${API}/tags`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getTag = params =>
  axios
    .get(`${API}/tags/id/${params.id}`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const getTagArticles = params =>
  axios
    .get(`${API}/tags/id/${params.id}/articles`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const createTag = data =>
  axios
    .post(`${API}/tags`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const updateTag = data =>
  axios
    .post(`${API}/tags/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const deleteTag = data =>
  axios
    .delete(`${API}/tags/id/${data.id}`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
