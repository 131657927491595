import React, { useEffect, useState } from "react";
import { List, Avatar, message, Card } from "antd";
import * as API from "@api/moderator";
import { UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Imgproxy } from "@utils";
const limit = 4;
const IncomingCalls = ({ productId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setNewPage] = useState(1);
  useEffect(() => {
    if (data) {
      setLoading(false);
      return;
    }

    fetchData();
    /* eslint-disable-next-line */
  }, [data]);
  const fetchData = (offs = 0) => {
    return API.Analytics.getProductContactsByProductId({ productId, params: { limit: limit, offset: offs } }).then(res => {
      if (!res?.data || res?.error) {
        return message.error("Ошибка во время загрузки объявления. Попробуйте позже.", 3);
      }

      setData(res.data);
      setLoading(false);
    });
  };
  const updatePageProductContacts = page => {
    setNewPage(page);
    setLoading(true);
    fetchData(limit * (page - 1));
  };
  return (
    <Card
      title={
        <>
          Входящие звонки <span className="text-neutral-400">{data?.count || 0}</span>
        </>
      }
    >
      <List
        pagination={{
          bottom: true,
          align: "left",
          size: "small",
          pageSize: limit,
          defaultPageSize: limit,
          current: currentPage,
          onChange: updatePageProductContacts,
          total: data?.count || 0
        }}
        loading={loading}
        dataSource={data?.rows || []}
        renderItem={(item, idx) => (
          <List.Item key={"incoming-calls-idx-" + idx} className="px-0 py-2">
            <List.Item.Meta
              avatar={<Avatar size="large" icon={<UserOutlined />} src={Imgproxy.getURL(item.user?.avatar, { width: 100, height: 100 })} className="-mb-5" />}
              title={
                <a href={"/users/view/" + item.user.id} target="_blank" rel="noreferrer">
                  {item.user?.display_name}
                </a>
              }
              description={
                <span className="-mt-1 block">
                  {dayjs(item.date).format("DD MMM YYYY, в HH:mm")}&nbsp; ({dayjs(item.date).startOf("minutes").fromNow()})
                </span>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default IncomingCalls;
