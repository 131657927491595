import { Dictionary } from "@modules/moderator";
import Fade from "react-reveal/Fade";
import { useLoaderData } from "react-router-dom";

const DictMap = {
  categories: Dictionary.Categories.List,
  countries: Dictionary.Countries.List,
  cities: Dictionary.Cities.List,
  orgtypes: Dictionary.Orgtypes.List,
  options: Dictionary.Options.List,
};

export default function DictList() {
  const initialData = useLoaderData();
  const Component = DictMap[initialData?.type];

  return (
    <div>
      <h2 className="block text-xl text-black mb-5">{initialData?.title}</h2>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Component initialData={initialData} />
          </div>
        </div>
      </Fade>
    </div>
  );
}
