import { Select } from "antd";

export const services = [
  {
    value: "kapital",
    label: "Вывод на Kapital.kz",
    icon: "/img/promotions/kapital.svg",
  },
];

export default function Services(params) {
  return (
    <Select
      className="w-full"
      placeholder="Тариф"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("source", v)}
      options={services}
    />
  );
}
