import { Fragment } from "react";
import { Form, Select } from "antd";

export default function Locations(props) {
  return (
    <Fragment {...props}>
      <div className="col-span-12 lg:col-span-6">
        <Form.Item
          label="Страны"
          help="Можно выбрать несколько"
          name="countries"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите хотя бы одну страну.",
            },
          ]}
          initialValue={[1]}
        >
          <Select
            mode="multiple"
            placeholder="Выберите одну или несколько стран"
            options={props?.dict?.countries}
            showSearch
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              optionA.label
                .toLowerCase()
                .localeCompare(optionB.label.toLowerCase())
            }
          />
        </Form.Item>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Form.Item
          label="Города"
          placeholder="Выберите одну или несколько стран"
          help="Можно выбрать несколько"
          name="cities"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите хотя бы один город.",
            },
          ]}
        >
          <Select
            mode="multiple"
            options={props?.dict?.cities}
            showSearch
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              optionA.label
                .toLowerCase()
                .localeCompare(optionB.label.toLowerCase())
            }
          />
        </Form.Item>
      </div>
    </Fragment>
  );
}
