import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api/moderator";

export default function FormInput(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    API.Blog.getLocales({ limit: 100 }).then(res => {
      if (res.error || !res) {
        return;
      }

      setData(res?.data?.rows || []);
    });
  }, []);

  return (
    <Form.Item
      {...props}
      name="locale_id"
      className="w-full"
      label="Язык материала"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите язык"
        }
      ]}
    >
      <Select
        placeholder="Выберите"
        className="w-full"
        showSearch
        filterOption={(input, option) => option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
        options={data.map(val => {
          return {
            label: val.name,
            value: val.id
          };
        })}
      />
    </Form.Item>
  );
}
