import { Statistic } from "antd";
import { Loading } from "@components";
import { useState, useEffect } from "react";
import * as API from "@api/moderator";
import classNames from "classnames";
import dayjs from "dayjs";

export default function PaymentStats({
  className,
  title = "Объявления на сайте",
  filter = { is_charged: 1 },
  from = dayjs().startOf("month"), // Start of this month (e.g. would be Month to Date)
  to = dayjs(),
  period = "month" // year, month, week, day
}) {
  const [values, setValues] = useState({ current: [], previous: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      API.Analytics.getPayments({
        filter: {
          ...filter,
          created_at: {
            from: from.format("YYYY-MM-DD"),
            to: to.format("YYYY-MM-DD") // Today
          }
        }
      }),
      API.Analytics.getPayments({
        filter: {
          ...filter,
          created_at: {
            from: from.subtract(1, period).format("YYYY-MM-DD"), // Subtract 1 period (e.g. 1 month)
            to: to.subtract(1, period).format("YYYY-MM-DD") // Subtract 1 period (e.g. 1 month)
          }
        }
      })
    ]).then(([current, previous]) => {
      setValues({ current: current.data, previous: previous.data });
      setLoading(false);
    });
    /* eslint-disable-next-line */
  }, []);

  // get percentage difference from current to previous
  const change = getPercentageChange(values?.current?.total, values?.previous?.total);

  if (loading) return <Loading compact />;

  return (
    <Statistic
      className={className}
      title={title}
      value={new Intl.NumberFormat(
        "ru-RU",
        {
          style: "currency",
          currency: "KZT"
        },
        { maximumFractionDigits: 0, minimumFractionDigits: 0 }
      ).format(values?.current?.total)}
      suffix={
        <span className={`block ml-2 text-sm ${change >= 0 ? "text-green-600" : "text-red-600"}`}>
          {change === "Infinity" ? 100 : change}%
          <i
            className={classNames("not-italic ml-2", {
              "text-green-600": change >= 0,
              "text-red-600": change < 0
            })}
          >
            {change >= 0 ? "+" : "-"}
            {values?.previous?.total}
          </i>
        </span>
      }
    />
  );
}

function getPercentageChange(current, previous) {
  // Check if the previous value is 0 to avoid division by zero
  if (previous === 0) {
    // If the current value is also 0, return 0% change, otherwise return Infinity
    return current === 0 ? "0.00%" : "Infinity";
  }

  // Calculate the percentage change
  const percentageChange = ((current - previous) / previous) * 100;

  // Check if the result is NaN or Infinity to avoid returning those values
  if (isNaN(percentageChange) || !isFinite(percentageChange)) {
    return 100;
  }

  // Format the result as a string with two decimal places
  return percentageChange.toFixed(2);
}
