import axios from "axios";
import config from "@config";

const API = `${config.api}/moderator/org_types`;

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const create = (data) =>
  axios
    .post(`${API}/`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const update = (data) =>
  axios
    .post(`${API}/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));

export const remove = (data) =>
  axios
    .delete(`${API}/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера",
    }));
