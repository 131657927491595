import { message, Select, Spin } from "antd";
import debounce from "debounce";
import * as API from "@api/moderator";
import { useState } from "react";

export default function Users(params) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);

  let fetchUserList = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Users.getAll({
      limit: 10,
      filter: { display_name: value },
    });

    if (!data || data?.error) {
      return message.error(
        "Невозможно произвести поиск, проверьте интернет-соединение",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((user) => ({
        value: user.id,
        label: `${user.display_name} (${user.email})`,
      }))
    );

    setFetching(false);
  };

  fetchUserList = debounce(fetchUserList, 200);

  return (
    <Select
      onChange={(v) => params.onChange("user_id", v)}
      showSearch
      className="w-full"
      allowClear
      placeholder="Поиск по заявителю"
      filterOption={false}
      onSearch={fetchUserList}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  );
}
