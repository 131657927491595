import { Popover } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useState, useRef, useEffect } from "react";
import {
  tariffDescription,
  tariffPrice,
  tariffLength,
} from "../packages/reference";
import classNames from "classnames";
import * as API from "@api/customer";

export default function Card({ data, key, listing, className }) {
  const formRef = useRef();
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const leadingItem = data[0];
  const info = tariffDescription(leadingItem);

  const getFormData = () => {
    const inputs = [];
    for (const [key, value] of Object.entries(formFields)) {
      inputs.push({ key, value });
    }

    return inputs.map((i) => <input name={i.key} value={i.value} />);
  };

  const onTariffSelect = async ({ id }) => {
    const form = formRef.current;
    setLoading(true);
    const _res = await API.Payments.create({
      payment_type: "tariff",
      product_id: listing.product.id,
      tariff_id: id,
    });

    form.action = _res.data.url;
    form.method = _res.data.method;
    setFormFields(_res.data.body);
  };

  useEffect(() => {
    if (formFields?.pg_sig) {
      const timer = setTimeout(() => {
        formRef.current.submit();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [formFields]);

  const tariffPreview = () => {
    return (
      <div className="w-fit" style={{ maxWidth: 230 }}>
        <span className="text-sm text-black">{info.description}</span>
        <img src={info.img} alt="Изображение" className="block w-full mt-5" />
      </div>
    );
  };

  return (
    <div
      key={key}
      className={classNames(
        "rounded-md bg-white border border-solid border-neutral-300 p-5 my-5 grid grid-cols-2 gap-6",
        className
      )}
    >
      <div className="col-span-2 lg:col-span-1 flex justify-start items-center">
        <form className="hidden invisible h-0 w-0" ref={formRef} method="POST">
          {getFormData()}
        </form>
        <img
          src={info.icon}
          alt={leadingItem.name}
          className="block mr-2"
          width={20}
          height={20}
        />
        <span className="font-semibold text-lg block text-neutral-800 mr-2">
          {leadingItem.name}
        </span>
        {loading ? (
          <LoadingOutlined className="loading-icon" />
        ) : (
          <Popover
            placement="bottom"
            title={leadingItem.name}
            content={tariffPreview}
          >
            <img
              src="/img/promotions/info.svg"
              className="block cursor-pointer"
              alt="Информация"
              width={16}
              height={16}
            />
          </Popover>
        )}
      </div>
      <div className="col-span-2 lg:col-span-1 flex flex-col lg:flex-row lg:justify-end lg:items-center">
        {data.map((tariff, i) => (
          <button
            key={i}
            disabled={loading}
            className="tariff-individual-button my-1 lg:ml-2 lg:my-0"
            onClick={() => onTariffSelect(tariff)}
          >
            <span className="pricetag">{tariffPrice(tariff)}</span>
            <span className="description">
              Оплатить на {tariffLength(tariff)}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}
